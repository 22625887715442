import { createContext, useContext, useEffect, useMemo, useState } from "react";

const authContext = createContext();

const AuthContext = ({ children }) => {
  const [token, setToken] = useState("");

  useEffect(() => {
    const item = localStorage.getItem("token");
    console.log("token in context: ", item);
    if (item !== "undefined" || item) {
      setToken(item);
    }
  }, []);

  return (
    <authContext.Provider value={{ token, setToken }}>
      {children}
    </authContext.Provider>
  );
};
export const useAuth = () => {
  return useContext(authContext);
};

export default AuthContext;
