import CourseA from "../../components/Course_components/CourseA";
import Dashboard from "../Dashboard/Dashboard";

import Planification from "../Planification/Planification";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import CourseB from "../../components/Course_components/CourseB";
import CourseC from "../../components/Course_components/CourseC";
import Login from "../Login/Login";
import Signup from "../Signup/Signup";
import AdminDashboardCursos from "../AdminDashboard/AdminDashboardCursos";
import AdminDashboardProfessors from "../AdminDashboard/AdminDashboardProfessors";
import AddCourse from "../../components/Course_components/AddCourse";
import ProfessorProfile from "../../components/Course_components/ProfessorProfile";
import CourseAPlanification from "../../components/Planification_components/CourseAPlanification/CourseAPlanification";

export default function Main() {
  return (
    <Router>
      {/* <div className=" flex w-full">
        <Sidebar />
        <div className=" flex flex-col w-full">
          <Navbar />
          <div className="h-[90vh] overflow-y-hidden"> */}
      <Routes>
        <Route path="/Signup" element={<Signup />} />
        <Route path="/Login" element={<Login />} />
        <Route path="/" element={<Login />} />
        <Route path="/Dashboard" element={<Dashboard />} />
        <Route path="/Settings" element={<Dashboard />} />
        <Route path="/Planning" element={<Planification />} />
        <Route path="/Courses" element={<CourseA />} />
        <Route path="/CourseA" element={<CourseA />} />
        <Route path="/CourseAPlanning" element={<CourseAPlanification />} />
        <Route path="/CourseB" element={<CourseB />} />
        <Route path="/CourseC" element={<CourseC />} />
        <Route
          path="/AdminDashboardCursos"
          element={<AdminDashboardCursos />}
        />
        <Route
          path="/AdminDashboardProfessors"
          element={<AdminDashboardProfessors />}
        />
        <Route path="/AddCourse" element={<AddCourse />} />
        <Route path="/Profile" element={<ProfessorProfile />} />
      </Routes>
      {/* </div>
        </div>
      </div> */}
    </Router>
  );
}
