import { GoPlus } from "react-icons/go";
import { IoIosClose } from "react-icons/io";

import { TiTick } from "react-icons/ti";

export default function GetActivity({
  activities,
  goal_no,
  goal,
  selected_activity = "",
  setActivityInput,
  activityInput,
  activityOptions,
  setActivityOptions,
  setAddedActivityGoal,
  addedActivityGoal,
}) {
  console.log("Activities in get: ", activities);

  const selectActivity = (goal_no, index) => {
    console.log("selecting activity");
    console.log("Select activity : ", goal_no, index);

    const updatedActivityOptions = [...activityOptions];

    updatedActivityOptions[goal_no] = {
      ...updatedActivityOptions[goal_no],
      selected_activity: {
        activity: updatedActivityOptions[goal_no]["actividad_" + (index + 1)],
        index,
      },
    };

    setActivityOptions(updatedActivityOptions);
  };

  const removeActivityOption = (e, goal_no, index) => {
    e.stopPropagation();
    console.log("To remove : ", goal_no, index);
    console.log("Activity Options : ", activityOptions);
    const updatedActivityOptions = [...activityOptions];

    console.log("Updated Activity Options : ", updatedActivityOptions);

    console.log("To remove : ", updatedActivityOptions[goal_no]);

    // Clone the target object to modify it
    const currentObject = { ...updatedActivityOptions[goal_no] };

    // Remove the specified actividad_X
    delete currentObject[`actividad_${index + 1}`];

    // Remove if selected activity is the one being removed
    if (
      currentObject.selected_activity &&
      currentObject.selected_activity.index === index
    ) {
      currentObject.selected_activity = "";
    }

    // Collect the remaining activities and reindex them
    const remainingActivities = Object.keys(currentObject)
      .filter((key) => key.startsWith("actividad_"))
      .sort((a, b) => parseInt(a.split("_")[1]) - parseInt(b.split("_")[1]))
      .map((key, index) => [`actividad_${index + 1}`, currentObject[key]]);

    // Create the new object with reindexed activities
    const newObject = {
      meta: currentObject.meta,
      selected_activity: currentObject.selected_activity,
    };
    remainingActivities.forEach(([newKey, value]) => {
      newObject[newKey] = value;
    });

    // Update the state
    updatedActivityOptions[goal_no] = newObject;
    setActivityOptions(updatedActivityOptions);
  };

  const addActivity = (goal_no) => {
    if (!activityInput) {
      return;
    }

    const updatedActivityOptions = [...activityOptions];

    const currentActivities = updatedActivityOptions[goal_no];
    let nextActivityIndex =
      Object.keys(currentActivities).filter((key) =>
        key.startsWith("actividad_")
      ).length + 1;
    currentActivities[`actividad_${nextActivityIndex}`] = activityInput;

    setActivityOptions(updatedActivityOptions);
    setAddedActivityGoal("");
    setActivityInput("");
  };

  const handleAddActivity = (goal_no) => {
    const currentActivities = activityOptions[goal_no];
    let nextActivityIndex = Object.keys(currentActivities).filter((key) =>
      key.startsWith("actividad_")
    ).length;

    if (nextActivityIndex === 4) {
      console.log("Can not add more, length is already 4.");
      return;
    }
    setAddedActivityGoal(goal_no);
  };

  const handleActivityInputClose = () => {
    setAddedActivityGoal("");
    setActivityInput("");
  };

  return (
    <div className=" flex flex-col gap-4">
      <div className=" flex-1 bg-[#D0DFF2] flex gap-[5px] py-[10px] px-[15px] rounded-md border border-[gainsboro]">
        <p className=" text-[12px]">
          Depending on your chosen goals, I suggest the following activities for
          goal {goal_no + 1}. If you want to add an additional activity, just
          write it.
        </p>
      </div>

      {/* activity */}
      <div className=" flex gap-4 flex-col sm:flex-row">
        <div className="bg-[#D0DFF2] flex flex-col flex-1 justify-center items-center gap-[5px] py-[5px] px-[10px] rounded-md border border-[gainsboro]">
          <span className=" text-[15px] flex sm:flex-col justify-center items-center pb-[10px] border-b border-b-gray-500 w-full text-center">
            <span>Meta</span>
            <span>#{goal_no + 1}</span>
          </span>
          <p className=" text-[8px] pt-[5px] text-center">{goal}</p>
        </div>
        {/* activity options */}
        <div className=" flex flex-col gap-2 flex-[9]">
          {activities.map((option, index) => {
            return (
              <div
                style={{
                  backgroundColor:
                    selected_activity?.activity === option &&
                    selected_activity?.index === index &&
                    "gainsboro",
                }}
                onClick={() => selectActivity(goal_no, index)}
                className=" bg-[#F0F0F0] cursor-pointer flex gap-2 justify-between rounded-md border border-[gainsboro] text-[10px]  items-center px-[5px]"
              >
                {/* <span className=" py-[10px]">A.</span> */}
                <p className=" p-[5px] sm:p-[0]">
                  {index + 1}. {option}
                </p>
                <div className=" flex items-center self-start">
                  {selected_activity?.activity === option &&
                    selected_activity?.index === index && (
                      <TiTick className=" w-[18px] h-[18px]" />
                    )}
                  <IoIosClose
                    onClick={(e) => removeActivityOption(e, goal_no, index)}
                    className=" w-[25px] h-[25px] align-top"
                  />
                </div>
              </div>
            );
          })}
          {addedActivityGoal === goal_no && (
            <div className=" h-full bg-[gainsboro] flex gap-[1px] justify-between rounded-md border border-[gainsboro] text-[10px]  items-center px-[5px]">
              {/* <span className=" py-[10px]">A.</span> */}
              {/* <p>{option}</p> */}
              <input
                type="text"
                name=""
                id=""
                className=" w-full bg-transparent outline-none"
                placeholder="Add Activity"
                value={activityInput}
                onChange={(e) => setActivityInput(e.target.value)}
              />
              <TiTick
                className=" w-[18px] h-[25px] cursor-pointer"
                onClick={() => addActivity(goal_no)}
              />
              <IoIosClose
                onClick={handleActivityInputClose}
                className=" w-[25px] h-[25px] cursor-pointer"
              />
            </div>
          )}
        </div>

        <div className="bg-[#D0DFF2] flex flex-col flex-1 justify-center items-center gap-[5px] py-[5px] px-[10px] rounded-md border border-[gainsboro]">
          <span className=" flex justify-center items-center w-[30px] h-[30px] bg-[#131432] rounded-[25px]">
            <GoPlus
              onClick={() => handleAddActivity(goal_no)}
              className=" w-[25px] h-[25px] cursor-pointer"
              color="white"
            />
          </span>
          <p className=" text-[12px] text-center">Agrega Actividad</p>
        </div>
      </div>
    </div>
  );
}
