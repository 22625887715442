export default function EditIcon() {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.696 2.26884C21.6709 1.24372 20.0088 1.24372 18.9837 2.26884L17.8266 3.426L21.5389 7.13831L22.696 5.98116C23.7211 4.95603 23.7211 3.29397 22.696 2.26884Z"
        fill="#9F9FAB"
      />
      <path
        d="M20.4782 8.19897L16.7659 4.48666L4.61505 16.6375C3.99827 17.2543 3.54488 18.015 3.29586 18.851L2.49608 21.5359C2.41746 21.7998 2.4898 22.0856 2.68453 22.2803C2.87926 22.4751 3.16505 22.5474 3.42898 22.4688L6.11387 21.669C6.94984 21.42 7.71059 20.9666 8.32738 20.3498L20.4782 8.19897Z"
        fill="#9F9FAB"
      />
    </svg>
  );
}
