import { FaGraduationCap } from "react-icons/fa";
import { RiQuestionAnswerFill } from "react-icons/ri";
import { GoPlus } from "react-icons/go";
import { IoIosClose } from "react-icons/io";
import { FaRegEdit } from "react-icons/fa";

const CourseAPlanificationActivities = () => {
  return (
    <div className=" w-full p-[30px] text-[#646588]">
      <div className=" bg-[#F6B846] text-white w-fit flex items-center gap-[15px] px-[15px] py-[10px] border-md rounded-md">
        <FaGraduationCap className=" w-[30px] h-[30px]" />
        <p className=" text-[20px]">
          Curso: 6to básico (C): Planificación Semanal
        </p>
      </div>

      <div className=" mt-[15px] flex flex-col gap-4">
        <div className=" flex-1 bg-[#D0DFF2] flex last: gap-[5px] py-[10px] px-[15px] rounded-md border border-[gainsboro]">
          <p className=" text-[12px]">
            Dados los Objetivos y el tiempo estipulado sugiero estas Metas de
            Aprendizaje por semana. Si deseas cambiar la progresión solo
            arrastra la Meta
          </p>
        </div>

        <div className=" flex gap-4">
          <div className="bg-[#D0DFF2] flex flex-col flex-1 justify-center items-center gap-[5px] py-[5px] px-[10px] rounded-md border border-[gainsboro]">
            <span className=" text-[15px] flex flex-col justify-center items-center pb-[10px] border-b border-b-gray-500 w-full text-center">
              <span>Meta</span>
              <span>#1</span>
            </span>
            <p className=" text-[8px] pt-[5px]">
              Identifica los factores bióticos y abióticos por medio de la
              exploración.
            </p>
          </div>
          <div className=" flex flex-col gap-2">
            <div className=" h-full bg-[#F0F0F0] flex gap-2 rounded-md border border-[gainsboro] text-[10px]  items-center px-[5px]">
              <span className=" py-[10px]">A.</span>
              <p>Trabajos en clase (mini-investigaciones)</p>
            </div>

            <div className=" h-full flex gap-2 rounded-md border border-[gainsboro] text-[10px] bg-[#D0DFF2] items-center px-[5px]">
              <span className=" py-[10px]">B.</span>
              <p>
                Actividad lúdica conexión de conceptos con ejemplos cotidianos:
                los factores bióticos y abióticos por medio de la exploración.
              </p>
            </div>

            <div className=" h-full bg-[#F0F0F0] flex gap-2 rounded-md border border-[gainsboro] text-[10px]  items-center px-[5px]">
              <span className=" py-[10px]">C.</span>
              <p>Presentación Oral</p>
            </div>
          </div>

          <div className="bg-[#D0DFF2] flex flex-col flex-1 justify-center items-center gap-[5px] py-[5px] px-[10px] rounded-md border border-[gainsboro]">
            <span className=" flex justify-center items-center w-[30px] h-[30px] bg-[#131432] rounded-[25px]">
              <GoPlus
                className=" w-[25px] h-[25px] cursor-pointer"
                color="white"
              />
            </span>
            <p className=" text-[12px]">Agrega Actividad</p>
          </div>
        </div>

        <div className=" flex-1 bg-[#D0DFF2] flex last: gap-[5px] py-[10px] px-[15px] rounded-md border border-[gainsboro]">
          <p className=" text-[12px]">
            De acuerdo a la actividad escogida para la meta #1 te sugiero los
            siguientes materiales. También puedes cambiar o agregar materiales
            si lo deseas.
          </p>
        </div>

        <div className=" flex rounded-md border border-[gainsboro] bg-[#D0DFF2] py-[10px] pr-[10px]">
          <div className="flex justify-center items-center flex-1">
            Materials
          </div>
          <div className=" flex-[3] bg-white py-[5px] px-[10px] rounded-sm text-[10px]">
            <ul>
              <li className=" flex items-center gap-[3px]">
                <IoIosClose className=" w-[25px] h-[25px]" />
                <FaRegEdit className=" w-[15px] h-[15px]" />
                <span className=" text-[15px] ml-[5px]">
                  1. Recortes de revistas
                </span>{" "}
              </li>
              <li className=" flex items-center gap-[3px]">
                <IoIosClose className=" w-[25px] h-[25px]" />
                <FaRegEdit className=" w-[15px] h-[15px]" />
                <span className=" text-[15px] ml-[5px]">
                  2. Presentación Oral
                </span>
              </li>
              <li className=" flex items-center gap-[3px]">
                <IoIosClose className=" w-[25px] h-[25px]" />
                <FaRegEdit className=" w-[15px] h-[15px]" />
                <span className=" text-[15px] ml-[5px]">3. Powerpoint</span>
              </li>
              <li className=" flex items-center gap-[3px]">
                <IoIosClose className=" w-[25px] h-[25px]" />
                <FaRegEdit className=" w-[15px] h-[15px]" />
                <span className=" text-[15px] ml-[5px]">4. Imágenes</span>
              </li>
            </ul>
          </div>
        </div>

        <div className=" flex-1 bg-[#D0DFF2] flex flex-col last: gap-[5px] py-[5px] px-[10px] rounded-md border border-[gainsboro]">
          <div className=" flex items-center gap-2">
            <RiQuestionAnswerFill className=" w-[20x] h-[20px] text-[dimgray]" />
            <input
              className=" bg-[#D0DFF2] px-4 outline-none w-full"
              type="text"
              placeholder="Escribe tu respuesta aqui..."
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default CourseAPlanificationActivities;
