import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import EditIcon from "../../SvgIcons/EditIcon";
import DeleteIcon from "../../SvgIcons/DeleteIcon";
import CheckedIcon from "../../SvgIcons/CheckedIcon";
import UncheckedIcon from "../../SvgIcons/UncheckedIcon";

export default function PaymentMethodsTable({
  tableHeader,
  tableData,
  deletePaymentMethod,
  editPaymentMethod,
  selectPaymentMethod,
}) {
  return (
    <TableContainer>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            {tableHeader.map((heading, index) => (
              <TableCell key={index} sx={{ fontWeight: "700" }}>
                {heading}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {tableData.map((row, index) => {
            const {
              icon,
              cardType,
              cardNumber,
              fname,
              lname,
              dueDate,
              selected,
            } = row;
            return (
              <TableRow
                key={index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>
                  <span className=" flex items-center gap-[5px]">
                    <span>{icon}</span>
                    <span>{`${cardType} ***${String(cardNumber).slice(
                      -4
                    )}`}</span>
                  </span>
                </TableCell>
                <TableCell>{`${fname} ${lname}`}</TableCell>
                <TableCell>
                  <span className=" flex justify-center">{dueDate}</span>
                </TableCell>
                <TableCell key={index}>
                  <span className=" flex items-center gap-[5px]">
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => editPaymentMethod(index)}
                    >
                      {<EditIcon />}
                    </span>
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => deletePaymentMethod(index)}
                    >
                      {<DeleteIcon />}
                    </span>
                  </span>
                </TableCell>
                <TableCell key={index} align="center">
                  {/* <input type="checkbox" name="payment" /> */}
                  {selected ? (
                    <span className=" flex justify-center cursor-pointer">
                      <CheckedIcon />
                    </span>
                  ) : (
                    <span
                      onClick={() => selectPaymentMethod(index)}
                      className=" flex justify-center cursor-pointer"
                    >
                      <UncheckedIcon />
                    </span>
                  )}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
