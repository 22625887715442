import CustomButton from "../../components/CustomButton/CustomButton";
import { TextField } from "@mui/material";
import { IoMdArrowBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { IoMailOpenOutline } from "react-icons/io5";

export default function CodeComponent({
  email,
  onClick,
  code,
  handleCodeChange,
  inputRefs,
  errors,
}) {
  const navigate = useNavigate();

  return (
    <div className=" flex flex-col gap-[30px] w-max sm:w-[327px]">
      <div className=" flex items-center justify-center">
        <span className=" w-[40px] h-[40px] rounded-lg bg-white flex items-center justify-center shadow-md border border-[#cccc]">
          <IoMailOpenOutline style={{ width: "20px", height: "20px" }} />
        </span>
      </div>
      <div className=" flex flex-col gap-2 text-center">
        <p className=" text-3xl">Password reset</p>
        <p className=" text-gray-500 text-[15px]">
          We sent a code to{" "}
          <span style={{ color: "black", fontWeight: "bold" }}>{email}</span>.
        </p>
      </div>
      <div className=" flex flex-col gap-[20px]">
        <div className=" flex flex-col gap-2">
          <div className=" flex items-center gap-2">
            {Object.keys(code).map((key, index) => {
              return (
                <input
                  type="text"
                  maxLength={1}
                  name={key}
                  value={code.key}
                  onChange={(e) => handleCodeChange(key, e.target.value, index)}
                  ref={(el) => (inputRefs.current[index] = el)}
                  className=" w-[48px] h-[55px] border border-[gainsboro] rounded-md text-[25px] text-center flex-1"
                />
              );
            })}
          </div>
          {errors.code && (
            <p className=" text-[15px] text-[#d32f2f]">{errors.code}</p>
          )}
        </div>

        <div>
          <CustomButton
            text={"Continue"}
            backgroundColor={"#2e2ef5"}
            width={"100%"}
            onClick={onClick}
          />
        </div>
      </div>
      <div className=" text-center">
        <p className=" text-gray-500 text-[15px]">
          Didn't receive the email?{" "}
          <a className=" text-[#2e2ef5] underline cursor-pointer">Click</a>
        </p>
      </div>
      <div className=" flex gap-2 items-center justify-center text-gray-500">
        <IoMdArrowBack
          onClick={() => navigate("/Login")}
          style={{ width: "20px", height: "20px", cursor: "pointer" }}
        />
        <span onClick={() => navigate("/Login")} style={{ cursor: "pointer" }}>
          Back to log in
        </span>
      </div>
    </div>
  );
}
