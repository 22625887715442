import DatePicker from "./components/DatePicker/DatePicker";
import Login from "./pages/Login/Login";
import Main from "./pages/Main/Main";
import AppRoutes from "./routes/AppRoutes";

function App() {
  return (
    <div>
      {/* <Login /> */}
      {/* <Main /> */}
      <AppRoutes />
    </div>
  );
}

export default App;
