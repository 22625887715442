import { useState } from "react";
import { Link } from "react-router-dom";
import CourseAPlanification from "../../components/Planification_components/CourseAPlanification/CourseAPlanification";
import CourseBPlanification from "../../components/Planification_components/CourseBPlanification/CourseBPlanification";
import CourseCPlanification from "../../components/Planification_components/CourseCPlanification/CourseCPlanification";
import Wrapper from "../../components/Wrapper/Wrapper";
import CourseAPlanificationOld from "../../components/Planification_components/CourseAPlanification/CourseAPlanificationOld";

const Planification = () => {
  const [page, setPage] = useState("CourseA");
  const handlePage = (e) => {
    setPage(e.currentTarget.id);
  };

  return (
    <Wrapper>
      <div className=" flex w-full">
        <div className=" mt-[10px] flex flex-col gap-[8px] p-[20px] h-[250px] border-r border-[#ccc]">
          <div
            onClick={handlePage}
            id="CourseA"
            className=" w-[125px] py-[10px] px-[20px] text-white bg-[#C94D3B] text-[12px] rounded-md cursor-pointer"
          >
            <p>CourseA</p>
          </div>
          <div
            onClick={handlePage}
            id="CourseB"
            className=" w-[125px] py-[10px] px-[20px] text-white bg-[#C94D3B] text-[12px] rounded-md cursor-pointer"
          >
            <p>CourseB</p>
          </div>
          <div
            onClick={handlePage}
            id="CourseC"
            className=" w-[125px] py-[10px] px-[20px] text-white bg-[#C94D3B] text-[12px] rounded-md cursor-pointer"
          >
            <p>CourseC</p>
          </div>
        </div>
        {page === "CourseA" && <CourseAPlanificationOld />}
        {page === "CourseB" && <CourseBPlanification />}
        {page === "CourseC" && <CourseCPlanification />}
      </div>
    </Wrapper>
  );
};
export default Planification;
