import { FaUserCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../globalContext/AuthContext/AuthContext";

export default function Navbar() {
  const navigate = useNavigate();
  const { setToken } = useAuth();

  const signout = () => {
    console.log("signing out...");
    localStorage.clear();
    setToken("");
    navigate("/Dashboard");
  };
  return (
    <div className=" px-[30px] flex items-center justify-end md:justify-between w-full h-[60px] border-b border-gray-[#CCCCCC]">
      <div className=" hidden w-[250px] md:block">
        <input
          type="text"
          className=" h-[36px] w-full px-2 py-2 rounded-md border border-gray-300 focus:outline-none focus:border-blue-500"
          placeholder="Enter your text..."
        />
      </div>
      <div className=" flex justify-between gap-4">
        <FaUserCircle onClick={signout} size={25} />
        <span>Haad</span>
      </div>
    </div>
  );
}
