export default function PaypalIcon() {
  return (
    <svg
      width="41"
      height="40"
      viewBox="0 0 41 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_738_2327)">
        <path
          d="M37.7413 6.55176H3.25852C1.92542 6.55176 0.844727 7.63245 0.844727 8.96555V31.0345C0.844727 32.3676 1.92542 33.4483 3.25852 33.4483H37.7413C39.0744 33.4483 40.1551 32.3676 40.1551 31.0345V8.96555C40.1551 7.63245 39.0744 6.55176 37.7413 6.55176Z"
          fill="white"
          stroke="#F3F3F3"
          stroke-width="1.03448"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M18.7336 20.177L18.9078 19.0677L18.5198 19.0586H16.6675L17.9548 10.8727C17.9588 10.8479 17.9718 10.8249 17.9907 10.8086C18.0098 10.7922 18.034 10.7832 18.0593 10.7832H21.1828C22.2198 10.7832 22.9353 10.9996 23.3089 11.4267C23.4841 11.627 23.5956 11.8365 23.6496 12.0668C23.7062 12.3086 23.7072 12.5975 23.652 12.9499L23.648 12.9755V13.2013L23.8231 13.3008C23.9706 13.3793 24.0878 13.4691 24.1778 13.5719C24.3276 13.7432 24.4244 13.961 24.4654 14.2191C24.5078 14.4845 24.4938 14.8005 24.4244 15.1582C24.3445 15.5696 24.2153 15.9279 24.0409 16.2211C23.8804 16.4912 23.676 16.7154 23.4331 16.889C23.2014 17.054 22.926 17.1792 22.6147 17.2594C22.3129 17.3381 21.969 17.3779 21.5917 17.3779H21.3486C21.1749 17.3779 21.006 17.4407 20.8735 17.5532C20.7406 17.6681 20.6527 17.825 20.6258 17.9966L20.6074 18.0965L20.2997 20.0518L20.2858 20.1235C20.282 20.1463 20.2757 20.1576 20.2664 20.1652C20.2581 20.1723 20.2462 20.177 20.2344 20.177H18.7336Z"
          fill="#28356A"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M23.989 13.001C23.9798 13.0607 23.9691 13.1218 23.9571 13.1846C23.5453 15.3055 22.136 16.0382 20.3362 16.0382H19.4198C19.1997 16.0382 19.0142 16.1984 18.9799 16.4161L18.3779 20.2461C18.3556 20.3892 18.4655 20.5179 18.6093 20.5179H20.2346C20.427 20.5179 20.5906 20.3777 20.6209 20.1874L20.6368 20.1046L20.9428 18.157L20.9626 18.0502C20.9925 17.8592 21.1564 17.7189 21.3488 17.7189H21.5919C23.1666 17.7189 24.3993 17.0778 24.7597 15.2223C24.9101 14.4472 24.8322 13.8001 24.4339 13.3449C24.3135 13.2077 24.1639 13.0938 23.989 13.001Z"
          fill="#298FC2"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M23.5583 12.8289C23.4953 12.8105 23.4304 12.7939 23.3638 12.7788C23.2968 12.7641 23.2283 12.7511 23.1576 12.7397C22.9106 12.6997 22.6398 12.6807 22.3498 12.6807H19.9017C19.8414 12.6807 19.7841 12.6943 19.7329 12.719C19.6199 12.7735 19.536 12.8807 19.5158 13.0119L18.9949 16.32L18.98 16.4164C19.0143 16.1987 19.1998 16.0384 19.4198 16.0384H20.3363C22.136 16.0384 23.5453 15.3054 23.9572 13.1848C23.9695 13.1221 23.9798 13.061 23.9891 13.0012C23.8849 12.9458 23.772 12.8983 23.6505 12.8579C23.6205 12.8479 23.5895 12.8383 23.5583 12.8289Z"
          fill="#22284F"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M19.5154 13.0124C19.5357 12.8812 19.6195 12.774 19.7325 12.7199C19.7841 12.6951 19.841 12.6815 19.9013 12.6815H22.3494C22.6394 12.6815 22.9102 12.7006 23.1573 12.7406C23.2278 12.7519 23.2964 12.765 23.3634 12.7797C23.43 12.7947 23.4949 12.8114 23.5578 12.8297C23.5891 12.8391 23.6201 12.8488 23.6504 12.8585C23.7719 12.8989 23.8849 12.9467 23.9891 13.0018C24.1116 12.218 23.9881 11.6844 23.5655 11.2011C23.0996 10.6691 22.2589 10.4414 21.183 10.4414H18.0594C17.8397 10.4414 17.6523 10.6016 17.6183 10.8198L16.3173 19.0898C16.2916 19.2534 16.4175 19.4011 16.5821 19.4011H18.5103L19.5154 13.0124Z"
          fill="#28356A"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M9.53061 22.4277H7.25328C7.09742 22.4277 6.96488 22.5413 6.94054 22.6956L6.01947 28.5519C6.00119 28.6675 6.09046 28.7716 6.20733 28.7716H7.29451C7.45033 28.7716 7.58288 28.6581 7.60723 28.5035L7.85564 26.9239C7.87957 26.7693 8.0124 26.6558 8.16799 26.6558H8.88888C10.389 26.6558 11.2547 25.9277 11.481 24.4852C11.5828 23.854 11.4852 23.3581 11.1905 23.0108C10.8668 22.6295 10.2927 22.4277 9.53061 22.4277ZM9.7933 24.5667C9.66875 25.3861 9.0444 25.3861 8.44075 25.3861H8.09702L8.33813 23.8557C8.35247 23.7632 8.43233 23.695 8.52564 23.695H8.68316C9.0944 23.695 9.48233 23.695 9.68275 23.9301C9.80233 24.0704 9.83888 24.2788 9.7933 24.5667Z"
          fill="#28356A"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M16.3963 24.4965H15.3058C15.2129 24.4965 15.1326 24.5646 15.1183 24.6571L15.07 24.9629L14.9938 24.8521C14.7577 24.5085 14.2312 24.3936 13.7058 24.3936C12.5006 24.3936 11.4714 25.3089 11.2709 26.5929C11.1667 27.2334 11.3148 27.8459 11.6772 28.273C12.0095 28.6657 12.485 28.8293 13.0508 28.8293C14.0218 28.8293 14.5601 28.2032 14.5601 28.2032L14.5116 28.5071C14.4933 28.6233 14.5826 28.7275 14.6988 28.7275H15.681C15.8372 28.7275 15.9691 28.614 15.9938 28.4593L16.5832 24.7162C16.6018 24.6009 16.5128 24.4965 16.3963 24.4965ZM14.8761 26.6251C14.771 27.2498 14.2766 27.6692 13.6458 27.6692C13.3292 27.6692 13.0761 27.5673 12.9135 27.3744C12.7523 27.1827 12.6912 26.9099 12.7423 26.606C12.8407 25.9865 13.3435 25.5533 13.9646 25.5533C14.2742 25.5533 14.5259 25.6567 14.6918 25.8512C14.8579 26.0479 14.9239 26.3225 14.8761 26.6251Z"
          fill="#28356A"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M22.1206 24.5898H21.0246C20.9201 24.5898 20.8219 24.642 20.7626 24.7292L19.2511 26.9618L18.6104 24.8163C18.5702 24.682 18.4466 24.5898 18.3068 24.5898H17.2299C17.099 24.5898 17.0082 24.7181 17.0497 24.8417L18.2568 28.3941L17.122 30.0007C17.0328 30.1273 17.1226 30.3013 17.2768 30.3013H18.3714C18.4753 30.3013 18.5725 30.2505 18.6315 30.1651L22.2764 24.8887C22.3637 24.7625 22.274 24.5898 22.1206 24.5898Z"
          fill="#28356A"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M25.7737 22.4277H23.4961C23.3406 22.4277 23.208 22.5413 23.1837 22.6956L22.2626 28.5519C22.2444 28.6675 22.3336 28.7716 22.4498 28.7716H23.6186C23.7271 28.7716 23.82 28.6922 23.837 28.584L24.0984 26.9239C24.1224 26.7693 24.2553 26.6558 24.4108 26.6558H25.1314C26.6318 26.6558 27.4973 25.9277 27.7237 24.4852C27.8259 23.854 27.7277 23.3581 27.433 23.0108C27.1096 22.6295 26.5359 22.4277 25.7737 22.4277ZM26.0364 24.5667C25.9122 25.3861 25.2878 25.3861 24.6838 25.3861H24.3406L24.5819 23.8557C24.5962 23.7632 24.6755 23.695 24.769 23.695H24.9266C25.3375 23.695 25.7257 23.695 25.9262 23.9301C26.0457 24.0704 26.082 24.2788 26.0364 24.5667Z"
          fill="#298FC2"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M32.638 24.4965H31.5481C31.4546 24.4965 31.375 24.5646 31.361 24.6571L31.3127 24.9629L31.2361 24.8521C31 24.5085 30.4739 24.3936 29.9483 24.3936C28.7433 24.3936 27.7144 25.3089 27.514 26.5929C27.4101 27.2334 27.5575 27.8459 27.9198 28.273C28.2529 28.6657 28.7277 28.8293 29.2935 28.8293C30.2644 28.8293 30.8028 28.2032 30.8028 28.2032L30.7543 28.5071C30.736 28.6233 30.8252 28.7275 30.9421 28.7275H31.9241C32.0796 28.7275 32.2121 28.614 32.2364 28.4593L32.8261 24.7162C32.8441 24.6009 32.7548 24.4965 32.638 24.4965ZM31.1179 26.6251C31.0134 27.2498 30.5182 27.6692 29.8874 27.6692C29.5714 27.6692 29.3178 27.5673 29.1552 27.3744C28.9941 27.1827 28.9335 26.9099 28.984 26.606C29.083 25.9865 29.5851 25.5533 30.2062 25.5533C30.5159 25.5533 30.7676 25.6567 30.9335 25.8512C31.1002 26.0479 31.1661 26.3225 31.1179 26.6251Z"
          fill="#298FC2"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M33.8326 22.5887L32.8979 28.5521C32.8796 28.6678 32.9688 28.7719 33.085 28.7719H34.0247C34.1809 28.7719 34.3134 28.6584 34.3373 28.5038L35.2591 22.6478C35.2775 22.5322 35.1882 22.4277 35.072 22.4277H34.0198C33.9269 22.428 33.8469 22.4962 33.8326 22.5887Z"
          fill="#298FC2"
        />
      </g>
      <defs>
        <clipPath id="clip0_738_2327">
          <rect
            width="40"
            height="40"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
