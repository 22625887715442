import { FaGraduationCap } from "react-icons/fa";
import { GiBookAura } from "react-icons/gi";
import { FaEye } from "react-icons/fa";
import { GrLinkNext, GrLinkPrevious } from "react-icons/gr";

const CourseBasicA = ({ data, handlePlanningSave, handlePrevious }) => {
  console.log("Data in final : ", data);
  const getPlanning = (materials, activity, indicators, goal, goal_no) => {
    return (
      <div className=" text-[#646588] flex flex-col xl:flex-row gap-[5px] bg-[#D0DFF2] rounded-md border border-[gainsboro] p-[10px]">
        <div className=" flex flex-1 flex-col xl:flex-row w-full">
          <div className="bg-[#D0DFF2] w-full xl:w-[75px] flex flex-col flex-1 xl:justify-center items-center gap-[5px] py-[5px] px-[10px]">
            <span className=" text-[15px] flex gap-2 xl:flex-col justify-center items-center pb-[10px] border-b border-b-gray-500 w-full text-center">
              <span>Meta</span>
              <span>#{goal_no + 1}</span>
            </span>
            <ul className=" flex flex-col gap-[4px] text-[#646588] text-[10px]">
              <li>
                <span>{goal}</span>
              </li>
            </ul>
          </div>
          <div className=" flex items-center px-[11px] py-[5px] xl:mr-[6px]">
            {/* <span className=" py-[70px] border-l border-gray-500"></span> */}
            <span className=" xl:py-[70px] h-[2px] w-full bg-gray-500 xl:h-0 xl:border-l xl:border-gray-500"></span>
          </div>
        </div>

        <div className=" flex flex-2  bg-[#D0DFF2] gap-[5px]">
          <div className=" flex flex-col bg-[#D0DFF2] py-[10px] w-full">
            <div className="flex gap-2 text-[10px] rounded-sm items-center px-[5px] bg-[#dce5f1] w-full p-[5px]">
              {/* <span className=" py-[10px]">B.</span> */}
              <p>{activity}</p>
            </div>

            <div className="text-[10px] p-[8px] mt-auto bg-[#dce5f1] rounded-sm">
              <h1 className=" text-[12px] text-[#646588] font-bold">
                Materials:
              </h1>
              <ul className=" flex flex-col gap-[2px] text-[#646588]">
                {materials.map((material, index) => {
                  return (
                    <li>
                      {index + 1}. {material}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>

        <div className=" flex flex-1 w-full xl:w-[200px] gap-[10px] xl:gap-0 flex-col xl:flex-row">
          <div className=" flex items-center px-[11px] py-[5px] xl:mr-[10px] xl:ml-[5px]">
            <span className=" xl:py-[70px] h-[2px] w-full bg-gray-500 xl:h-0 xl:border-l xl:border-gray-500"></span>
          </div>
          <div className=" flex-[3] py-[5px] bg-[#dce5f1] rounded-sm text-[10px] p-[10px]">
            <div className="flex flex-1 mb-[5px] font-bold">Indicators:</div>
            <ul className=" flex flex-col gap-[2px] text-[#646588]">
              {indicators.map((indicator, index) => {
                return (
                  <li>
                    {index + 1}. {indicator}
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    );
  };
  return (
    <div className=" w-full p-[20px] xl:p-[30px] h-[92vh] overflow-scroll">
      <div className=" flex justify-between items-center">
        <div className=" bg-[#C94D3B] text-white w-[250px] flex items-center gap-[10px] px-[8px] py-[10px] border-md rounded-md">
          <FaGraduationCap className=" w-[25px] h-[25px] hidden sm:block" />
          <p className="text-[12px] md:text-[20px]">Curso: 6to básico (A)</p>
        </div>
        <div className=" hidden sm:flex flex items-center gap-4 rounded-sm py-[4px] px-[6px] ">
          {/* <span className=" w-[18px] h-[18px] flex items-center justify-center bg-slate-50 rounded-full">
            <GrLinkNext className="w-[12px] h-[12px]" color="#65CD6F" />
          </span> */}
          <button
            onClick={handlePrevious}
            className=" bg-[#65CD6F] text-sm m-auto text-white px-[10px] py-[5px] rounded-md cursor-pointer"
          >
            Previous
          </button>
          <button className=" bg-[#65CD6F] text-sm m-auto text-white px-[10px] py-[5px] rounded-md cursor-pointer">
            Save
          </button>
        </div>
      </div>

      <div className="  mt-[20px] pt-[10px] h-[75vh] overflow-y-scroll flex flex-col gap-[5px]">
        <div className="bg-[#131432] flex text-white items-center py-[10px] rounded-md ">
          <GiBookAura className="w-[25px] h-[25px] ml-[10px] hidden sm:block" />
          <h1 className=" m-auto text-[12px] md:text-[20px]">
            OUTPUT PLANIFICACION SEMANAL
          </h1>
        </div>

        <div className=" pb-[10px] flex flex-col gap-4">
          <div className=" flex justify-end my-[6px]">
            <p className="px-[10px] py-[5px] border border-gray-700 bg-white text-[10px]">
              Semana del 21 de febrero de 2024
            </p>
          </div>
          <div className=" flex flex-col gap-2">
            <div className="bg-[#131432] flex text-white items-center justify-center sm:justify-start py-[4px] gap-4 rounded-md">
              <FaEye className="w-[20px] h-[20px] ml-[10px] hidden sm:block" />
              <h1 className="text-[15px] text-center">
                DETALLES DE LA PLANIFICACION{" "}
              </h1>
            </div>

            <div className=" flex justify-between ">
              <div className=" w-[300px] px-[10px] py-[5px] border border-gray-700 bg-white">
                <p className=" text-[12px]">Professor: Carlos Moreno</p>
              </div>
              <div className=" w-[300px] px-[10px] py-[5px] border border-gray-700 bg-white">
                <p className=" text-[12px]">Curso: 6to básico (A)</p>
              </div>
            </div>

            <div className=" flex justify-between">
              <div className=" w-[300px] px-[10px] py-[5px] border border-gray-700 bg-white">
                <p className=" text-[12px]">Asignatura: Languaje</p>
              </div>
              <div className=" w-[300px] px-[10px] py-[5px] border border-gray-700 bg-white">
                <p className=" text-[12px]">Unidad: #1 - Los elementos</p>
              </div>
            </div>
            <div className=" flex flex-col sm:flex-row gap-2 text-[12px] py-[20px] items-center border border-gray-700 bg-white px-2">
              <p className=" w-[140px] border-b sm:border-b-0 sm:border-r border-gray-500">
                Objetivo de Aprendizajo
              </p>
              <p>
                Lorem, ipsum dolor sit amet consectetur adipisicing elit. Animi.
              </p>
            </div>
          </div>

          <div className=" flex flex-col gap-2 mt-4">
            {/* materials */}
            {Object.keys(data.goals).map((key, index) => {
              const goal = data.goals[index].goal;
              const materials = data.goals[index].materials;
              const indicators = data.goals[index].indicators;
              const activity = data.goals[index].activity;

              console.log("Index : ", index);

              return getPlanning(materials, activity, indicators, goal, index);
            })}
          </div>
        </div>
        {/* buttons */}
        <div className=" flex-1 gap-2 flex justify-end sm:hidden">
          <div
            onClick={handlePrevious}
            className=" flex items-center bg-[#65CD6F] w-[200px] rounded-sm py-[4px] px-[6px] cursor-pointer"
          >
            <span className=" w-[18px] h-[18px] flex items-center justify-center bg-slate-50 rounded-full">
              <GrLinkPrevious className="w-[12px] h-[12px]" color="#65CD6F" />
            </span>
            <p className=" text-sm m-auto text-white">Atras</p>
          </div>
          <div className=" flex items-center bg-[#65CD6F] w-[200px] rounded-sm py-[4px] px-[6px] cursor-pointer">
            <span className=" w-[18px] h-[18px] flex items-center justify-center bg-slate-50 rounded-full">
              <GrLinkNext className="w-[12px] h-[12px]" color="#65CD6F" />
            </span>
            <p className=" text-sm m-auto text-white">save</p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CourseBasicA;
