export default function CheckedIcon() {
  return (
    <svg
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.5 16C3.5 8.8203 9.3203 3 16.5 3C23.6797 3 29.5 8.8203 29.5 16C29.5 23.1797 23.6797 29 16.5 29C9.3203 29 3.5 23.1797 3.5 16ZM21.3137 13.5812C21.6347 13.1318 21.5307 12.5073 21.0812 12.1863C20.6318 11.8653 20.0073 11.9693 19.6863 12.4188L15.3724 18.4582L13.2071 16.2929C12.8166 15.9024 12.1834 15.9024 11.7929 16.2929C11.4024 16.6834 11.4024 17.3166 11.7929 17.7071L14.7929 20.7071C15.0007 20.915 15.2895 21.0208 15.5825 20.9966C15.8754 20.9723 16.1429 20.8204 16.3137 20.5812L21.3137 13.5812Z"
        fill="#27CA7C"
      />
    </svg>
  );
}
