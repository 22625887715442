import { FaGraduationCap } from "react-icons/fa";
import { RiQuestionAnswerFill } from "react-icons/ri";
import { GoPlus } from "react-icons/go";

const CourseAPlanificationLearningGoals = () => {
  return (
    <div className=" w-full p-[30px]">
      <div className=" bg-[#6972FF] text-white w-fit flex items-center gap-[15px] px-[15px] py-[10px] border-md rounded-md">
        <FaGraduationCap className=" w-[30px] h-[30px]" />
        <p className=" text-[20px]">
          Curso: 6to básico (B): Planificación Semanal
        </p>
      </div>

      <div className="mt-[15px] flex flex-col gap-4">
        <div className=" flex-1 bg-[#D0DFF2] flex last: gap-[5px] py-[10px] px-[15px] rounded-md border border-[gainsboro]">
          <p className=" text-[12px]">
            Perfecto! Yo te puedo asistir en el proceso de creación de una
            planificación semanal para tu 6to básico (A).
          </p>
        </div>

        <div className=" flex-1 bg-[#D0DFF2] flex last: gap-[5px] py-[10px] px-[15px] rounded-md border border-[gainsboro]">
          <p className=" text-[12px]">
            Primero, debemos establecer el o los Objetivos de Aprendizaje de
            unidad a continuación...
          </p>
        </div>

        <div className=" flex-1 bg-[#D0DFF2] flex flex-col last: gap-[5px] py-[4px] px-[15px] rounded-md border border-[gainsboro]">
          <p className=" text-[12px]">
            Objetivo de aprendizaje 2, Objetivo de aprendizaje 4
          </p>
        </div>

        <div className=" flex-1 bg-[#D0DFF2] flex last: gap-[5px] py-[10px] px-[15px] rounded-md border border-[gainsboro]">
          <p className=" text-[12px]">
            Dados los Objetivos y el tiempo estipulado sugiero estas Metas de
            Aprendizaje por semana. Si deseas cambiar la progresión solo
            arrastra la Meta
          </p>
        </div>

        <div className=" flex gap-4">
          <div className=" flex flex-col gap-2">
            <div className=" flex gap-2">
              <div className=" py-[5px] flex gap-2 rounded-md border border-[gainsboro] text-[10px] bg-[#D0DFF2] items-center px-[5px]">
                <span className=" w-[50px] border-r border-gray-500 py-[10px]">
                  1.
                </span>
                <p>
                  Identifica los factores bióticos y abióticos por medio de la
                  exploración.
                </p>
              </div>

              <div className=" py-[5px] flex gap-2 rounded-md border border-[gainsboro] text-[10px] bg-[#D0DFF2] items-center px-[5px]">
                <span className=" w-[50px] border-r border-gray-500 py-[10px]">
                  2.
                </span>
                <p>
                  Identifica los factores bióticos y abióticos por medio de la
                  exploración.
                </p>
              </div>

              <div className=" py-[5px] flex gap-2 rounded-md border border-[gainsboro] text-[10px] bg-[#D0DFF2] items-center px-[5px]">
                <span className=" w-[50px] border-r border-gray-500 py-[10px]">
                  3.
                </span>
                <p>
                  Identifica los factores bióticos y abióticos por medio de la
                  exploración.
                </p>
              </div>

              <div className=" py-[5px] flex gap-2 rounded-md border border-[gainsboro] text-[10px] bg-[#D0DFF2] items-center px-[5px]">
                <span className=" w-[50px] border-r border-gray-500 py-[10px]">
                  4.
                </span>
                <p>
                  Identifica los factores bióticos y abióticos por medio de la
                  exploración.
                </p>
              </div>
            </div>
            <div className=" flex gap-2">
              <div className=" py-[5px] flex gap-2 rounded-md border border-[gainsboro] text-[10px] bg-[#D0DFF2] items-center px-[5px] ">
                <span className=" w-[50px] border-r border-gray-500 py-[10px]">
                  5.
                </span>
                <p>
                  Identifica los factores bióticos y abióticos por medio de la
                  exploración.
                </p>
              </div>

              <div className=" py-[5px] flex gap-2 rounded-md border border-[gainsboro] text-[10px] bg-[#D0DFF2] items-center px-[5px] ">
                <span className=" w-[50px] border-r border-gray-500 py-[10px]">
                  6.
                </span>
                <p>
                  Identifica los factores bióticos y abióticos por medio de la
                  exploración.
                </p>
              </div>

              <div className=" py-[5px] flex gap-2 rounded-md border border-[gainsboro] text-[10px] bg-[#D0DFF2] items-center px-[5px] ">
                <span className=" w-[50px] border-r border-gray-500 py-[10px]">
                  7.
                </span>
                <p>
                  Identifica los factores bióticos y abióticos por medio de la
                  exploración.
                </p>
              </div>

              <div className=" py-[5px] flex gap-2 rounded-md border border-[gainsboro] text-[10px] bg-[#D0DFF2] items-center px-[5px]">
                <span className=" w-[50px] border-r border-gray-500 py-[10px]">
                  8.
                </span>
                <p>
                  Identifica los factores bióticos y abióticos por medio de la
                  exploración.
                </p>
              </div>
            </div>
          </div>
          <div className="bg-[#D0DFF2] flex flex-col justify-center items-center gap-[5px] py-[10px] px-[15px] rounded-md border border-[gainsboro]">
            <span className=" flex justify-center items-center w-[30px] h-[30px] bg-[#131432] rounded-[25px]">
              <GoPlus
                className=" w-[25px] h-[25px] cursor-pointer"
                color="white"
              />
            </span>
            <p className=" text-[12px]">Agrega Metas</p>
          </div>
        </div>

        <div className=" flex-1 bg-[#D0DFF2] flex flex-col last: gap-[5px] py-[6px] px-[15px] rounded-md border border-[gainsboro]">
          <div className=" flex items-center gap-2">
            <RiQuestionAnswerFill className=" w-[20x] h-[20px] text-[dimgray]" />
            <input
              className=" bg-[#D0DFF2] px-4 outline-none w-full"
              type="text"
              placeholder="Escribe tu respuesta aqui..."
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default CourseAPlanificationLearningGoals;
