import Modal from "@mui/material/Modal";
import { useState } from "react";
import CustomButton from "../../components/CustomButton/CustomButton";
import MastercardIcon from "../../SvgIcons/MastercardIcon";
import VisaIcon from "../../SvgIcons/VisaIcon";
import BasicTable from "../../components/BasicTable/BasicTable";
import { IoMdClose } from "react-icons/io";
import PaymentMethodsTable from "./PaymentMethodsTable";
import AddCardModal from "./AddCardModal";

const modalStyles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "#fff",
  border: "1px solid #ccc",
  boxShadow: 24,
  p: 4,
};

export default function PaymentMethodsModal({
  open,
  handleClose,
  tableHeading,
  tableData,
  handleCardModal,
  deletePaymentMethod,
  editPaymentMethod,
  selectPaymentMethod,
}) {
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div
          style={modalStyles}
          className=" bg-[#F0F0FF] flex flex-col gap-[10px] w-[800px] p-[30px] rounded-2xl"
        >
          <div className=" flex justify-between items-center">
            <div>
              <h1 className=" text-[20px] font-medium">Historial de pagos</h1>
              <p className=" text-[#61616E] text-[12px]">
                Accede a tus compras pasadas
              </p>
            </div>
            <div className=" flex items-center justify-center w-[35px] h-[35px] rounded-full hover:bg-[#dcdcdc]">
              <IoMdClose
                style={{ width: "25px", height: "25px", cursor: "pointer" }}
                onClick={handleClose}
              />
            </div>
          </div>
          <PaymentMethodsTable
            tableHeader={tableHeading}
            tableData={tableData}
            deletePaymentMethod={deletePaymentMethod}
            editPaymentMethod={editPaymentMethod}
            selectPaymentMethod={selectPaymentMethod}
          />
          <div className=" mt-[10px]">
            <CustomButton
              text={"Agregar método de pago"}
              padding={"5px 12px"}
              borderRadius={"10px"}
              onClick={handleCardModal}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
}
