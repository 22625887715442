import * as React from "react";
import { FaGraduationCap } from "react-icons/fa";
import CustomCard from "../../components/Dashboard_components/CustomCard";
import DatePicker from "../../components/DatePicker/DatePicker";
import Navbar from "../../components/Navbar/Navbar";
import Wrapper from "../../components/Wrapper/Wrapper";
import CustomImageCard from "../../components/Dashboard_components/CustomImageCard";
import professor from "../../components/img/professor.jpg";
export default function ProfessorProfile() {
  const handleAddCourse = () => {
    return;
  };

  const prof = {
    imageUrl: professor,
    title: "Pedro Garrido",
    description: "Description text goes here.",
  };

  return (
    <Wrapper>
      <div className=" text-[#717171] h-[92vh] overflow-scroll">
        <h1 className=" p-[20px]  text-black font-bold text-xl">
          Perfil de Usuario:
        </h1>
        <div className=" flex justify-between flex-col md:flex-row gap-[20px]">
          {/* profile image */}
          <div className=" px-[11px] sm:px-[30px] py-[15px] flex flex-col gap-2 lg:w-[30%]">
            <h1 className=" text-[15px] lg:text-xl font-bold">
              Visualización de Perfil
            </h1>
            <div className=" flex justify-center">
              <CustomImageCard
                imageUrl={prof.imageUrl}
                title={prof.title}
                description={prof.description}
                cardWidth="200px"
                imageWidth="150px"
                imageHeight="150px"
              />
            </div>
          </div>
          {/* professor detail */}
          <div className=" p-[20px] md:px-[30px] py-[15px] w-full overflow-y-scroll flex flex-col gap-2">
            {/* sections and grades */}
            <div>
              <h1 className=" text-xl font-bold">Detalles del Perfil</h1>
            </div>

            {/* professors */}
            <div className=" mt-[5px] flex flex-col gap-4 flex-wrap text-[#717171] w-full]">
              <div className=" w-full flex justify-between flex-col lg:flex-row gap-[10px]">
                <div className=" flex flex-col gap-1 flex-1">
                  <label htmlFor="">Nombre:</label>
                  <input
                    type="text"
                    name="name"
                    value="Pedro Garrido"
                    id=""
                    className=" border border-gray-400 px-2 py-1 rounded-md"
                  />
                </div>
                <div className=" flex flex-col gap-1 flex-1">
                  <label htmlFor="">Email:</label>
                  <input
                    type="text"
                    name="email"
                    value="pgarrido@gmail.com"
                    id=""
                    className=" border border-gray-400 px-2 py-1 rounded-md"
                  />
                </div>
              </div>
              <div className=" w-full flex flex-col gap-1">
                <label htmlFor="">Rol:</label>
                <select
                  name="role"
                  id=""
                  className=" border border-gray-400 px-2 py-1 rounded-md text-sm text-gray-400"
                >
                  <option value="2">Professor</option>
                </select>
              </div>
              <div className=" w-full flex flex-col gap-1">
                <label htmlFor="">Cursos:</label>
                <select
                  name="courses"
                  id=""
                  className=" border border-gray-400 px-2 py-1 rounded-md text-sm text-gray-400"
                >
                  <option value="2">6° Básico (A)</option>
                  <option value="2">6° Básico (B)</option>
                  <option value="2">7° Básico (A)</option>
                  <option value="2">6° Básico (B)</option>
                </select>
              </div>
              <div className=" w-full flex flex-col gap-1">
                <label htmlFor="">Asignaturas:</label>
                <select
                  name="Asignaturas"
                  id=""
                  className=" border border-gray-400 px-2 py-1 rounded-md text-sm text-gray-400"
                >
                  <option value="2">Mathematics</option>
                </select>
              </div>

              <div className=" w-full flex justify-between gap-[10px] flex-col lg:flex-row">
                <div className=" flex flex-col gap-1 flex-1">
                  <label htmlFor="">Colegio:</label>
                  <input
                    type="text"
                    name="name"
                    value="Southern Cross"
                    id=""
                    className=" border border-gray-400 px-2 py-1 rounded-md"
                  />
                </div>
                <div className=" flex flex-col gap-1 flex-1">
                  <label htmlFor="">Comuna:</label>
                  <input
                    type="text"
                    name="email"
                    value="Las Condes"
                    id=""
                    className=" border border-gray-400 px-2 py-1 rounded-md"
                  />
                </div>
              </div>
            </div>

            <div className=" flex justify-end">
              <button
                className=" bg-[#131432] text-white px-[20px] py-[5px] rounded-md"
                onClick={handleAddCourse}
              >
                Save
              </button>
            </div>
          </div>
          {/* <DatePicker /> */}
        </div>
      </div>
    </Wrapper>
  );
}
