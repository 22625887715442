import { useState } from "react";
import logo from "../Login/logo.jpg";

const Signup = () => {
  const [emailsNotification, setEmailsNotification] = useState(false);
  const [termsAccept, setTermsAccept] = useState(false);
  const [formData, setFormData] = useState({
    username: "",
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    school: "",
    role: "",
    birth: "",
    genre: "",
  });

  const handleInputChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };
  const handleFormSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
    console.log("conditions :", emailsNotification, termsAccept);
    setFormData({
      username: "",
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      confirmPassword: "",
      school: "",
      role: "",
      birth: "",
      genre: "",
    });
  };

  return (
    <div>
      <div className=" w-full h-[40vh] bg-[#131432] border-b-4 border-[#6972FF]">
        <div className=" p-[30px] flex justify-center sm:justify-start md:py-[20px] md:px-[40px]">
          <img src={logo} alt="" className=" w-[50px]" />
        </div>
      </div>

      {/* sign in form */}
      {/* xl:top-[200px] lg:top-[150px] */}
      <div className=" flex justify-center items-center p-[10px] absolute top-[100px] md:top-[50px] w-full md:justify-end md:pr-[30px] lg:justify-end lg:pr-[100px] xl:pr-[120px] 2xl:top-[500px]">
        <div className=" w-[400px] left-[680px] top-[50px] bg-white rounded-xl px-10 py-[20px] 2xl:w-[550px]">
          <div className=" flex flex-col gap-[10px]">
            <div className=" flex justify-between items-center">
              <h1 className=" text-xl font-bold">Sign In</h1>
              <p className=" text-sm text-gray-400">
                Already have an account?{" "}
                <a
                  href="/login"
                  className=" text-blue-600 cursor-pointer underline"
                >
                  Login here!
                </a>
              </p>
            </div>
            <form
              action=""
              className=" mt-4 flex flex-col gap-2 2xl:gap-[30px]"
            >
              <div className="flex flex-col gap-2 2xl:gap-[20px]">
                <div className=" flex flex-col gap-2 2xl:gap-[20px]">
                  <div>
                    <input
                      placeholder="Nombre de usuario"
                      type="text"
                      name="username"
                      value={formData.username}
                      onChange={handleInputChange}
                      id="username"
                      autocomplete="given-name"
                      class="block px-2 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                  <div>
                    <input
                      placeholder="Nombre"
                      type="text"
                      name="firstName"
                      value={formData.firstName}
                      onChange={handleInputChange}
                      id="password"
                      autocomplete="given-name"
                      class="block px-2 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>

                  <div>
                    <input
                      placeholder="Apellido"
                      type="text"
                      name="lastName"
                      value={formData.lastName}
                      onChange={handleInputChange}
                      id="password"
                      autocomplete="given-name"
                      class="block px-2 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                  <div>
                    <input
                      placeholder="Email"
                      type="text"
                      name="email"
                      value={formData.email}
                      onChange={handleInputChange}
                      id="password"
                      autocomplete="given-name"
                      class="block px-2 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>

                  <div>
                    <input
                      placeholder="Contraseña"
                      type="text"
                      name="password"
                      value={formData.password}
                      onChange={handleInputChange}
                      id="password"
                      autocomplete="given-name"
                      class="block px-2 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>

                <div className=" flex flex-col gap-2 2xl:gap-[20px]">
                  <div>
                    <input
                      placeholder="Confirmar Contraseña"
                      type="text"
                      name="confirmPassword"
                      value={formData.confirmPassword}
                      onChange={handleInputChange}
                      id="password"
                      autocomplete="given-name"
                      class="block px-2 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>

                  <div>
                    <select
                      placeholder="Colegio"
                      type="text"
                      name="school"
                      value={formData.school}
                      onChange={handleInputChange}
                      id="password"
                      autocomplete="given-name"
                      class="block px-2 w-full rounded-md border-0 py-1.5  shadow-sm ring-1 ring-inset ring-gray-300 text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    >
                      <option value="">Select Option</option>
                      <option value="school1">School1</option>
                      <option value="school2">School2</option>
                    </select>
                  </div>
                  <div>
                    <select
                      placeholder="Rol"
                      name="role"
                      value={formData.role}
                      onChange={handleInputChange}
                      id="password"
                      autocomplete="given-name"
                      class="block px-2 w-full rounded-md border-0 py-1.5  shadow-sm ring-1 ring-inset ring-gray-300 text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    >
                      <option value="admin">Admin</option>
                      <option value="moderator">Moderator</option>
                    </select>
                  </div>

                  <div>
                    <input
                      placeholder="Fecha de Nacimiento"
                      type="date"
                      name="birth"
                      value={formData.birth}
                      onChange={handleInputChange}
                      id="password"
                      autocomplete="given-name"
                      class="block px-2 w-full rounded-md border-0 py-1.5  shadow-sm ring-1 ring-inset ring-gray-300 text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                  <div>
                    <select
                      placeholder="Genero"
                      name="genre"
                      value={formData.genre}
                      onChange={handleInputChange}
                      id="password"
                      autocomplete="given-name"
                      class="block px-2 w-full rounded-md border-0 py-1.5  shadow-sm ring-1 ring-inset ring-gray-300 text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    >
                      <option value="genre1">Genre1</option>
                      <option value="genre2">Genre2</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className=" flex items-center gap-[4px]">
                <input
                  type="checkbox"
                  name="terms"
                  id=""
                  onChange={() => setTermsAccept(!termsAccept)}
                />
                <p className=" text-gray-400 text-sm">Terms and conditions</p>
              </div>
              <div className=" flex items-center gap-[4px]">
                <input
                  type="checkbox"
                  name="emails"
                  id=""
                  onChange={() => setEmailsNotification(!emailsNotification)}
                />
                <p className=" text-gray-400 text-sm">Receive emails</p>
              </div>

              <div
                className=" cursor-pointer bg-[#6972FF] text-white rounded text-center py-[4px]"
                onClick={handleFormSubmit}
              >
                <button>Sign in</button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div className=" w-full h-[84vh] bg-[#D9D9D9] md:h-[74vh] lg:h-[60vh]"></div>
    </div>
  );
};
export default Signup;
