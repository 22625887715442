import { MdDashboard } from "react-icons/md";
import { FaCalendarAlt } from "react-icons/fa";
import { FaGraduationCap } from "react-icons/fa";
import { IoSettingsOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

export default function Sidebar() {
  const navigate = useNavigate();
  const location = useLocation();
  const { routedPage } = location.state || "";
  console.log("Rounted page : ", routedPage);
  const [page, setPage] = useState(routedPage);
  console.log("page : ", page);
  const handlePages = (e) => {
    // e.preventDefault();
    setPage(e.currentTarget.id);
    navigate(`/${e.currentTarget.id}`, {
      state: { routedPage: e.currentTarget.id },
    });
  };
  return (
    <div className=" h-screen w-[100px] md:w-[170px] bg-[#131432] flex flex-col items-center">
      <div className=" flex items-center justify-center h-[60px] mt-[15px]">
        <img
          className=" w-[125px]"
          src="https://s3-alpha-sig.figma.com/img/7ea5/4ea3/0f0420a687a40be831ec155f8f70f596?Expires=1711929600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=DZgoIi3PUdCUcQLQ3rScZN8SCR8QwwE2jAcyHFIPEfYaaxQ276QxIX8GT9CUDmfTTe86yjLIZ~fnVSd2sraGKOjKbA2YRzzHHkHIChRFHGbBRzDJTFUjyudPoHqyXbhm1EZR46pWvXzQ8J07WUrD6HxLXdWDwj2CZ832B6QNDcdDTsxeRHvglSRet9vYdF-V~xcyhTZjbdRjmEERdvWM1vl9FmgxWzrYJwi6z4WSb8y0YQoYed-EJ4watP6Q6UWt5dz5ry4WhhGJdpq7CnXOPo1-JlXsCUGde7HW~vyZwV4P5HmczMMG1wyg6lztfVn06zfZXuROZ5LivzJj0rAZ2A__"
          alt=""
        />
      </div>

      {/* menu */}
      <div className=" gap-6 flex flex-col items-center text-white mt-[50px] max-h-full">
        <div
          onClick={handlePages}
          id="Dashboard"
          style={{
            color: page === "Dashboard" ? "#C94D3B" : "",
            opacity: page === "Dashboard" ? 1 : 0.5,
          }}
          className=" flex gap-2 items-center justify-center w-[100px] cursor-pointer"
        >
          <MdDashboard className=" w-[15px] h-[15px] md:w-[20px] md:h-[20px]" />
          <div className=" text-[12px] md:text-sm">Dashboard</div>
        </div>

        {/* <div
          onClick={handlePages}
          id="Planning"
          style={{
            color: page === "Planning" ? "#C94D3B" : "",
            opacity: page === "Planning" ? 1 : 0.5,
          }}
          className=" flex gap-2 items-center w-[100px] cursor-pointer"
        >
          <FaCalendarAlt />
          <div className=" text-sm">Planning</div>
        </div>

        <div
          onClick={handlePages}
          id="Courses"
          style={{
            color: page === "Courses" ? "#C94D3B" : "",
            opacity: page === "Courses" ? 1 : 0.5,
          }}
          className=" flex gap-2 items-center w-[100px] cursor-pointer"
        >
          <FaGraduationCap />
          <div className=" text-sm">Courses</div>
        </div> */}
      </div>

      <div
        onClick={handlePages}
        id="profile"
        style={{
          color: page === "Settings" ? "#C94D3B" : "",
          opacity: page === "Settings" ? 1 : 0.5,
        }}
        className="flex gap-2 items-center justify-center text-white w-[100px] mt-auto mb-[30px] cursor-pointer"
      >
        <IoSettingsOutline
          className="w-[15px] h-[15px] md:w-[20px] md:h-[20px]"
          color={page === "settings" ? "#C94D3B" : "white"}
        />
        <span className=" text-[12px] md:text-sm">Settings</span>
      </div>
    </div>
  );
}
