export default function VisaIcon() {
  return (
    <svg
      width="40"
      height="26"
      viewBox="0 0 40 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M37.8667 0.418945H2.13333C0.955126 0.418945 0 1.37564 0 2.55579V23.4434C0 24.6235 0.955126 25.5802 2.13333 25.5802H37.8667C39.0449 25.5802 40 24.6235 40 23.4434V2.55579C40 1.37564 39.0449 0.418945 37.8667 0.418945Z"
        fill="#0E4595"
      />
      <path
        d="M14.8374 18.2732L16.6167 7.81543H19.4625L17.682 18.2732H14.8374Z"
        fill="white"
      />
      <path
        d="M27.963 8.04177C27.3992 7.8299 26.5158 7.60254 25.4125 7.60254C22.6005 7.60254 20.6197 9.02092 20.6029 11.0537C20.587 12.5564 22.0169 13.3947 23.0964 13.8949C24.2041 14.4075 24.5765 14.7345 24.5712 15.1922C24.5642 15.8932 23.6867 16.2134 22.8687 16.2134C21.7296 16.2134 21.1245 16.0549 20.19 15.6645L19.8232 15.4982L19.4238 17.8393C20.0885 18.1312 21.3176 18.3842 22.5939 18.3973C25.5853 18.3973 27.5272 16.9951 27.5493 14.8244C27.56 13.6347 26.8019 12.7294 25.16 11.983C24.1653 11.4992 23.5561 11.1764 23.5626 10.6865C23.5626 10.2518 24.0782 9.78703 25.1923 9.78703C26.1229 9.77255 26.797 9.97582 27.3222 10.1877L27.5773 10.3084L27.963 8.04177Z"
        fill="white"
      />
      <path
        d="M35.2862 7.81543H33.0872C32.406 7.81543 31.8962 8.00165 31.597 8.68267L27.3706 18.2666H30.3589C30.3589 18.2666 30.8476 16.9779 30.9581 16.695C31.2846 16.695 34.1876 16.6995 34.6027 16.6995C34.6878 17.0656 34.9489 18.2666 34.9489 18.2666H37.5895L35.2862 7.81543ZM31.7973 14.5682C32.0326 13.9657 32.9311 11.6449 32.9311 11.6449C32.9143 11.6727 33.1647 11.0394 33.3084 10.6467L33.5008 11.5484C33.5008 11.5484 34.0457 14.0447 34.1596 14.5682H31.7973V14.5682Z"
        fill="white"
      />
      <path
        d="M2.447 7.81543L2.41064 8.03308C3.5355 8.30582 4.5402 8.70049 5.41981 9.19154L7.94492 18.2566L10.9559 18.2531L15.4361 7.81543H12.4216L9.63544 14.9469L9.33856 13.4977C9.32483 13.4534 9.30996 13.409 9.29471 13.3646L8.32585 8.69834C8.15359 8.03613 7.65393 7.83851 7.03585 7.81543H2.447Z"
        fill="white"
      />
    </svg>
  );
}
