import { FaGraduationCap } from "react-icons/fa";
import { GiBookAura } from "react-icons/gi";
import { FaEye } from "react-icons/fa";

const CourseBasicC = () => {
  return (
    <div className=" w-full p-[30px]">
      <div className=" bg-[#F6B846] text-white w-[250px] flex items-center gap-[10px] px-[8px] py-[10px] border-md rounded-md">
        <FaGraduationCap className=" w-[25px] h-[25px]" />
        <p>Curso: 6to básico (C)</p>
      </div>

      <div className=" bg-[#d2d2d2] mt-[20px] pt-[10px] h-[75vh] overflow-y-scroll">
        <div className="bg-[#131432] flex text-white items-center py-[2px]">
          <GiBookAura className="w-[25px] h-[25px] ml-[10px]" />
          <h1 className=" m-auto text-[20px]">Planificacion Semanal (1/4)</h1>
        </div>

        <div className=" px-[40px]">
          <div className=" flex justify-end my-[6px]">
            <p className="px-[10px] py-[5px] border border-gray-700 bg-white text-[10px]">
              Semana del 21 de febrero de 2024
            </p>
          </div>
          <div className=" flex flex-col gap-2">
            <div className="bg-[#131432] flex text-white items-center py-[4px] gap-4">
              <FaEye className="w-[20px] h-[20px] ml-[10px]" />
              <h1 className="text-[15px]">DATOS GENERALES</h1>
            </div>

            <div className=" flex justify-between ">
              <div className=" w-[300px] px-[10px] py-[5px] border border-gray-700 bg-white">
                <p className=" text-[12px]">Professor: Carlos Moreno</p>
              </div>
              <div className=" w-[300px] px-[10px] py-[5px] border border-gray-700 bg-white">
                <p className=" text-[12px]">Curso: 6to básico (A)</p>
              </div>
            </div>

            <div className=" flex justify-between">
              <div className=" w-[300px] px-[10px] py-[5px] border border-gray-700 bg-white">
                <p className=" text-[12px]">Asignatura: Languaje</p>
              </div>
              <div className=" w-[300px] px-[10px] py-[5px] border border-gray-700 bg-white">
                <p className=" text-[12px]">Unidad: #1 - Los elementos</p>
              </div>
            </div>
            <div className=" flex gap-2 text-[12px] py-[20px] items-center border border-gray-700 bg-white px-2">
              <p className=" w-[140px] border-r border-gray-500">
                Objetivo de Aprendizajo
              </p>
              <p>
                Lorem, ipsum dolor sit amet consectetur adipisicing elit. Animi.
              </p>
            </div>
          </div>

          <div className=" flex flex-col gap-2 mt-4">
            <div className="flex gap-2 text-white text-[12px]">
              <div className=" bg-[#131432] flex-1 flex justify-center items-center py-[10px]">
                <p className=" pb-[8px] border-b border-[#9c9b9b] w-[175px]">
                  Metas de Aprizandija
                </p>
              </div>

              <div className=" bg-[#131432] flex-1 flex justify-center items-center py-[10px]">
                <p className=" pb-[8px] border-b border-[#9c9b9b] w-[175px]">
                  Indicatiors (KLI's)
                </p>
              </div>

              <div className=" bg-[#131432] flex-1 flex justify-center items-center py-[10px]">
                <p className=" pb-[8px] border-b border-[#9c9b9b] w-[175px]">
                  Materials
                </p>
              </div>
            </div>

            <div className="flex gap-2 text-white text-[12px]">
              <div className=" min-h-[150px] bg-[#eaecee] flex-1 flex flex-col items-center py-[5px] border border-[#131432] text-black">
                <p className=" pb-[8px] border-b border-[#9c9b9b] w-[175px]">
                  Meta #1
                </p>
                <p className=" w-[175px]">
                  Actividad:
                  <br />
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                </p>
              </div>

              <div className=" min-h-[150px] bg-white flex-1 flex justify-center py-[5px] border border-[#131432] text-black">
                <ul className=" flex flex-col gap-2 w-[175px]">
                  <li>
                    <span>-</span>
                    <span>1:lorem</span>
                  </li>
                  <li>
                    <span>-</span>
                    <span>1:lorem</span>
                  </li>
                  <li>
                    <span>-</span>
                    <span>1:lorem</span>
                  </li>
                  <li>
                    <span>-</span>
                    <span>1:lorem</span>
                  </li>
                </ul>
              </div>

              <div className=" min-h-[150px] bg-white flex-1 flex justify-center py-[5px] border border-[#131432] text-black">
                <ul className=" flex flex-col gap-2 w-[175px]">
                  <li>
                    <span>Material 1:</span>
                  </li>
                  <li>
                    <span>Material 1:</span>
                  </li>
                  <li>
                    <span>Material 1:</span>
                  </li>
                  <li>
                    <span>Material 1:</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CourseBasicC;
