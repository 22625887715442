export default function DeleteIcon() {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.465 4.47819V4.70498C18.4197 4.79237 19.3665 4.90731 20.3046 5.04898C20.6519 5.10143 20.9981 5.15755 21.343 5.2173C21.7511 5.28799 22.0247 5.67617 21.954 6.0843C21.8833 6.49244 21.4951 6.76598 21.087 6.69529C21.0174 6.68323 20.9477 6.67132 20.878 6.65957L19.8726 19.7301C19.7523 21.2931 18.449 22.5 16.8814 22.5H9.04854C7.48093 22.5 6.1776 21.2931 6.05737 19.7301L5.05195 6.65957C4.98224 6.67132 4.91258 6.68323 4.84298 6.69529C4.43484 6.76598 4.04667 6.49244 3.97597 6.0843C3.90527 5.67617 4.17882 5.28799 4.58696 5.2173C4.93185 5.15755 5.27799 5.10143 5.62532 5.04898C6.56342 4.90731 7.51024 4.79237 8.46497 4.70498V4.47819C8.46497 2.91371 9.6775 1.57818 11.2804 1.52691C11.8398 1.50901 12.4013 1.5 12.965 1.5C13.5286 1.5 14.0902 1.50901 14.6495 1.52691C16.2524 1.57818 17.465 2.91371 17.465 4.47819ZM11.3284 3.02614C11.8717 3.00876 12.4173 3 12.965 3C13.5126 3 14.0582 3.00876 14.6016 3.02614C15.3562 3.05028 15.965 3.68393 15.965 4.47819V4.59082C14.9726 4.53056 13.9723 4.5 12.965 4.5C11.9576 4.5 10.9573 4.53056 9.96497 4.59082V4.47819C9.96497 3.68393 10.5737 3.05028 11.3284 3.02614ZM10.9736 8.97118C10.9576 8.55727 10.6092 8.23463 10.1953 8.25055C9.78139 8.26647 9.45876 8.61492 9.47468 9.02882L9.82083 18.0288C9.83675 18.4427 10.1852 18.7654 10.5991 18.7494C11.013 18.7335 11.3356 18.3851 11.3197 17.9712L10.9736 8.97118ZM16.4543 9.02882C16.4703 8.61492 16.1476 8.26647 15.7337 8.25055C15.3198 8.23463 14.9714 8.55727 14.9554 8.97118L14.6093 17.9712C14.5934 18.3851 14.916 18.7335 15.3299 18.7494C15.7438 18.7654 16.0923 18.4427 16.1082 18.0288L16.4543 9.02882Z"
        fill="#9F9FAB"
      />
    </svg>
  );
}
