import * as React from "react";
import { FaGraduationCap } from "react-icons/fa";
import CustomCard from "../../components/Dashboard_components/CustomCard";
import DatePicker from "../../components/DatePicker/DatePicker";
import Navbar from "../../components/Navbar/Navbar";
import Wrapper from "../../components/Wrapper/Wrapper";
import CustomImageCard from "../../components/Dashboard_components/CustomImageCard";
import professor from "../../components/img/professor.jpg";
import { useNavigate } from "react-router-dom";

export default function AddCourse() {
  const handleAddCourse = () => {
    return;
  };

  return (
    <Wrapper>
      <div className=" flex justify-between  h-[90vh]">
        <div className=" px-[30px] py-[15px] w-full overflow-y-scroll flex flex-col gap-2">
          {/* sections and grades */}
          <div>
            <h1 className=" text-xl font-bold">Course</h1>
          </div>

          {/* professors */}
          <div className=" mt-[5px] flex flex-col gap-4 flex-wrap text-[#717171]">
            <div className=" flex flex-col gap-1">
              <label htmlFor="">Curso:</label>
              <input
                type="text"
                name="course"
                id=""
                className=" border border-gray-400 px-2 py-1 rounded-md"
              />
            </div>
            <div className=" flex flex-col gap-1">
              <label htmlFor="">Asignatura:</label>
              <input
                type="text"
                name="subject"
                id=""
                className=" border border-gray-400 px-2 py-1 rounded-md"
              />
            </div>
            <div className="flex flex-col gap-1">
              <label htmlFor="">Horas pedagógicas por semana:</label>
              <div className=" flex gap-2">
                <div className="flex flex-col">
                  <label
                    htmlFor=""
                    className=" text-center text-sm text-gray-400"
                  >
                    lun
                  </label>
                  <select
                    name="lun"
                    id=""
                    className=" border border-gray-400 rounded-sm text-sm text-gray-400"
                  >
                    <option value="2">0-2</option>
                  </select>
                </div>

                <div className="flex flex-col">
                  <label
                    htmlFor=""
                    className=" text-center text-sm text-gray-400"
                  >
                    mar
                  </label>
                  <select
                    name="mar"
                    id=""
                    className=" border border-gray-400 rounded-sm text-sm text-gray-400"
                  >
                    <option value="2">0-2</option>
                  </select>
                </div>

                <div className="flex flex-col">
                  <label
                    htmlFor=""
                    className=" text-center text-sm text-gray-400"
                  >
                    mie
                  </label>
                  <select
                    name="mie"
                    id=""
                    className=" border border-gray-400 rounded-sm text-sm text-gray-400"
                  >
                    <option value="2">0-2</option>
                  </select>
                </div>

                <div className="flex flex-col">
                  <label
                    htmlFor=""
                    className=" text-center text-sm text-gray-400"
                  >
                    jue
                  </label>
                  <select
                    name="jue"
                    id=""
                    className=" border border-gray-400 rounded-sm text-sm text-gray-400"
                  >
                    <option value="2">0-2</option>
                  </select>
                </div>

                <div className="flex flex-col">
                  <label
                    htmlFor=""
                    className=" text-center text-sm text-gray-400"
                  >
                    vie
                  </label>
                  <select
                    name="vie"
                    id=""
                    className=" border border-gray-400 rounded-sm text-sm text-gray-400"
                  >
                    <option value="2">0-2</option>
                  </select>
                </div>
              </div>
            </div>

            {/* colors for course */}
            <div className="flex flex-col gap-1">
              <label htmlFor="">Color del Curso:</label>
              <div className=" flex gap-2">
                <div className="flex flex-col w-[35px] h-[25px] bg-[#FFBF4F] border border-[#707070]"></div>

                <div className="flex flex-col w-[35px] h-[25px] bg-[#7CC683] border border-[#707070]"></div>

                <div className="flex flex-col w-[35px] h-[25px] bg-[#CB5959] border border-[#707070]"></div>

                <div className="flex flex-col w-[35px] h-[25px] bg-[#131432] border border-[#707070]"></div>

                <div className="flex flex-col w-[35px] h-[25px] bg-[#6EDEF8] border border-[#707070]"></div>

                <div className="flex flex-col w-[35px] h-[25px] bg-[#FA90F6] border border-[#707070]"></div>
                <div className="flex flex-col w-[35px] h-[25px] bg-[#FFA01D] border border-[#707070]"></div>
              </div>
            </div>

            <div className=" flex flex-col gap-1">
              <label htmlFor="">Comentarios adicionales</label>
              <textarea
                name="comment"
                id=""
                cols="5"
                rows="5"
                className=" border border-gray-400 px-2 py-1 rounded-md"
              ></textarea>
            </div>
          </div>

          <div className=" flex justify-end">
            <button
              className=" bg-[#131432] text-white px-[20px] py-[5px] rounded-md"
              onClick={handleAddCourse}
            >
              Save
            </button>
          </div>
        </div>
        {/* <DatePicker /> */}
      </div>
    </Wrapper>
  );
}
