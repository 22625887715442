import { FaGraduationCap } from "react-icons/fa";
import { RiQuestionAnswerFill } from "react-icons/ri";
import { GoPlus } from "react-icons/go";
import { IoIosClose } from "react-icons/io";
import { FaRegEdit } from "react-icons/fa";

const CourseAPlanificationSemanalEvaluation = () => {
  return (
    <div className=" w-full p-[30px] text-[#646588]">
      <div className=" bg-[#F6B846] text-white w-fit flex items-center gap-[15px] px-[15px] py-[10px] border-md rounded-md">
        <FaGraduationCap className=" w-[30px] h-[30px]" />
        <p className=" text-[20px]">
          Curso: 6to básico (C): Planificación Semanal
        </p>
      </div>

      <div className=" mt-[15px] flex flex-col gap-4">
        <div className=" flex-1 bg-[#F0F0F0] flex last: gap-[5px] py-[10px] px-[15px] rounded-md border border-[gainsboro]">
          <p className=" text-[12px]">
            Estos son los indicadores de apredizajes recomendados segun metas y
            actividades escogidas.
          </p>
        </div>

        <div className=" flex gap-[5px]">
          <div className=" flex rounded-md border border-[gainsboro] bg-[#D0DFF2] gap-[5px] w-full">
            <div className=" flex">
              <div className="bg-[#D0DFF2] w-[75px] flex flex-col flex-1 justify-center items-center gap-[5px] py-[5px] px-[10px]">
                <span className=" text-[15px] flex flex-col justify-center items-center pb-[10px] border-b border-b-gray-500 w-full text-center">
                  <span>Meta</span>
                  <span>#1</span>
                </span>
                <p className=" text-[8px] pt-[5px]">
                  Identifica los factores bióticos y abióticos por medio de la
                  exploración.
                </p>
              </div>
              <div className=" flex items-center">
                <span className=" py-[50px] border-l border-gray-500"></span>
              </div>
            </div>

            <div className=" flex flex-col bg-[#D0DFF2] py-[10px]">
              <div className="flex gap-2 text-[10px]  items-center px-[5px]">
                {/* <span className=" py-[10px]">B.</span> */}
                <p>
                  B. Actividad lúdica conexión de conceptos con ejemplos
                  cotidianos: los factores bióticos y abióticos por medio de la
                  exploración.
                </p>
              </div>

              <div className="flex gap-2 text-[10px] items-center px-[5px] mt-auto">
                <ul>
                  <li>1. Identifica los factores bióticos</li>
                  <li>2. Identifica los abióticos</li>
                  <li>3. Categoriza los elementos en un ecosistema</li>
                  <li>4. No presenta errores de ortografía</li>
                </ul>
              </div>
            </div>
          </div>

          <div className=" flex flex-col items-center justify-center w-[200px] rounded-md border border-[gainsboro] bg-[#D0DFF2] p-[10px]">
            <div className="flex justify-center items-center flex-1">
              Materials
            </div>
            <div className=" flex-[3] bg-white py-[5px] px-[10px] rounded-sm text-[10px]">
              <ul>
                <li className=" flex items-center gap-[3px]">
                  <span className=" text-[10px] ml-[5px]">
                    1. Recortes de revistas
                  </span>{" "}
                </li>
                <li className=" flex items-center gap-[3px]">
                  <span className=" text-[10px] ml-[5px]">
                    2. Presentación Oral
                  </span>
                </li>
                <li className=" flex items-center gap-[3px]">
                  <span className=" text-[10px] ml-[5px]">3. Powerpoint</span>
                </li>
                <li className=" flex items-center gap-[3px]">
                  <span className=" text-[10px] ml-[5px]">4. Imágenes</span>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className=" flex gap-[5px]">
          <div className=" flex rounded-md border border-[gainsboro] bg-[#D0DFF2] gap-[5px] w-full">
            <div className=" flex">
              <div className="bg-[#D0DFF2] w-[75px] flex flex-col flex-1 justify-center items-center gap-[5px] py-[5px] px-[10px]">
                <span className=" text-[15px] flex flex-col justify-center items-center pb-[10px] border-b border-b-gray-500 w-full text-center">
                  <span>Meta</span>
                  <span>#2</span>
                </span>
                <p className=" text-[8px] pt-[5px]">
                  Categoriza los distintos factores (bióticos y abióticos) en
                  diferentes ecosistemas.
                </p>
              </div>
              <div className=" flex items-center">
                <span className=" py-[50px] border-l border-gray-500"></span>
              </div>
            </div>

            <div className=" flex flex-col bg-[#D0DFF2] py-[10px]">
              <div className="flex gap-2 text-[10px]  items-center px-[5px]">
                {/* <span className=" py-[10px]">B.</span> */}
                <p>A. Trabajos en clase (mini-investigaciones)</p>
              </div>

              <div className="flex gap-2 text-[10px] items-center px-[5px] mt-auto">
                <ul>
                  <li>
                    1. Categoriza los factores bióticos en diferentes
                    ecosistemas.
                  </li>
                  <li>
                    2. Categoriza los factores abióticos en diferentes
                    ecosistemas.
                  </li>
                  <li>3. BNbuslnonsak</li>
                </ul>
              </div>
            </div>
          </div>

          <div className=" flex flex-col items-center justify-center w-[200px] rounded-md border border-[gainsboro] bg-[#D0DFF2] p-[10px]">
            <div className="flex justify-center items-center flex-1">
              Materials
            </div>
            <div className=" flex-[3] bg-white py-[5px] px-[10px] rounded-sm text-[10px]">
              <ul>
                <li className=" flex items-center gap-[3px]">
                  <span className=" text-[10px] ml-[5px]">
                    1. Guía de investigación
                  </span>
                </li>
                <li className=" flex items-center gap-[3px]">
                  <span className=" text-[10px] ml-[5px]">
                    2. Elementos de laboratorio
                  </span>
                </li>
                <li className=" flex items-center gap-[3px]">
                  <span className=" text-[10px] ml-[5px]">3. Powerpoint</span>
                </li>
                <li className=" flex items-center gap-[3px]">
                  <span className=" text-[10px] ml-[5px]">4. Papel craft</span>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className=" flex-1 bg-[#D0DFF2] flex flex-col last: gap-[5px] py-[5px] px-[10px] rounded-md border border-[gainsboro]">
          <div className=" flex items-center gap-2">
            <RiQuestionAnswerFill className=" w-[20x] h-[20px] text-[dimgray]" />
            <input
              className=" bg-[#D0DFF2] px-4 outline-none w-full"
              type="text"
              placeholder="Escribe tu respuesta aqui..."
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default CourseAPlanificationSemanalEvaluation;
