import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../../globalContext/AuthContext/AuthContext";
import { useEffect, useState } from "react";

const withAuthProtection = (Component) => {
  const ProtectedRoute = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    // const { token } = useAuth();
    const token = localStorage.getItem("token");
    const [loading, setLoading] = useState(true);

    useEffect(() => {
      if (token !== "undefined" && token) {
        if (location.pathname === "/Login") {
          navigate("/Dashboard");
        } else {
          setLoading(false);
        }
      } else {
        if (location.pathname !== "/Login") {
          navigate("/Login");
        }
        setLoading(false);
      }
    }, [token, location.pathname, navigate]);

    if (loading) {
      return <div>Loading...</div>;
    }

    return <Component {...props} />;
  };

  return ProtectedRoute;
};

export default withAuthProtection;
