import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

export default function CustomCard({
  icon,
  color,
  title,
  text1,
  text2,
  subject,
}) {
  return (
    <Box>
      <Card
        variant="outlined"
        sx={{
          backgroundColor: color,
          width: "200px",
          height: "165px",
          borderRadius: "10px",
          color: "white",
          border: "1px solid #ccc",
        }}
      >
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "5px",
            height: "100%",
          }}
        >
          <Typography sx={{ fontSize: 14 }} component="div">
            {icon}
          </Typography>
          <Typography variant="h5" component="div" sx={{ fontSize: "18px" }}>
            {title}
          </Typography>
          <Typography
            variant="h5"
            component="div"
            sx={{ fontSize: "15px", marginBottom: "10px" }}
          >
            {subject}
          </Typography>
          <Typography
            variant="body2"
            component="div"
            sx={{ fontSize: "12px", marginTop: "auto" }}
          >
            <p className=" mb-[15px] opacity-[0.8]">{text1}</p>
            <p className=" mb-[-20px] opacity-[0.5] text-[10px]">{text2}</p>
          </Typography>
          {/* <Typography variant="body2">Creado el 27 Enero 2021</Typography> */}
        </CardContent>
      </Card>
    </Box>
  );
}
