import { FaGraduationCap } from "react-icons/fa";
import { RiQuestionAnswerFill } from "react-icons/ri";
import { GoPlus } from "react-icons/go";
import { IoIosClose } from "react-icons/io";
import { FaRegEdit } from "react-icons/fa";
import { GrLinkNext, GrLinkPrevious } from "react-icons/gr";
import { useEffect, useState } from "react";
import { TiTick } from "react-icons/ti";
import axios from "axios";

const tempIndicators = {
  0: ["Cargando...", "Cargando...", "Cargando..."],
  1: ["Cargando...", "Cargando...", "Cargando..."],
  2: ["Cargando...", "Cargando...", "Cargando..."],
  3: ["Cargando...", "Cargando...", "Cargando..."],
  4: ["Cargando...", "Cargando...", "Cargando..."],
};

const CourseAPlanificationSemanalEvaluation = ({
  data,
  setData,
  handlePrevious,
  handleNext,
}) => {
  const [editIndicatorGoal, setEditIndicatorGoal] = useState("");
  const [indicatorsOptions, setIndicatorsOptions] = useState(tempIndicators);
  const [editIndicatorIndex, setEditIndicatorIndex] = useState("");
  const [editIndicatorInput, setEditIndicatorInput] = useState("");

  console.log("data in evaluation : ", data);

  const goals = Object.values(data.goals).reduce((acc, goal, index) => {
    acc[`goal_${index}`] = goal.goal;
    return acc;
  }, {});
  console.log("Goals : ", goals);

  useEffect(() => {
    const indicatorsUrl = process.env.REACT_APP_API_URL+'/indicators';

    const fetchIndicators = async () => {
      try {
        const response = await axios.post(indicatorsUrl, {
          weeks: data.weeks,
          comments: data.comment,
          objectives: data.objectives,
          goals: goals,
        });
        const updatedData = Object.values(response.data).map((value) => {
          return Object.keys(value)
            .filter((key) => key.startsWith("indica"))
            .map((key) => value[key]);
        });

        setIndicatorsOptions(updatedData);
        console.log("Indicators : ", updatedData);
      } catch (error) {
        console.error("Failed to fetch indicators:", error);
      }
    };
    console.log("Fetching indicators...");
    fetchIndicators();
  }, []);

  const editIndicatorsOption = (goal_no, index, material) => {
    setEditIndicatorGoal(goal_no);
    setEditIndicatorIndex(index);
    setEditIndicatorInput(material);
  };

  const saveEditIndicators = () => {
    const newIndicators = { ...indicatorsOptions };

    newIndicators[editIndicatorGoal][editIndicatorIndex] = editIndicatorInput;

    setEditIndicatorGoal("");
    setEditIndicatorIndex("");
    setEditIndicatorInput("");
  };

  const removeMaterialOption = (goal_no, index) => {
    console.log(goal_no, index);

    const newIndicators = { ...indicatorsOptions };

    const removed_item = newIndicators[goal_no].splice(index, 1);
    console.log("Removed : ", removed_item);
    setIndicatorsOptions(newIndicators);
  };

  const handleNextPage = () => {
    console.log("handle next page");

    console.log("Data : ", data);

    const newData = {
      ...data,
      goals: Object.values(data.goals).map((goal, index) => ({
        goal: goal.goal,
        element: index,
        activity: goal.activity,
        materials: goal.materials,
        indicators: indicatorsOptions[index],
      })),
    };

    console.log("Updated Data : ", newData);
    setData(newData);

    handleNext();
  };

  const getIndicators = (
    goal_no,
    goal,
    materials,
    indicators,
    selected_activity
  ) => {
    return (
      <div className=" flex gap-[5px] flex-col lg:flex-row">
        {/* left side */}
        <div className=" flex rounded-md border border-[gainsboro] bg-[#D0DFF2] gap-[5px] w-full flex-col lg:flex-row">
          <div className=" flex flex-[1.5] flex-col gap-[10px] lg:flex-row">
            <div className="bg-[#D0DFF2] w-full lg:w-[75px] flex flex-col flex-1 justify-center items-center gap-[5px] p-[10px]">
              <span className=" text-[15px] flex flex-col justify-center items-center pb-[10px] border-b border-b-gray-500 w-full text-center">
                <span>Meta #{goal_no + 1}</span>
              </span>
              {/* choosen activity */}
              <p className=" text-[12px] lg:text-[15px] pt-[5px] text-center">
                {goal}
              </p>
            </div>
            <div className=" flex items-center px-[11px] py-[5px] lg:p-0">
              <span className=" lg:py-[70px] h-[2px] w-full bg-gray-500 lg:h-0 lg:border-l lg:border-gray-500"></span>
            </div>
          </div>

          <div className=" flex flex-col bg-[#D0DFF2] px-[20px] pb-[20px] sm:py-[20px] flex-[3] gap-[16px] sm:gap-[33px]">
            <div className="flex gap-2 text-[15px]  items-center px-[5px] pt-[12px]">
              {/* <span className=" py-[10px]">B.</span> */}
              <p className=" text-[12px] sm:text-[15px]">
                Actividad: {selected_activity}
              </p>
            </div>

            {/* meta activites */}
            <div className="flex gap-2 text-[10px] items-center px-[5px]">
              <ul>
                <li className=" font-bold">Materiales:</li>
                <li>
                  {materials.map((material, index) => {
                    return (
                      <li>
                        {index + 1}. {material}
                      </li>
                    );
                  })}
                </li>
              </ul>
            </div>
          </div>
        </div>

        {/* right side */}
        <div className=" flex flex-col gap-[10px] lg:items-center justify-center w-full lg:w-[364px] rounded-md border border-[gainsboro] bg-[#D0DFF2] p-[10px]">
          <div className="flex justify-center items-center flex-1 text-[#646588]">
            Indicadores:
          </div>
          <div className=" flex-[3] bg-white py-[5px] px-[10px] rounded-sm text-[10px]">
            <ul>
              {indicators.map((indicator, index) => {
                return (
                  <li className=" flex items-center gap-[3px]">
                    <IoIosClose
                      onClick={() => removeMaterialOption(goal_no, index)}
                      className=" w-[25px] h-[25px] cursor-pointer"
                    />
                    {editIndicatorGoal === goal_no &&
                    editIndicatorIndex === index ? (
                      <TiTick
                        className=" w-[18px] h-[18px] cursor-pointer"
                        onClick={saveEditIndicators}
                      />
                    ) : (
                      <FaRegEdit
                        className=" w-[15px] h-[15px] cursor-pointer"
                        onClick={() =>
                          editIndicatorsOption(goal_no, index, indicator)
                        }
                      />
                    )}
                    {/* <span className=" text-[15px] ml-[5px]">
                        {index + 1}. {material}
                      </span> */}
                    <div className=" flex items-center justify-center gap-[8px] text-[15px] ml-[5px] w-full">
                      <span>{index + 1}</span>
                      <input
                        type="text"
                        name=""
                        id=""
                        // onChange={(e) => setMaterialInput(e.target.value)}
                        className=" w-full outline-none px-[4px]"
                        style={{
                          backgroundColor:
                            editIndicatorIndex === index &&
                            editIndicatorGoal === goal_no &&
                            "gainsboro",
                        }}
                        // placeholder="Add Material"
                        disabled={
                          !(
                            editIndicatorIndex === index &&
                            editIndicatorGoal === goal_no
                          )
                        }
                        onChange={(e) => setEditIndicatorInput(e.target.value)}
                        value={
                          editIndicatorGoal === goal_no &&
                          editIndicatorIndex === index
                            ? editIndicatorInput
                            : indicator
                        }
                      />
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className=" w-full p-[20px] sm:p-[30px] text-[#646588] h-[92vh] overflow-scroll">
      <div className=" bg-[#C94D3B] text-white w-fit flex items-center gap-[15px] px-[15px] py-[10px] border-md rounded-md">
        <FaGraduationCap className=" w-[30px] h-[30px] hidden sm:block" />
        <p className=" text-[12px] md:text-[20px]">
          Curso: 6to básico (A): Planificación Semanal
        </p>
      </div>

      <div className=" mt-[15px] flex flex-col gap-4 overflow-scroll pb-[20px] max-h-[75vh]">
        <div className=" flex-1 bg-[#F0F0F0] flex last: gap-[5px] py-[10px] px-[15px] rounded-md border border-[gainsboro]">
          <p className=" text-[12px]">
            Estos son los indicadores de apredizajes recomendados segun metas y
            actividades escogidas.
          </p>
        </div>

        {/* meta data */}
        {Object.keys(data.goals).map((key, index) => {
          const indicators = indicatorsOptions[key];
          console.log("Indicators : ", indicators);
          console.log("Data to use : ", data);
          console.log("Key : ", key);
          const materials = data.goals[key].materials;
          const activity = data.goals[key].activity;
          const goal = data.goals[key].goal;

          console.log("Materials : ", materials);
          console.log("Activity : ", activity);

          return getIndicators(index, goal, materials, indicators, activity);
        })}

        {/* <div className=" flex-1 bg-[#D0DFF2] flex flex-col last: gap-[5px] py-[5px] px-[10px] rounded-md border border-[gainsboro]">
          <div className=" flex items-center gap-2">
            <RiQuestionAnswerFill className=" w-[20x] h-[20px] text-[dimgray]" />
            <input
              className=" bg-[#D0DFF2] px-4 outline-none w-full"
              type="text"
              placeholder="Escribe tu respuesta aqui..."
            />
          </div>
        </div> */}

        <div className=" flex-1 gap-2 flex justify-end">
          <div
            onClick={handlePrevious}
            className=" flex items-center bg-[#65CD6F] w-[200px] rounded-sm py-[4px] px-[6px] cursor-pointer"
          >
            <span className=" w-[18px] h-[18px] flex items-center justify-center bg-slate-50 rounded-full">
              <GrLinkPrevious className="w-[12px] h-[12px]" color="#65CD6F" />
            </span>
            <p className=" text-sm m-auto text-white">Atras</p>
          </div>
          <div
            onClick={handleNextPage}
            className=" flex items-center bg-[#65CD6F] w-[200px] rounded-sm py-[4px] px-[6px] cursor-pointer"
          >
            <span className=" w-[18px] h-[18px] flex items-center justify-center bg-slate-50 rounded-full">
              <GrLinkNext className="w-[12px] h-[12px]" color="#65CD6F" />
            </span>
            <p className=" text-sm m-auto text-white">Finalizar</p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CourseAPlanificationSemanalEvaluation;
