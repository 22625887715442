import { FaGraduationCap } from "react-icons/fa";
import { RiQuestionAnswerFill } from "react-icons/ri";
import { GoPlus } from "react-icons/go";
import { IoIosClose } from "react-icons/io";
import { FaRegEdit } from "react-icons/fa";
import { GrLinkNext, GrLinkPrevious } from "react-icons/gr";
import { useEffect, useState } from "react";
import { TiTick } from "react-icons/ti";
import axios from "axios";

// const tempMeta = [
//   "Trabajos en clase (mini-investigaciones",
//   "Actividad lúdica conexión de conceptos con ejemplos cotidianos: los factores bióticos y abióticos por medio de la exploración.",
//   "Presentación Oral",
// ];

const tempMate = {
  0: ["Cargando...", "Cargando...", "Cargando..."],
  1: ["Cargando...", "Cargando...", "Cargando..."],
  2: ["Cargando...", "Cargando...", "Cargando..."],
  3: ["Cargando...", "Cargando...", "Cargando..."],
  4: ["Cargando...", "Cargando...", "Cargando..."],
};

const indicators = [
  "Identificar los factores bioticos",
  "Identifica los abioticos",
  "Categoriza los elementos en un ecosistema",
  "No presenta errores de ortografía",
];

const makeObject = (goals) => {
  console.log("Making object...");
  const tempObj = {};
  for (let index = 0; index < goals; index++) {
    tempObj[`goal_${index + 1}`] = {
      materials: [...tempMate],
    };
  }
  return tempObj;
};

const CourseAPlanificationMaterials = ({
  handleNext,
  handlePrevious,
  data,
  setData,
}) => {
  // const [data, setData] = useState({ ...makeObject(objectives.length) });
  const [addedMetaGoal, setAddedMetaGoal] = useState("");
  const [addedMaterialGoal, setAddedMaterialGoal] = useState("");
  const [materialsOptions, setMaterialsOptions] = useState(tempMate);
  const [materialInput, setMaterialInput] = useState("");
  const [editMaterialGoal, setEditMaterialGoal] = useState("");
  const [editMaterialIndex, setEditMaterialIndex] = useState("");
  const [editMaterialInput, setEditMaterialInput] = useState("");
  console.log("data at the beginning of materials: ", data);

  useEffect(() => {
    const fetchMaterials = async () => {
      const materialsUrl = process.env.REACT_APP_API_URL+'/materials';
  
      try {
        const response = await axios.post(materialsUrl, {
          weeks: data.weeks,
          comments: data.comment,
          objectives: data.objectives,
          activities: data.goals,
        });
        //const updatedData = response.data.materiales;
        const updatedData = Object.values(response.data.materiales).map(
          (value) => {
            return Object.keys(value)
              .filter((key) => key.startsWith("material"))
              .map((key) => value[key]);
          }
        );

        setMaterialsOptions(updatedData);
        console.log("Materials : ", updatedData);
      } catch (error) {
        console.error("Failed to fetch materials:", error);
      }
    };
    console.log("Fetching materials...");
    fetchMaterials();
  }, []);

  const handleMaterialInputChange = () => {
    setAddedMaterialGoal("");
    setMaterialInput("");
  };

  const removeMaterialOption = (goal_no, index) => {
    console.log(goal_no, index);

    const newMaterials = { ...materialsOptions };

    const removed_item = newMaterials[goal_no].splice(index, 1);
    console.log("Removed : ", removed_item);
    setMaterialsOptions(newMaterials);
  };
  const addMaterial = (goal_no) => {
    if (!materialInput) {
      return;
    }
    materialsOptions[goal_no].push(materialInput);
    setMaterialsOptions({ ...materialsOptions });
    setAddedMaterialGoal("");
    setMaterialInput("");
  };
  const editMaterialOption = (goal_no, index, material) => {
    setEditMaterialGoal(goal_no);
    setEditMaterialIndex(index);
    setEditMaterialInput(material);
  };
  const saveEditMaterial = () => {
    const newMaterials = { ...materialsOptions };

    newMaterials[editMaterialGoal][editMaterialIndex] = editMaterialInput;

    setMaterialsOptions(newMaterials);
    setEditMaterialGoal("");
    setEditMaterialIndex("");
    setEditMaterialInput("");
  };

  const handleNextPage = () => {
    console.log("handle next page");

    console.log("Data : ", data);

    const newData = {
      ...data,
      goals: Object.values(data.goals).map((goal, index) => ({
        goal: goal.goal,
        element: index,
        activity: goal.activity,
        materials: materialsOptions[index],
      })),
    };

    console.log("Updated Data : ", newData);
    setData(newData);

    handleNext();
  };

  const getMetas = (activity, goal_no, goal) => {
    return (
      <div className=" flex flex-col gap-4">
        {/* meta */}
        <div className=" flex gap-4 flex-col sm:flex-row">
          <div className="bg-[#D0DFF2] flex flex-col flex-1 justify-center items-center gap-[5px] py-[5px] px-[10px] rounded-md border border-[gainsboro]">
            <span className=" text-[15px] flex sm:flex-col justify-center items-center pb-[10px] border-b border-b-gray-500 w-full text-center">
              <span>Meta</span>
              <span>#{goal_no + 1}</span>
            </span>
            <p className=" text-[8px] pt-[5px] text-center">{goal}</p>
          </div>
          {/* meta options */}
          <div className=" flex flex-col gap-2 flex-[9]">
            <div className=" bg-[#F0F0F0] cursor-pointer flex gap-2 justify-between rounded-md border border-[gainsboro] text-[10px]  items-center px-[5px]">
              {/* <span className=" py-[10px]">A.</span> */}
              <p className=" p-[5px]">
                {goal_no + 1}. {activity}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const getMaterials = (materials, goal_no) => {
    return (
      <div className=" flex flex-col gap-4">
        <div className=" flex-1 bg-[#D0DFF2] flex last: gap-[5px] py-[10px] px-[15px] rounded-md border border-[gainsboro]">
          <p className=" text-[12px]">
            De acuerdo a la actividad escogida para la meta #{goal_no + 1} te
            sugiero los siguientes materiales. También puedes cambiar o agregar
            materiales si lo deseas.
          </p>
        </div>

        {/* materials */}
        <div className=" flex gap-4 flex-col sm:flex-row">
          <div className=" flex-[10] flex flex-col sm:flex-row items-center py-[10px] px-[5px] sm:px-0 gap-[5px] rounded-md border border-[gainsboro] bg-[#D0DFF2] py-[10px] sm:pr-[10px]">
            <div className="flex justify-center items-center flex-1">
              Materiales
            </div>
            <div className=" flex-[3] bg-white py-[5px] w-full lg:w-fit px-[4px] sm:px-[10px] rounded-sm text-[10px]">
              <ul>
                {materials.map((material, index) => {
                  return (
                    <li className=" flex items-center gap-[3px]">
                      <div className=" flex items-center">
                        <IoIosClose
                          onClick={() => removeMaterialOption(goal_no, index)}
                          className=" w-[22px] h-[22px] sm:w-[25px] sm:h-[25px] cursor-pointer"
                        />
                        {editMaterialGoal === goal_no &&
                        editMaterialIndex === index ? (
                          <TiTick
                            className=" w-[16px] h-[16px]  sm:w-[18px] sm:h-[18px] cursor-pointer"
                            onClick={saveEditMaterial}
                          />
                        ) : (
                          <FaRegEdit
                            className=" w-[12px] h-[12px] sm:w-[15px] sm:h-[15px] cursor-pointer"
                            onClick={() =>
                              editMaterialOption(goal_no, index, material)
                            }
                          />
                        )}
                      </div>
                      {/* <span className=" text-[15px] ml-[5px]">
                        {index + 1}. {material}
                      </span> */}
                      <div className=" flex items-center justify-center text-[12px] sm:text-[15px] ml-[5px] w-full">
                        <span className="flex items-center">{index + 1}:</span>
                        <input
                          type="text"
                          name=""
                          id=""
                          // onChange={(e) => setMaterialInput(e.target.value)}
                          className=" w-full outline-none px-[4px]"
                          style={{
                            backgroundColor:
                              editMaterialIndex === index &&
                              editMaterialGoal === goal_no &&
                              "gainsboro",
                          }}
                          // placeholder="Add Material"
                          disabled={
                            !(
                              editMaterialIndex === index &&
                              editMaterialGoal === goal_no
                            )
                          }
                          onChange={(e) => setEditMaterialInput(e.target.value)}
                          value={
                            editMaterialGoal === goal_no &&
                            editMaterialIndex === index
                              ? editMaterialInput
                              : material
                          }
                        />
                      </div>
                    </li>
                  );
                })}
                {addedMaterialGoal === goal_no && (
                  <li className=" flex items-center gap-[3px]">
                    <IoIosClose
                      onClick={handleMaterialInputChange}
                      className=" w-[25px] h-[25px] cursor-pointer"
                    />
                    <TiTick
                      className=" w-[18px] h-[18px] cursor-pointer"
                      onClick={() => addMaterial(goal_no)}
                    />
                    <div className=" flex items-center justify-center gap-[8px] text-[15px] ml-[5px]">
                      <span>{materialsOptions[goal_no].length + 1}</span>
                      <input
                        type="text"
                        name=""
                        id=""
                        onChange={(e) => setMaterialInput(e.target.value)}
                        className=" w-full outline-none"
                        placeholder="Add Material"
                      />
                    </div>
                  </li>
                )}
              </ul>
            </div>
          </div>
          <div className="flex-1 bg-[#D0DFF2] flex flex-col justify-center items-center gap-[5px] py-[5px] px-[10px] rounded-md border border-[gainsboro]">
            <span className=" flex justify-center items-center w-[30px] h-[30px] bg-[#131432] rounded-[25px]">
              <GoPlus
                onClick={() => setAddedMaterialGoal(goal_no)}
                className=" w-[25px] h-[25px] cursor-pointer"
                color="white"
              />
            </span>
            <p className=" text-[12px]">Agrega Material</p>
          </div>
        </div>
      </div>
    );
  };

  // useEffect(() => {
  //   setData({ ...makeObject(objectives.length) });
  // }, objectives);

  return (
    <div className=" w-full p-[20px] sm:p-[30px] text-[#646588] h-[92vh] overflow-scroll">
      <div className=" bg-[#C94D3B] text-white w-fit flex items-center gap-[15px] px-[15px] py-[10px] border-md rounded-md">
        <FaGraduationCap className=" w-[30px] h-[30px] hidden sm:block" />
        <p className=" text-[12px] md:text-[20px]">
          Curso: 6to básico (A): Planificación Semanal
        </p>
      </div>

      <div className=" mt-[15px] flex flex-col gap-4 overflow-scroll pb-[20px] max-h-[75vh]">
        {/* meta and materials */}
        {Object.keys(data.goals).map((key, index) => {
          const goal = data.goals[key].goal;
          const goal_no = index;
          const activity = data.goals[key].activity;
          console.log("Index : ", index);
          const materials = materialsOptions[index];
          console.log("Materials : ", materials);
          return (
            <div className=" flex flex-col gap-4" key={key}>
              {getMetas(activity, goal_no, goal)}
              {getMaterials(materials, goal_no, goal)}
            </div>
          );
        })}

        {/* <div className=" flex-1 bg-[#D0DFF2] flex flex-col last: gap-[5px] py-[5px] px-[10px] rounded-md border border-[gainsboro]">
          <div className=" flex items-center gap-2">
            <RiQuestionAnswerFill className=" w-[20x] h-[20px] text-[dimgray]" />
            <input
              className=" bg-[#D0DFF2] px-4 outline-none w-full"
              type="text"
              placeholder="Escribe tu respuesta aqui..."
            />
          </div>
        </div> */}

        <div className=" flex-1 flex gap-2 justify-end">
          <div
            onClick={handlePrevious}
            className=" flex items-center bg-[#65CD6F] w-[200px] rounded-sm py-[4px] px-[6px] cursor-pointer"
          >
            <span className=" w-[18px] h-[18px] flex items-center justify-center bg-slate-50 rounded-full">
              <GrLinkPrevious className="w-[12px] h-[12px]" color="#65CD6F" />
            </span>
            <p className=" text-sm m-auto text-white">Back</p>
          </div>
          <div
            onClick={handleNextPage}
            className=" flex items-center bg-[#65CD6F] w-[200px] rounded-sm py-[4px] px-[6px] cursor-pointer"
          >
            <span className=" w-[18px] h-[18px] flex items-center justify-center bg-slate-50 rounded-full">
              <GrLinkNext className="w-[12px] h-[12px]" color="#65CD6F" />
            </span>
            <p className=" text-sm m-auto text-white">Next</p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CourseAPlanificationMaterials;
