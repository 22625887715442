import React from "react";
import { Card, CardContent, CardMedia, Typography } from "@mui/material";

const CustomImageCard = ({
  imageUrl,
  title,
  description,
  cardWidth,
  imageWidth,
  imageHeight,
}) => {
  return (
    <Card
      sx={{
        maxWidth: 345,
        padding: "0px",
        borderRadius: "10px",
        width: cardWidth || "150px",
      }}
    >
      <CardMedia
        component="img"
        height="100"
        image={imageUrl}
        alt="Image"
        sx={{
          borderRadius: "50%",
          margin: "auto",
          marginTop: 2,
          width: imageWidth || "100px",
          height: imageHeight || "100px",
          objectFit: "fill",
        }}
      />
      <CardContent sx={{ textAlign: "center" }}>
        <Typography
          gutterBottom
          variant="h5"
          component="div"
          sx={{ fontSize: "15px", fontWeight: "bold" }}
        >
          {title}
        </Typography>
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{ fontSize: "12px" }}
        >
          {description}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default CustomImageCard;
