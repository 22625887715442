import { useState } from "react";
import CourseAPlanificationLearningObjective from "./CourseAPlanificationLearningObjective";
import CourseAPlanificationLearningGoals from "./CourseAPlanificationLearningGoals";
import CourseAPlanificationActivities from "./CourseAPlanificationActivities";
import CourseAPlanificationSemanalEvaluation from "./CourseAPlanificationSemanalEvaluation";
import CourseBasicA from "../../../pages/Course/CourseBasicA";
import Wrapper from "../../Wrapper/Wrapper";

const CourseAPlanificationOld = () => {
  // const [page, setPage] = useState("LearningObjectives");
  const [page, setPage] = useState(1);
  const handlePage = (e) => {
    console.log("Page : ", e.currentTarget.id);
    setPage(e.currentTarget.id);
  };
  return (
    <div className=" flex">
      <div className=" mt-[10px] flex flex-col gap-[8px] p-[20px] h-[250px] border-r border-[#ccc]">
        <div
          onClick={handlePage}
          id="LearningObjectives"
          className=" w-[185px] py-[10px] px-[20px] text-white bg-[#C94D3B] text-[12px] rounded-md cursor-pointer"
        >
          <p>Objetivo de aprendizaje</p>
        </div>
        <div
          onClick={handlePage}
          id="LearningGoals"
          className=" w-[185px] py-[10px] px-[20px] text-white bg-[#C94D3B] text-[12px] rounded-md cursor-pointer"
        >
          <p>Metas de aprendizaje</p>
        </div>
        <div
          onClick={handlePage}
          id="Activities"
          className=" w-[185px] py-[10px] px-[20px] text-white bg-[#C94D3B] text-[12px] rounded-md cursor-pointer"
        >
          <p>Actividades</p>
        </div>
        <div
          onClick={handlePage}
          id="Evaluation"
          className=" w-[185px] py-[10px] px-[20px] text-white bg-[#C94D3B] text-[12px] rounded-md cursor-pointer"
        >
          <p>Evaluacion</p>
        </div>
        <div
          onClick={handlePage}
          id="Revision"
          className=" w-[185px] py-[10px] px-[20px] text-white bg-[#C94D3B] text-[12px] rounded-md cursor-pointer"
        >
          <p>Revision y archivo</p>
        </div>
      </div>

      {page === "LearningObjectives" && (
        <CourseAPlanificationLearningObjective />
      )}
      {page === "LearningGoals" && <CourseAPlanificationLearningGoals />}
      {page === "Activities" && <CourseAPlanificationActivities />}
      {page === "Evaluation" && <CourseAPlanificationSemanalEvaluation />}
      {page === "Revision" && <CourseBasicA />}
    </div>
  );
};
export default CourseAPlanificationOld;
