export default function UncheckedIcon() {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.25 8C1.25 3.99594 4.49594 0.75 8.5 0.75H16.5C20.5041 0.75 23.75 3.99594 23.75 8V16C23.75 20.0041 20.5041 23.25 16.5 23.25H8.5C4.49594 23.25 1.25 20.0041 1.25 16V8Z"
        stroke="#8A8A97"
        stroke-width="1.5"
      />
    </svg>
  );
}
