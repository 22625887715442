import Navbar from "../Navbar/Navbar";
import Sidebar from "../Sidebar/Sidebar";

const Wrapper = ({ children }) => {
  return (
    <div className=" flex w-full h-[100vh]">
      <Sidebar />
      <div className=" flex flex-col w-full overflow-x-hidden">
        <Navbar />
        <div className=" overflow-y-hidden">{children}</div>
      </div>
    </div>
  );
};
export default Wrapper;
