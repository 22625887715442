import dayjs from "dayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { useState } from "react";

export default function DatePicker() {
  const [value, setValue] = useState(dayjs(new Date()));

  return (
    <div className=" w-[400px] hidden p-[20px] h-screen bg-[#F7FAFF] md:block">
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer
          sx={{
            width: "325px",
            // height: "315px",
            backgroundColor: "white",
            borderRadius: "10px",
            // justifyContent: "center",
            // alignItems: "center",
          }}
          components={["DateCalendar", "DateCalendar"]}
        >
          <DemoItem>
            <DateCalendar
              sx={{ height: "100%" }}
              value={value}
              onChange={(newValue) => setValue(newValue)}
            />
          </DemoItem>
        </DemoContainer>
      </LocalizationProvider>
    </div>
  );
}
