import CustomButton from "../../components/CustomButton/CustomButton";
import { TextField } from "@mui/material";
import { IoMdArrowBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { HiOutlineFingerPrint } from "react-icons/hi2";

export default function EmailComponent({ email, setEmail, onClick, errors }) {
  const navigate = useNavigate();

  return (
    <div className=" flex flex-col gap-[30px] w-max sm:w-[327px]">
      <div className=" flex items-center justify-center">
        <span className=" w-[40px] h-[40px] rounded-lg bg-white flex items-center justify-center shadow-md border border-[#cccc]">
          <HiOutlineFingerPrint style={{ width: "25px", height: "25px" }} />
        </span>
      </div>
      <div className=" flex flex-col gap-2 text-center">
        <p className=" text-2xl">Forgot password</p>
        <p className=" text-gray-500 text-[15px]">
          No worries, we will send you reset instructions.
        </p>
      </div>
      <div className=" flex flex-col gap-[20px]">
        <div>
          <TextField
            id="filled-basic"
            placeholder="Enter Email"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            variant="outlined"
            FormHelperTextProps={{ style: { color: "#d32f2f", marginLeft: 0 } }}
            error={errors.email}
            helperText={errors.email && errors.email}
            required
            sx={{
              width: "100%",
              "& .MuiFilledInput-root": {
                borderRadius: "10px",
                background: "white",
              },
              "& .MuiOutlinedInput-root": {
                "& input": {
                  padding: "7px 10px",
                },
              },
            }}
          />
        </div>
        <div>
          <CustomButton
            text={"Reset password"}
            backgroundColor={"#2e2ef5"}
            width={"100%"}
            onClick={onClick}
          />
        </div>
      </div>
      <div className=" flex gap-2 items-center justify-center text-gray-500">
        <IoMdArrowBack
          onClick={() => navigate("/Login")}
          style={{ width: "20px", height: "20px", cursor: "pointer" }}
        />
        <span onClick={() => navigate("/Login")} style={{ cursor: "pointer" }}>
          Back to log in
        </span>
      </div>
    </div>
  );
}
