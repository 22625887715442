import { useRef, useState } from "react";
import Wrapper from "../../components/Wrapper/Wrapper";
import Avatar from "@mui/material/Avatar";
import CustomButton from "../../components/CustomButton/CustomButton";
import { TextField } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormHelperText from "@mui/material/FormHelperText";

export default function EditProfile() {
  const [selectedFile, setSelectedFile] = useState(null);
  const imgRef = useRef();
  const [showPassword, setShowPassword] = useState(false);

  const [profileData, setProfileData] = useState({
    image: "",
    firstName: "",
    lastName: "",
    email: "",
    school: "",
    birth: "",
    gender: "",
    address: "",
  });

  const [passwordFields, setPasswordFields] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    school: "",
    birth: "",
    gender: "",
    address: "",
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const handleInputChange = (e) => {
    setProfileData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handlePasswordInputChange = (e) => {
    setPasswordFields((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSetErrors = (key, message) => {
    setErrors((prev) => ({
      ...prev,
      [key]: message,
    }));
  };

  const handleEditProfileSubmit = () => {
    if (!profileData.firstName) {
      handleSetErrors("firstName", "Kindly Enter first name");
      return;
    } else {
      handleSetErrors("firstName", "");
    }
    if (!profileData.lastName) {
      handleSetErrors("lastName", "Kindly Enter last name");
      return;
    } else {
      handleSetErrors("lastName", "");
    }
    if (!profileData.email) {
      handleSetErrors("email", "Kindly enter email");
      return;
    } else {
      handleSetErrors("email", "");
    }
    if (!profileData.school) {
      handleSetErrors("school", "Kindly Enter school");
      return;
    } else {
      handleSetErrors("school", "");
    }
    if (!profileData.address) {
      handleSetErrors("address", "Kindly Enter your address");
      return;
    } else {
      handleSetErrors("address", "");
    }
    if (!profileData.birth) {
      handleSetErrors("birth", "Kindly Enter birth date");
      return;
    } else {
      handleSetErrors("birth", "");
    }
    if (!profileData.gender) {
      handleSetErrors("gender", "Kindly Enter your gender");
      return;
    } else {
      handleSetErrors("gender", "");
    }

    console.log("profile data : ", profileData);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && (file.type === "image/png" || file.type === "image/jpeg")) {
      setSelectedFile(file);
    } else {
      setSelectedFile(null);
    }
  };

  const handleUpload = () => {
    imgRef.current.click();
  };

  const handleRemove = () => {
    setSelectedFile(null);
  };

  const handleChangePasswordSubmit = () => {
    if (!passwordFields.oldPassword) {
      handleSetErrors("oldPassword", "Kindly Enter Old Password");
      return;
    } else {
      handleSetErrors("oldPassword", "");
    }
    if (!passwordFields.newPassword) {
      handleSetErrors("newPassword", "Kindly Enter New Password");
      return;
    } else {
      handleSetErrors("newPassword", "");
    }
    if (!passwordFields.confirmPassword) {
      handleSetErrors("confirmPassword", "Kindly Enter Confirm Password");
      return;
    } else {
      handleSetErrors("confirmPassword", "");
    }
    if (passwordFields.newPassword !== passwordFields.confirmPassword) {
      handleSetErrors(
        "confirmPassword",
        "New password and confirm password are not same."
      );
      return;
    } else {
      handleSetErrors("confirmPassword", "");
    }
    if (passwordFields.newPassword.length < 8) {
      handleSetErrors(
        "confirmPassword",
        "Password must be greater than 8 characters."
      );
      return;
    }
    console.log(passwordFields);
  };

  return (
    <Wrapper>
      <div className=" w-full h-[92vh] overflow-scroll">
        <div className=" flex flex-col gap-8 px-[25px] py-[40px] sm:py-[50px] sm:px-[50px] w-full sm:w-[500px]">
          <div className=" flex gap-[20px] items-center">
            <div onClick={handleUpload} style={{ cursor: "pointer" }}>
              {selectedFile ? (
                <Avatar
                  sx={{ width: "80px", height: "80px" }}
                  src={URL.createObjectURL(selectedFile)}
                ></Avatar>
              ) : (
                <Avatar sx={{ width: "80px", height: "80px" }}>P</Avatar>
              )}
              <input
                ref={imgRef}
                style={{ display: "none" }}
                type="file"
                accept=".png, .jpg, .jpeg"
                onChange={handleFileChange}
              />
            </div>
            <div className=" flex flex-col gap-1 text-[12px] sm:text-[18px]">
              <span>Profile Picture</span>
              <span className=" text-[12px] text-gray-500">
                PNG, JPG upto 5MB
              </span>
              <div className=" flex items-center gap-1">
                <span
                  className=" text-[12px] text-orange-500 cursor-pointer"
                  onClick={handleUpload}
                >
                  Update
                </span>
                {/* <span
                className=" text-[12px] text-orange-500 cursor-pointer"
                onClick={handleRemove}
              >
                Remove
              </span> */}
              </div>
            </div>
          </div>
          {/* fname */}
          <div className=" flex flex-col gap-1">
            <label htmlFor="" className=" text-gray-500">
              First Name
            </label>
            <TextField
              id="filled-basic"
              name="firstName"
              placeholder="First Name"
              onChange={handleInputChange}
              value={profileData.firstName}
              variant="outlined"
              FormHelperTextProps={{
                style: { color: "#d32f2f", marginLeft: 0 },
              }}
              error={errors.firstName}
              helperText={errors.firstName && errors.firstName}
              required
              sx={{
                width: "100%",
                "& .MuiFilledInput-root": {
                  borderRadius: "10px",
                  background: "white",
                },
                "& .MuiOutlinedInput-root": {
                  "& input": {
                    padding: "7px 10px",
                  },
                },
              }}
            />
          </div>
          {/* lname */}
          <div className=" flex flex-col gap-1">
            <label htmlFor="" className=" text-gray-500">
              Last Name
            </label>
            <TextField
              id="filled-basic"
              name="lastName"
              placeholder="Last Name"
              onChange={handleInputChange}
              value={profileData.lastName}
              variant="outlined"
              FormHelperTextProps={{
                style: { color: "#d32f2f", marginLeft: 0 },
              }}
              error={errors.lastName}
              helperText={errors.lastName && errors.lastName}
              required
              sx={{
                width: "100%",
                "& .MuiFilledInput-root": {
                  borderRadius: "10px",
                  background: "white",
                },
                "& .MuiOutlinedInput-root": {
                  "& input": {
                    padding: "7px 10px",
                  },
                },
              }}
            />
          </div>
          {/* email */}
          <div className=" flex flex-col gap-1">
            <label htmlFor="" className=" text-gray-500">
              Email
            </label>
            <TextField
              id="filled-basic"
              name="email"
              placeholder="Email"
              onChange={handleInputChange}
              value={profileData.email}
              variant="outlined"
              FormHelperTextProps={{
                style: { color: "#d32f2f", marginLeft: 0 },
              }}
              error={errors.email}
              helperText={errors.email && errors.email}
              required
              sx={{
                width: "100%",
                "& .MuiFilledInput-root": {
                  borderRadius: "10px",
                  background: "white",
                },
                "& .MuiOutlinedInput-root": {
                  "& input": {
                    padding: "7px 10px",
                  },
                },
              }}
            />
          </div>
          {/* school */}
          <div className=" flex flex-col gap-1">
            <label htmlFor="" className=" text-gray-500">
              School
            </label>
            <TextField
              id="filled-basic"
              name="school"
              placeholder="School"
              onChange={handleInputChange}
              value={profileData.school}
              variant="outlined"
              FormHelperTextProps={{
                style: { color: "#d32f2f", marginLeft: 0 },
              }}
              error={errors.school}
              helperText={errors.school && errors.school}
              required
              sx={{
                width: "100%",
                "& .MuiFilledInput-root": {
                  borderRadius: "10px",
                  background: "white",
                },
                "& .MuiOutlinedInput-root": {
                  "& input": {
                    padding: "7px 10px",
                  },
                },
              }}
            />
          </div>
          {/* address */}
          <div className=" flex flex-col gap-1">
            <label htmlFor="" className=" text-gray-500">
              Address
            </label>
            <TextField
              id="filled-basic"
              name="address"
              placeholder="Address"
              onChange={handleInputChange}
              value={profileData.address}
              variant="outlined"
              FormHelperTextProps={{
                style: { color: "#d32f2f", marginLeft: 0 },
              }}
              error={errors.address}
              helperText={errors.address && errors.address}
              required
              sx={{
                width: "100%",
                "& .MuiFilledInput-root": {
                  borderRadius: "10px",
                  background: "white",
                },
                "& .MuiOutlinedInput-root": {
                  "& input": {
                    padding: "7px 10px",
                  },
                },
              }}
            />
          </div>
          {/* Birthday */}
          <div className=" flex flex-col gap-1">
            <label htmlFor="" className=" text-gray-500">
              Birthday
            </label>
            <TextField
              id="filled-basic"
              name="birth"
              type="date"
              // placeholder="School"
              onChange={handleInputChange}
              value={profileData.birth}
              variant="outlined"
              FormHelperTextProps={{
                style: { color: "#d32f2f", marginLeft: 0 },
              }}
              error={errors.birth}
              helperText={errors.birth && errors.birth}
              required
              sx={{
                width: "100%",
                "& .MuiFilledInput-root": {
                  borderRadius: "10px",
                  background: "white",
                },
                "& .MuiOutlinedInput-root": {
                  "& input": {
                    padding: "7px 10px",
                  },
                },
              }}
            />
          </div>
          {/* gender */}
          <div className=" flex flex-col gap-1">
            <label htmlFor="" className=" text-gray-500">
              Gender
            </label>
            <Select
              name="gender"
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={profileData.gender}
              onChange={handleInputChange}
              inputProps={{ "aria-label": "Without label" }}
              sx={{
                height: "37px",
              }}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value={"male"}>Male</MenuItem>
              <MenuItem value={"female"}>Female</MenuItem>
              <MenuItem value={"other"}>Other</MenuItem>
            </Select>
            {errors.gender && (
              <FormHelperText sx={{ color: "#d32f2f" }}>
                {errors.gender}
              </FormHelperText>
            )}
          </div>
          {/* button */}
          <div>
            <CustomButton
              text={"Save"}
              backgroundColor={"#2e2ef5"}
              onClick={handleEditProfileSubmit}
            />
          </div>
          {/* password */}
          <div className=" flex flex-col gap-8">
            <p
              className=" text-blue-700 cursor-pointer"
              onClick={() => setShowPassword(!showPassword)}
            >
              Change Password
            </p>
            {showPassword && (
              <div className=" flex flex-col gap-4">
                <TextField
                  id="filled-basic"
                  name="oldPassword"
                  placeholder="Old password"
                  onChange={handlePasswordInputChange}
                  value={passwordFields.oldPassword}
                  variant="outlined"
                  FormHelperTextProps={{
                    style: { color: "#d32f2f", marginLeft: 0 },
                  }}
                  error={errors.oldPassword}
                  helperText={errors.oldPassword && errors.oldPassword}
                  required
                  sx={{
                    width: "100%",
                    "& .MuiFilledInput-root": {
                      borderRadius: "10px",
                      background: "white",
                    },
                    "& .MuiOutlinedInput-root": {
                      "& input": {
                        padding: "7px 10px",
                      },
                    },
                  }}
                />
                <TextField
                  id="filled-basic"
                  name="newPassword"
                  placeholder="New password"
                  onChange={handlePasswordInputChange}
                  value={passwordFields.newPassword}
                  variant="outlined"
                  FormHelperTextProps={{
                    style: { color: "#d32f2f", marginLeft: 0 },
                  }}
                  error={errors.newPassword}
                  helperText={errors.newPassword && errors.newPassword}
                  required
                  sx={{
                    width: "100%",
                    "& .MuiFilledInput-root": {
                      borderRadius: "10px",
                      background: "white",
                    },
                    "& .MuiOutlinedInput-root": {
                      "& input": {
                        padding: "7px 10px",
                      },
                    },
                  }}
                />
                <TextField
                  id="filled-basic"
                  name="confirmPassword"
                  placeholder="Confirm password"
                  onChange={handlePasswordInputChange}
                  value={passwordFields.confirmPassword}
                  variant="outlined"
                  FormHelperTextProps={{
                    style: { color: "#d32f2f", marginLeft: 0 },
                  }}
                  error={errors.confirmPassword}
                  helperText={errors.confirmPassword && errors.confirmPassword}
                  required
                  sx={{
                    width: "100%",
                    "& .MuiFilledInput-root": {
                      borderRadius: "10px",
                      background: "white",
                    },
                    "& .MuiOutlinedInput-root": {
                      "& input": {
                        padding: "7px 10px",
                      },
                    },
                  }}
                />
                <div>
                  <CustomButton
                    text={"Save"}
                    backgroundColor={"#2e2ef5"}
                    onClick={handleChangePasswordSubmit}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Wrapper>
  );
}
