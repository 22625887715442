import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { FaGraduationCap } from "react-icons/fa";
import CustomCard from "../../components/Dashboard_components/CustomCard";
import DatePicker from "../../components/DatePicker/DatePicker";
import { Link, useNavigate } from "react-router-dom";
import Wrapper from "../../components/Wrapper/Wrapper";
import { GoPlus } from "react-icons/go";
import { IoMdClose } from "react-icons/io";

export default function AddCourse({
  setOpenAddCourseModal,
  newCourse,
  handleInputChange,
  setCourseColor,
  handleAddCourse,
}) {
  return (
    <div className=" px-[10px] sm:px-[30px] py-[10px] w-full overflow-y-scroll flex flex-col gap-2">
      {/* sections and grades */}
      <div className=" flex justify-between">
        <h1 className=" text-xl font-bold">Course</h1>
        <div
          onClick={() => setOpenAddCourseModal(false)}
          className=" cursor-pointer flex items-center justify-center w-[28px] h-[28px] hover:bg-[gainsboro] rounded-full"
        >
          <IoMdClose className=" w-[18px] h-[18px]" />
        </div>
      </div>

      {/* professors */}
      <div className=" mt-[5px] flex flex-col gap-4 flex-wrap text-[#717171]">
        <div className=" w-full mt-[5px] lg:flex-col flex flex-col sm:flex-row justify-between gap-4">
          <div className=" flex flex-col gap-1">
            <label htmlFor="">Curso:</label>
            <input
              type="text"
              name="title"
              value={newCourse.title}
              onChange={handleInputChange}
              id=""
              className=" border border-gray-400 px-2 py-1 rounded-md"
            />
          </div>
          <div className=" flex flex-col gap-1">
            <label htmlFor="">Asignatura:</label>
            <input
              type="text"
              name="subject"
              onChange={handleInputChange}
              value={newCourse.subject}
              id=""
              className=" border border-gray-400 px-2 py-1 rounded-md"
            />
          </div>
        </div>
        {/* <div className=" flex flex-col gap-1">
          <label htmlFor="">Curso:</label>
          <input
            type="text"
            name="title"
            value={newCourse.title}
            onChange={handleInputChange}
            id=""
            className=" border border-gray-400 px-2 py-1 rounded-md"
          />
        </div>
        <div className=" flex flex-col gap-1">
          <label htmlFor="">Asignatura:</label>
          <input
            type="text"
            name="subject"
            onChange={handleInputChange}
            value={newCourse.subject}
            id=""
            className=" border border-gray-400 px-2 py-1 rounded-md"
          />
        </div> */}
        {/* course hours */}
        <div className="flex flex-col gap-1 w-full">
          <label htmlFor="">Horas pedagógicas por semana:</label>
          <div className=" flex gap-2">
            <div className="flex flex-col">
              <label htmlFor="" className=" text-center text-sm text-gray-400">
                lun
              </label>
              <select
                name="lun"
                id=""
                className=" border border-gray-400 rounded-sm text-sm text-gray-400"
              >
                <option value="2">0-2</option>
              </select>
            </div>

            <div className="flex flex-col">
              <label htmlFor="" className=" text-center text-sm text-gray-400">
                mar
              </label>
              <select
                name="mar"
                id=""
                className=" border border-gray-400 rounded-sm text-sm text-gray-400"
              >
                <option value="2">0-2</option>
              </select>
            </div>

            <div className="flex flex-col">
              <label htmlFor="" className=" text-center text-sm text-gray-400">
                mie
              </label>
              <select
                name="mie"
                id=""
                className=" border border-gray-400 rounded-sm text-sm text-gray-400"
              >
                <option value="2">0-2</option>
              </select>
            </div>

            <div className="flex flex-col">
              <label htmlFor="" className=" text-center text-sm text-gray-400">
                jue
              </label>
              <select
                name="jue"
                id=""
                className=" border border-gray-400 rounded-sm text-sm text-gray-400"
              >
                <option value="2">0-2</option>
              </select>
            </div>

            <div className="flex flex-col">
              <label htmlFor="" className=" text-center text-sm text-gray-400">
                vie
              </label>
              <select
                name="vie"
                id=""
                className=" border border-gray-400 rounded-sm text-sm text-gray-400"
              >
                <option value="2">0-2</option>
              </select>
            </div>
          </div>
        </div>

        {/* colors for course */}
        <div className="flex flex-col gap-1 w-full">
          <label htmlFor="">Color del Curso:</label>
          <div className=" flex gap-2">
            <div
              onClick={() => setCourseColor("#FFBF4F")}
              className=" cursor-pointer flex flex-col w-[35px] h-[25px] bg-[#FFBF4F] border border-[#707070]"
            ></div>

            <div
              onClick={() => setCourseColor("#7CC683")}
              className=" cursor-pointer flex flex-col w-[35px] h-[25px] bg-[#7CC683] border border-[#707070]"
            ></div>

            <div
              onClick={() => setCourseColor("#CB5959")}
              className=" cursor-pointer flex flex-col w-[35px] h-[25px] bg-[#CB5959] border border-[#707070]"
            ></div>

            <div
              onClick={() => setCourseColor("#131432")}
              className=" cursor-pointer flex flex-col w-[35px] h-[25px] bg-[#131432] border border-[#707070]"
            ></div>

            <div
              onClick={() => setCourseColor("#6EDEF8")}
              className=" cursor-pointer flex flex-col w-[35px] h-[25px] bg-[#6EDEF8] border border-[#707070]"
            ></div>

            <div
              onClick={() => setCourseColor("#FA90F6")}
              className=" cursor-pointer flex flex-col w-[35px] h-[25px] bg-[#FA90F6] border border-[#707070]"
            ></div>
            <div
              onClick={() => setCourseColor("#FFA01D")}
              className=" cursor-pointer flex flex-col w-[35px] h-[25px] bg-[#FFA01D] border border-[#707070]"
            ></div>
          </div>
        </div>

        <div className=" flex flex-col gap-1">
          <label htmlFor="">Comentarios adicionales</label>
          <textarea
            name="comment"
            onChange={handleInputChange}
            value={newCourse.comment}
            id=""
            cols="5"
            rows="3"
            className=" border border-gray-400 px-2 py-1 rounded-md"
          ></textarea>
        </div>
      </div>

      <div className=" flex justify-end">
        <button
          className=" bg-[#131432] text-white px-[20px] py-[5px] rounded-md"
          onClick={handleAddCourse}
        >
          Save
        </button>
      </div>
    </div>
  );
}
