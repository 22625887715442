import { FaGraduationCap } from "react-icons/fa";
import { RiQuestionAnswerFill } from "react-icons/ri";
import { GoPlus } from "react-icons/go";
import { GrLinkNext, GrLinkPrevious } from "react-icons/gr";
import { IoIosClose } from "react-icons/io";
import { useState, useEffect } from "react";
import { TiTick } from "react-icons/ti";
import axios from "axios";

const goalsTemp = ["Cargando..."];

const CourseAPlanificationLearningGoals = ({
  handlePrevious,
  handleNext,
  data,
  setData,
}) => {
  const [goals, setGoals] = useState(goalsTemp);
  const [addGoalInput, setAddGoalInput] = useState("");
  const [addGoal, setAddGoal] = useState(false);
  const [dragIndex, setDragIndex] = useState("");
  //console.log("Dragged Index : ", dragIndex);
  console.log("Data : ", data);
  console.log("CourseAPlanificationLearningGoals rendered");

  useEffect(() => {
    const goalsUrl = process.env.REACT_APP_API_URL+'/goals';

    const fetchGoals = async () => {
      try {
        const response = await axios.post(goalsUrl, {
          weeks: data.weeks,
          comments: data.comment,
          objectives: data.objectives,
        });
        const goalsArray = response.data.metas.map(
          (obj) => Object.values(obj)[0]
        );
        console.log(goalsArray);
        if (goalsArray.length === +data.weeks) {
          setGoals(goalsArray);
        } else {
          console.log(goalsArray);
          console.error("Number of goals does not match data.weeks");
        }
      } catch (error) {
        console.error("Failed to fetch goals:", error);
      }
    };

    fetchGoals();
  }, []);

  const handleRemoveGoal = (goal) => {
    setGoals(goals.filter((g) => g != goal));
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleAddGoal();
    }
  };

  const handleAddGoal = () => {
    if (!addGoalInput) {
      return;
    }
    setGoals([...goals, addGoalInput]);
    setAddGoal(false);
    setAddGoalInput("");
  };

  const closeAddGoalInput = () => {
    setAddGoalInput("");
    setAddGoal(false);
  };
  const handleDrop = (droppedIndex) => {
    const draggedValue = goals[dragIndex];
    const droppedValue = goals[droppedIndex];
    goals[dragIndex] = droppedValue;
    goals[droppedIndex] = draggedValue;
    setGoals([...goals]);
  };

  const goToNextInteraction = () => {
    for (const goal of Object.values(goals)) {
      console.log("GOAL: ", goal);
      // if (goal === "Cargando...") {
      //   return; // This will exit the handleNextPage function entirely
      // }
    }

    const newData = {
      ...data,
      goals: goals.map((goal, index) => ({ goal: goal, element: index })),
    };

    setData(newData);
    handleNext();
  };

  return (
    <div className=" w-full p-[20px] sm:p-[30px] h-[92vh] overflow-scroll">
      <div className=" bg-[#C94D3B] text-white w-fit flex items-center gap-[15px] px-[15px] py-[10px] border-md rounded-md">
        <FaGraduationCap className=" w-[35px] h-[35px] hidden sm:block" />
        <p className=" text-[12px] md:text-[20px]">
          Curso: 6to básico (A): Planificación Semanal
        </p>
      </div>

      <div className="mt-[15px] flex flex-col gap-4 overflow-scroll pb-[20px] max-h-[75vh] pr-[10px]">
        <div className=" flex-1 bg-[#D0DFF2] flex last: gap-[5px] py-[10px] px-[15px] rounded-md border border-[gainsboro]">
          <p className=" text-[12px] md:text-[20px]">
            Perfecto! Yo te puedo asistir en el proceso de creación de una
            planificación semanal para tu 6to básico (A).
          </p>
        </div>

        <div className=" flex-1 bg-[#D0DFF2] flex last: gap-[5px] py-[10px] px-[15px] rounded-md border border-[gainsboro]">
          <p className=" text-[12px] md:text-[20px]">
            Primero, debemos establecer el o los Objetivos de Aprendizaje de
            unidad a continuación...
          </p>
        </div>
        {data.objectives.map((objective, index) => (
          <div className=" flex-1 bg-[#D0DFF2] flex flex-col last: gap-[5px] py-[4px] px-[15px] rounded-md border border-[gainsboro]">
            <p className=" text-[12px] md:text-[20px]">
              Objetivo {index + 1}: {objective}
            </p>
          </div>
        ))}

        <div className=" flex-1 bg-[#D0DFF2] flex last: gap-[5px] py-[10px] px-[15px] rounded-md border border-[gainsboro]">
          <p className=" text-[12px] md:text-[20px]">
            Dados los Objetivos y el tiempo estipulado sugiero estas Metas de
            Aprendizaje por semana. Si deseas cambiar la progresión solo
            arrastra la Meta
          </p>
        </div>

        <div className=" flex gap-2 flex-wrap">
          {goals.map((goal, index) => {
            return (
              <div
                draggable
                onDragStart={() => setDragIndex(index)}
                onDrop={() => handleDrop(index)}
                key={index}
                onDragOver={(e) => e.preventDefault()}
                className=" relative basis-[258px] overflow-scroll py-[10px] flex gap-2 rounded-md border border-[gainsboro] text-[12px] bg-[#D0DFF2] items-center px-[5px]"
              >
                <span className=" flex py-[10px] text-[12px] md:text-[15px]">
                  {index + 1}.
                </span>
                <p className="pl-[6px] mt-[5px] md:m-0 border-l border-gray-500 text-[12px] md:text-[15px]">
                  {goal}
                </p>
                <IoIosClose
                  onClick={() => handleRemoveGoal(goal)}
                  className=" w-[18px] h-[18px] absolute top-0 right-[2px] text-gray-500 cursor-pointer"
                />
              </div>
            );
          })}
          {addGoal && (
            <div
              tabIndex="0"
              onKeyDown={handleKeyDown}
              className=" relative basis-[258px] overflow-scroll py-[5px] flex gap-2 rounded-md border border-[gainsboro] text-[12px] bg-[gainsboro] px-[5px]"
            >
              {/* <span className=" w-[50px] border-r border-gray-500 py-[10px]">
                8.
              </span> */}
              <textarea
                placeholder="Add Goal..."
                className="p-[5px] w-[95%] bg-transparent outline-none resize-none h-[59px]"
                rows={4}
                cols={4}
                onChange={(e) => setAddGoalInput(e.target.value)}
                value={addGoalInput}
              ></textarea>
              <IoIosClose
                onClick={closeAddGoalInput}
                className=" w-[20px] h-[20px] absolute top-0 right-0 text-gray-500 cursor-pointer"
              />
              <TiTick
                onClick={handleAddGoal}
                className=" w-[20px] h-[20px] absolute top-0 right-[15px] text-gray-500 cursor-pointer"
              />
            </div>
          )}

          {!addGoal && (
            <div className="bg-[#D0DFF2] h-[71px] flex basis-[260px] flex-col justify-center items-center gap-[5px] py-[10px] px-[15px] rounded-md border border-[gainsboro]">
              <span className=" flex justify-center items-center w-[26px] h-[26px] bg-[#131432] rounded-[25px]">
                <GoPlus
                  onClick={() => setAddGoal(true)}
                  className=" w-[22px] h-[22px] cursor-pointer"
                  color="white"
                />
              </span>
              <p className=" text-[12px]">Agrega Metas</p>
            </div>
          )}
        </div>
        {/* </div> */}

        <div className=" flex-1 flex gap-2 justify-end">
          <div
            onClick={handlePrevious}
            className=" flex items-center bg-[#65CD6F] w-[200px] rounded-sm py-[4px] px-[6px] cursor-pointer"
          >
            <span className=" w-[18px] h-[18px] flex items-center justify-center bg-slate-50 rounded-full">
              <GrLinkPrevious className="w-[12px] h-[12px]" color="#65CD6F" />
            </span>
            <p className=" text-[12px] sm:text-sm m-auto text-white">Back</p>
          </div>
          <div
            onClick={goToNextInteraction}
            className=" flex items-center bg-[#65CD6F] w-[200px] rounded-sm py-[4px] px-[6px] cursor-pointer"
          >
            <span className=" w-[18px] h-[18px] flex items-center justify-center bg-slate-50 rounded-full">
              <GrLinkNext className="w-[12px] h-[12px]" color="#65CD6F" />
            </span>
            <p className=" text-[12px] sm:text-sm m-auto text-white">Next</p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CourseAPlanificationLearningGoals;
