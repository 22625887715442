import { FaGraduationCap } from "react-icons/fa";
import { RiQuestionAnswerFill } from "react-icons/ri";
import { GrLinkNext } from "react-icons/gr";
import { GrLinkPrevious } from "react-icons/gr";
import { useState, useEffect } from "react";
import axios from "axios";
import CustomSnackbar from "../../CustomSnackbar/CustomSnackbar";
import { useLocation } from "react-router-dom";

const CourseAPlanificationLearningObjective = ({
  handleNext,
  objectives,
  setObjectives,
  data,
  setData,
  title,
  color,
}) => {
  const [objectivesOptions, setObjectivesOptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [weekInputAlert, setWeekInputAlert] = useState(false);
  const [inputValues, setInputValues] = useState({
    weeks: "",
    comment: "",
  });
  const [open, setOpen] = useState({ open: false, message: "" });

  console.log("Objectives : ", objectives);
  // Function to handle selection change
  const handleSelectChange = (event) => {
    const selectedValues = Array.from(
      event.target.selectedOptions,
      (option) => option.value
    );
    setObjectives(selectedValues);
  };

  const handleObjectiveChange = (value) => {
    if (checkIsSelected(value)) {
      setObjectives((prev) => [...prev.filter((item) => item !== value)]);
      return;
    }
    setObjectives((prev) => [...prev, value]);
  };

  const checkIsSelected = (value) => {
    return objectives.find((item) => item === value);
  };

  const handleInputChange = (event) => {
    const value = event.target.value;
    const numberValue = parseInt(value, 10);

    if (value === "" || !isNaN(numberValue)) {
      setInputValues({
        ...inputValues,
        [event.target.name]: value ? numberValue : value,
      });
      setWeekInputAlert(false);
    } else {
      console.log("NOT A NUMBER");
      setWeekInputAlert(true);
      setTimeout(() => {
        setWeekInputAlert(false);
      }, 3000);
    }
  };

  const goToNextInteraction = () => {
    if (!objectives.length) {
      setOpen({
        open: true,
        message: "Kindly select at least on objective to go to next.",
      });
      return;
    }
    const newData = {
      ...data,
      objectives,
      weeks: inputValues.weeks,
      comment: inputValues.comment,
    };

    setData(newData);
    console.log("Data : ", data);
    handleNext();
  };

  useEffect(() => {
    const fetchGoals = async () => {
      const objectivesUrl = process.env.REACT_APP_API_URL+'/objectives';
  
      try {
        const response = await axios.post(objectivesUrl);
        console.log(response.data);

        setObjectivesOptions(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Failed to fetch goals:", error);
        setLoading(false);
      }
    };

    fetchGoals();
  }, []);

  // console.log("Objectives : ", objectives);
  return (
    <div className=" w-full p-[20px] sm:p-[30px] h-[92vh] overflow-scroll">
      <div
        style={{ backgroundColor: color }}
        className=" text-white w-fit flex items-center gap-[15px] px-[15px] py-[10px] border-md rounded-md"
      >
        <FaGraduationCap className=" w-[35px] h-[35px] hidden sm:block" />
        <p className=" text-[12px] md:text-[20px]">
          Curso: {title}: Planificación Semanal
        </p>
      </div>

      <div className=" mt-[15px] flex flex-col gap-4 overflow-scroll pb-[20px] max-h-[75vh]">
        <div className=" flex-1 bg-[#D0DFF2] flex last: gap-[5px] py-[10px] px-[15px] rounded-md border border-[gainsboro]">
          <p className=" text-[12px] md:text-[15px]">
            Perfecto! Yo te puedo asistir en el proceso de creación de una
            planificación semanal para tu 6to básico (A).
          </p>
        </div>

        <div className=" flex-1 bg-[#D0DFF2] flex last: gap-[5px] py-[10px] px-[15px] rounded-md border border-[gainsboro]">
          <p className=" text-[12px] md:text-[15px]">
            Primero, debemos establecer el o los Objetivos de Aprendizaje de
            unidad a continuación...
          </p>
        </div>

        {/* showing objectives */}
        {/* <div className=" flex-1 bg-[#D0DFF2] flex flex-col last: gap-[5px] py-[10px] px-[15px] rounded-md border border-[gainsboro]">
          <select
            name="select-question"
            value={objectives}
            onChange={handleSelectChange}
            id=""
            className="block w-full shadow-sm text-gray-700 bg-transparent outline-none"
            multiple
          >
            {Object.entries(objectivesOptions).map(([key, value]) => (
              <option
                key={key}
                className="p-2 m-2 border border-[#dcdcdc] bg-white rounded w-full overflow-scroll"
              >
                {value}
              </option>
            ))}
          </select>
        </div> */}

        <div className="flex-1 bg-[#D0DFF2] flex flex-col gap-[5px] py-[10px] px-[15px] rounded-md border border-[gainsboro]">
          <h1 className="m-2 text-[12px] md:text-[15px]">
            Actividades disponibles:
          </h1>
          {/* 
          <select
            name="select-question"
            value={objectives}
            onChange={handleSelectChange}
            id=""
            className="block w-full shadow-sm text-gray-700 bg-transparent outline-none"
            multiple
          >
            {Object.entries(objectivesOptions).map(([key, value]) => (
              <option
                key={key}
                className="p-2 border border-[#dcdcdc] bg-white rounded whitespace-normal break-words mb-2"
              >
                {value}
              </option>
            ))}
          </select> */}

          {Object.entries(objectivesOptions).map(([key, value]) => (
            <span
              key={key}
              onClick={() => handleObjectiveChange(value)}
              style={{
                backgroundColor: checkIsSelected(value) ? "#a2c7ef" : "",
                // color: checkIsSelected(value) ? "white" : "",
              }}
              className=" text-[12px] md:text-[15px] p-2 border border-[#dcdcdc] bg-white rounded whitespace-normal break-words mb-2 cursor-pointer"
            >
              {value}
            </span>
          ))}
        </div>

        <div className=" flex-1 bg-[#D0DFF2] flex flex-col last: gap-[5px] py-[4px] px-[15px] rounded-md border border-[gainsboro]">
          <p className=" text-[12px] md:text-[15px]">
            Based on the chosen Learning Objectives, enter the number of weeks
            in below input:
          </p>
        </div>

        <div className=" relative flex-1 bg-[#D0DFF2] flex flex-col last: gap-[5px] py-[6px] px-[15px] rounded-md border border-[gainsboro]">
          {/* <RiQuestionAnswerFill className=" w-[20x] h-[20px] text-[dimgray]" /> */}
          <input
            className=" bg-[#D0DFF2] px-2 outline-none w-full"
            type="text"
            name="weeks"
            placeholder="Enter no of weeks"
            maxLength={2}
            value={inputValues.weeks}
            onChange={handleInputChange}
          />
          {weekInputAlert && (
            <span className=" absolute top-[36px] text-[12px] bg-white rounded-sm py-[4px] px-[6px] left-[2px] text-gray-500 shadow-md">
              You can only type number(e.g 4)
            </span>
          )}
        </div>

        <div className=" flex-1 bg-[#D0DFF2] flex flex-col last: gap-[5px] py-[10px] px-[15px] rounded-md border border-[gainsboro]">
          <div className=" flex items-center gap-2">
            <RiQuestionAnswerFill className=" w-[20x] h-[20px] text-[dimgray]" />
            <input
              className=" bg-[#D0DFF2] px-4 outline-none w-full"
              type="text"
              name="comment"
              placeholder="Escribe tu respuesta aqui..."
              value={inputValues.comment}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className=" flex-1 flex gap-2 justify-end">
          {/* <div className=" flex items-center bg-[#65CD6F] w-[200px] rounded-sm py-[4px] px-[6px]">
            <span className=" w-[18px] h-[18px] flex items-center justify-center bg-slate-50 rounded-full">
              <GrLinkPrevious className="w-[12px] h-[12px]" color="#65CD6F" />
            </span>
            <p className=" text-sm m-auto text-white">Back</p>
          </div> */}

          <div
            onClick={goToNextInteraction}
            className=" flex items-center bg-[#65CD6F] w-[100px] sm:w-[200px] rounded-sm py-[4px] px-[6px] cursor-pointer"
          >
            <span className="  w-[18px] h-[18px] flex items-center justify-center bg-slate-50 rounded-full">
              <GrLinkNext className="w-[12px] h-[12px]" color="#65CD6F" />
            </span>
            <p className=" text-sm m-auto text-white">Next</p>
          </div>
        </div>
      </div>
      <CustomSnackbar open={open} setOpen={setOpen} severity={"error"} />
    </div>
  );
};
export default CourseAPlanificationLearningObjective;
