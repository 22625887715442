import React from "react";
import Button from "@mui/material/Button";
// import { makeStyles } from "@mui/styles";

// const useStyles = makeStyles((theme) => ({
//   primary: {
//     backgroundColor: theme.palette.primary.main,
//     color: theme.palette.primary.contrastText,
//     "&:hover": {
//       backgroundColor: theme.palette.primary.dark,
//     },
//   },
//   secondary: {
//     backgroundColor: theme.palette.secondary.main,
//     color: theme.palette.secondary.contrastText,
//     "&:hover": {
//       backgroundColor: theme.palette.secondary.dark,
//     },
//   },
//   tertiary: {
//     backgroundColor: theme.palette.grey[500],
//     color: theme.palette.text.primary,
//     "&:hover": {
//       backgroundColor: theme.palette.grey[700],
//     },
//   },
// }));

const getVariantStyles = (variant) => {
  switch (variant) {
    case "primary":
      return {
        backgroundColor: "#7171FF",
        color: "primary.contrastText",
        "&:hover": {
          backgroundColor: "primary.dark",
        },
      };
    case "secondary":
      return {
        backgroundColor: "#fff",
        color: "black",
        "&:hover": {
          backgroundColor: "dark",
        },
      };
    case "tertiary":
      return {
        backgroundColor: "grey.500",
        color: "text.primary",
        "&:hover": {
          backgroundColor: "grey.700",
        },
      };
    default:
      return {};
  }
};

const CustomButton = ({
  variant = "primary",
  width,
  height,
  padding,
  startIcon,
  endIcon,
  text,
  color,
  fontSize,
  border,
  borderRadius,
  onClick,
  backgroundColor,
}) => {
  // const classes = useStyles();

  const handleOnClick = () => {
    if (typeof onClick === "function") {
      onClick();
    } else {
      console.log("On Click method is not passed");
    }
  };

  return (
    <Button
      // className={classes[variant]}
      startIcon={startIcon}
      endIcon={endIcon}
      onClick={handleOnClick}
      sx={{
        width,
        height,
        padding,
        color,
        fontSize,
        border,
        borderRadius,
        ...getVariantStyles(variant),
        backgroundColor: backgroundColor && backgroundColor,
        textTransform: "none",
      }}
    >
      <span>{text}</span>
    </Button>
  );
};

export default CustomButton;
