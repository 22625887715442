import { useState } from "react";
import CourseAPlanificationLearningObjective from "./CourseAPlanificationLearningObjective";
import CourseAPlanificationLearningGoals from "./CourseAPlanificationLearningGoals";
import CourseAPlanificationActivities from "./CourseAPlanificationActivities";
import CourseAPlanificationSemanalEvaluation from "./CourseAPlanificationSemanalEvaluation";
import CourseAPlanificationMaterials from "./CourseAPlanificationMaterials";
import CourseBasicA from "../../../components/Planification_components/CourseAPlanification/CourseBasicA";
import Wrapper from "../../Wrapper/Wrapper";
import { useLocation } from "react-router-dom";

const CourseAPlanification = () => {
  // const [page, setPage] = useState("LearningObjectives");
  const [page, setPage] = useState(1);
  const [objectives, setObjectives] = useState([]);
  const [data, setData] = useState({});
  const location = useLocation();
  const { courseTitle, color } = location.state || {};
  console.log("Data : ", courseTitle, color);
  console.log("PAGE : ", page);
  // const handlePage = (e) => {
  //   console.log("Page : ", e.currentTarget.id);
  //   setPage(e.currentTarget.id);
  // };
  const handleNext = () => {
    setPage((prev) => {
      return (prev += 1);
    });
  };
  const handlePrevious = () => {
    console.log("decreasing page : ", page);
    setPage((prev) => {
      return (prev -= 1);
    });
  };
  return (
    <Wrapper>
      <div className=" flex">
        {/* <div className=" mt-[10px] flex flex-col gap-[8px] p-[20px] h-[250px] border-r border-[#ccc]">
          <div
            onClick={handlePage}
            id="LearningObjectives"
            className=" w-[185px] py-[10px] px-[20px] text-white bg-[#C94D3B] text-[12px] rounded-md cursor-pointer"
          >
            <p>Objetivo de aprendizaje</p>
          </div>
          <div
            onClick={handlePage}
            id="LearningGoals"
            className=" w-[185px] py-[10px] px-[20px] text-white bg-[#C94D3B] text-[12px] rounded-md cursor-pointer"
          >
            <p>Metas de aprendizaje</p>
          </div>
          <div
            onClick={handlePage}
            id="Activities"
            className=" w-[185px] py-[10px] px-[20px] text-white bg-[#C94D3B] text-[12px] rounded-md cursor-pointer"
          >
            <p>Actividades</p>
          </div>
          <div
            onClick={handlePage}
            id="Evaluation"
            className=" w-[185px] py-[10px] px-[20px] text-white bg-[#C94D3B] text-[12px] rounded-md cursor-pointer"
          >
            <p>Evaluacion</p>
          </div>
          <div
            onClick={handlePage}
            id="Revision"
            className=" w-[185px] py-[10px] px-[20px] text-white bg-[#C94D3B] text-[12px] rounded-md cursor-pointer"
          >
            <p>Revision y archivo</p>
          </div>
        </div> */}

        {/* {page === "LearningObjectives" && (
        <CourseAPlanificationLearningObjective />
      )}
      {page === "LearningGoals" && <CourseAPlanificationLearningGoals />}
      {page === "Activities" && <CourseAPlanificationActivities />}
      {page === "Evaluation" && <CourseAPlanificationSemanalEvaluation />}
      {page === "Revision" && <CourseBasicA />} */}

        {page === 1 && (
          <CourseAPlanificationLearningObjective
            handleNext={handleNext}
            objectives={objectives}
            setObjectives={setObjectives}
            data={data}
            setData={setData}
            title={courseTitle}
            color={color}
          />
        )}
        {page === 2 && (
          <CourseAPlanificationLearningGoals
            handleNext={handleNext}
            handlePrevious={handlePrevious}
            objectives={objectives}
            data={data}
            setData={setData}
          />
        )}
        {page === 3 && (
          <CourseAPlanificationActivities
            handleNext={handleNext}
            handlePrevious={handlePrevious}
            data={data}
            setData={setData}
          />
        )}
        {page === 4 && (
          <CourseAPlanificationMaterials
            handleNext={handleNext}
            handlePrevious={handlePrevious}
            data={data}
            setData={setData}
          />
        )}
        {page === 5 && (
          <CourseAPlanificationSemanalEvaluation
            handleNext={handleNext}
            handlePrevious={handlePrevious}
            data={data}
            setData={setData}
          />
        )}
        {page === 6 && (
          <CourseBasicA
            data={data}
            page={page}
            setPage={setPage}
            handlePrevious={handlePrevious}
          />
        )}
      </div>
    </Wrapper>
  );
};
export default CourseAPlanification;
