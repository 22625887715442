import * as React from "react";
import { FaGraduationCap } from "react-icons/fa";
import CustomCard from "../../components/Dashboard_components/CustomCard";
import DatePicker from "../../components/DatePicker/DatePicker";
import Navbar from "../../components/Navbar/Navbar";
import Wrapper from "../../components/Wrapper/Wrapper";
import CustomImageCard from "../../components/Dashboard_components/CustomImageCard";
import professor from "../../components/img/professor.jpg";
import { useNavigate } from "react-router-dom";

export default function AdminDashboardCursos() {
  const navigate = useNavigate();

  const navigateToCourseProfessors = () => {
    navigate("/AdminDashboardProfessors");
  };

  const professors = [
    {
      imageUrl: professor,
      title: "Pedro Garrido",
      description: "Description text goes here.",
    },
    {
      imageUrl: professor,
      title: "Pedro Garrido",
      description: "Description text goes here.",
    },
    {
      imageUrl: professor,
      title: "Pedro Garrido",
      description: "Description text goes here.",
    },
    {
      imageUrl: professor,
      title: "Pedro Garrido",
      description: "Description text goes here.",
    },
    {
      imageUrl: professor,
      title: "Pedro Garrido",
      description: "Description text goes here.",
    },
    {
      imageUrl: professor,
      title: "Pedro Garrido",
      description: "Description text goes here.",
    },
    {
      imageUrl: professor,
      title: "Pedro Garrido",
      description: "Description text goes here.",
    },
    {
      imageUrl: professor,
      title: "Pedro Garrido",
      description: "Description text goes here.",
    },
  ];

  return (
    // <div className=" flex w-full">
    //   <Sidebar />
    //   <div className=" flex flex-col w-full">
    //     <Navbar />
    //     <div className="h-[90vh] overflow-y-hidden">
    <Wrapper>
      <div className=" flex justify-between  h-[90vh]">
        <div className=" px-[30px] py-[15px] w-full overflow-y-scroll flex flex-col gap-[30px]">
          {/* sections and grades */}
          <div>
            <h1 className=" text-xl font-bold">Courses</h1>
            <div className=" mt-[10px] flex justify-between flex-wrap">
              <div className=" flex flex-col gap-[4px] mb-[10px]">
                <CustomCard
                  color="#131432"
                  icon={<FaGraduationCap color="white" size={30} />}
                  title="6to Básico (A)"
                  text1="20 archivos en esta carpeta"
                  text2="Creado el 27 Enero 2021"
                />
                <div className=" flex flex-col gap-[3px] text-white">
                  <div className=" flex gap-2">
                    <span className=" flex-1 bg-[#131432] text-center rounded-md cursor-pointer">
                      A
                    </span>
                    <span className=" flex-1 bg-[#131432] text-center rounded-md cursor-pointer">
                      B
                    </span>
                  </div>
                  <div className=" flex gap-2">
                    <span className=" flex-1 bg-[#131432] text-center rounded-md cursor-pointer">
                      C
                    </span>
                    <span className=" flex-1 bg-[#131432] text-center rounded-md cursor-pointer">
                      D
                    </span>
                  </div>
                </div>
              </div>

              <div className=" flex flex-col gap-[4px] mb-[10px]">
                <CustomCard
                  color="#131432"
                  icon={<FaGraduationCap color="white" size={30} />}
                  title="7mo Básico"
                  text1="20 archivos en esta carpeta"
                  text2="Creado el 27 Enero 2021"
                />
                <div className=" flex flex-col gap-[3px] text-white">
                  <div className=" flex gap-2">
                    <span
                      onClick={navigateToCourseProfessors}
                      className=" flex-1 bg-[#131432] text-center rounded-md cursor-pointer"
                    >
                      A
                    </span>
                    <span className=" flex-1 bg-[#131432] text-center rounded-md cursor-pointer">
                      B
                    </span>
                  </div>
                </div>
              </div>

              <div className=" flex flex-col gap-[4px] mb-[10px]">
                <CustomCard
                  color="#131432"
                  icon={<FaGraduationCap color="white" size={30} />}
                  title="8vo Básico"
                  text1="20 archivos en esta carpeta"
                  text2="Creado el 27 Enero 2021"
                />
                <div className=" flex flex-col gap-[3px] text-white">
                  <div className=" flex gap-2">
                    <span className=" flex-1 bg-[#131432] text-center rounded-md cursor-pointer">
                      A
                    </span>
                    <span className=" flex-1 bg-[#131432] text-center rounded-md cursor-pointer">
                      B
                    </span>
                  </div>
                  <div className=" flex gap-2">
                    <span className=" flex-1 bg-[#131432] text-center rounded-md cursor-pointer">
                      C
                    </span>
                    <span className=" flex-1 bg-[#131432] text-center rounded-md cursor-pointer">
                      D
                    </span>
                  </div>
                </div>
              </div>

              <div className=" flex flex-col gap-[4px] mb-[10px]">
                <CustomCard
                  color="#131432"
                  icon={<FaGraduationCap color="white" size={30} />}
                  title="9no Básico"
                  text1="20 archivos en esta carpeta"
                  text2="Creado el 27 Enero 2021"
                />
                <div className=" flex flex-col gap-[3px] text-white">
                  <div className=" flex gap-2">
                    <span className=" flex-1 bg-[#131432] text-center rounded-md cursor-pointer">
                      A
                    </span>
                    <span className=" flex-1 bg-[#131432] text-center rounded-md cursor-pointer">
                      B
                    </span>
                  </div>
                  <div className=" flex gap-2">
                    <span className=" flex-1 bg-[#131432] text-center rounded-md cursor-pointer">
                      C
                    </span>
                    <span className=" flex-1 bg-[#131432] text-center rounded-md cursor-pointer">
                      D
                    </span>
                  </div>
                  <div className=" flex gap-2">
                    <span className=" flex-1 bg-[#131432] text-center rounded-md cursor-pointer">
                      E
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* professors */}
          <div>
            <h1 className=" text-xl font-bold mb-4">Professors:</h1>
            <div className=" mt-[10px] flex justify-center sm:justify-start gap-8 flex-wrap">
              {professors.map((prof, index) => {
                return (
                  <div
                    key={index}
                    className=""
                    onClick={() => navigate("/courseA")}
                  >
                    <CustomImageCard
                      imageUrl={prof.imageUrl}
                      title={prof.title}
                      description={prof.description}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <DatePicker />
      </div>
    </Wrapper>

    //     </div>
    //   </div>
    // </div>
  );
}
