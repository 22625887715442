import * as React from "react";
import { FaGraduationCap } from "react-icons/fa";
import DatePicker from "../../components/DatePicker/DatePicker";
import Wrapper from "../../components/Wrapper/Wrapper";
import CustomImageCard from "../../components/Dashboard_components/CustomImageCard";
import professor from "../../components/img/professor.jpg";
import { useNavigate } from "react-router-dom";

export default function AdminDashboardProfessors() {
  const navigate = useNavigate();
  const professors = [
    {
      imageUrl: professor,
      title: "Pedro Garrido",
      description: "Description text goes here.",
    },
    {
      imageUrl: professor,
      title: "Pedro Garrido",
      description: "Description text goes here.",
    },
    {
      imageUrl: professor,
      title: "Pedro Garrido",
      description: "Description text goes here.",
    },
    {
      imageUrl: professor,
      title: "Pedro Garrido",
      description: "Description text goes here.",
    },
    {
      imageUrl: professor,
      title: "Pedro Garrido",
      description: "Description text goes here.",
    },
    {
      imageUrl: professor,
      title: "Pedro Garrido",
      description: "Description text goes here.",
    },
    {
      imageUrl: professor,
      title: "Pedro Garrido",
      description: "Description text goes here.",
    },
    {
      imageUrl: professor,
      title: "Pedro Garrido",
      description: "Description text goes here.",
    },
  ];

  return (
    // <div className=" flex w-full">
    //   <Sidebar />
    //   <div className=" flex flex-col w-full">
    //     <Navbar />
    //     <div className="h-[90vh] overflow-y-hidden">
    <Wrapper>
      <div className=" flex justify-between  h-[90vh]">
        <div className=" px-[20px] sm:px-[30px] py-[15px] w-full overflow-y-scroll flex flex-col gap-[30px] items-center sm:items-start ">
          {/* sections and grades */}
          <div className=" bg-[#C94D3B] text-white w-fit flex items-center gap-[10px] px-[8px] py-[10px] border-md rounded-md">
            <FaGraduationCap className=" w-[25px] h-[25px] hidden sm:block" />
            <p className=" text-[12px] sm:text-[15px] ">Curso: 7mo bsico A</p>
          </div>

          {/* professors */}
          <div>
            <h1 className=" text-xl font-bold mb-4">Professors:</h1>

            <div className=" mt-[10px] flex gap-8 flex-wrap justify-center sm:justify-start">
              {professors.map((prof, index) => {
                return (
                  <div
                    key={index}
                    className=" cursor-pointer"
                    onClick={() => navigate("/Profile")}
                  >
                    <CustomImageCard
                      imageUrl={prof.imageUrl}
                      title={prof.title}
                      description={prof.description}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <DatePicker />
      </div>
    </Wrapper>

    //     </div>
    //   </div>
    // </div>
  );
}
