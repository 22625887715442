import { useState } from "react";
import logo from "./logo.jpg";

export default function Login() {
  const [formData, setFormData] = useState({ username: "", password: "" });

  const handleInputChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };
  const handleFormSubmit = (e) => {
    e.preventDefault();

    const opts = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(formData),
    };

    const tokenUrl = process.env.REACT_APP_API_URL+"/auth/token";

    fetch(tokenUrl, opts)
      .then((resp) => {
        if (resp.status === 200) return resp.json();
        else alert("Something went wrong. Response status: " + resp.status);
      })
      .then((data) => {
        console.log("This came from the backend:", data);
        localStorage.setItem("token", data.token);
        window.location.href = "/Dashboard";
      })
      .catch((error) => {
        console.error("THIS IS AN ERROR", error);
      });

    console.log(formData);
    setFormData({ username: "", password: "" });
  };

  return (
    <div>
      <div className=" w-full h-[40vh] bg-[#131432] border-b-4 border-[#6972FF]">
        <div className=" p-[65px] flex justify-center sm:justify-start">
          <img src={logo} alt="" className=" w-[50px]" />
        </div>
      </div>

      {/* sign in form */}
      <div className=" flex justify-center items-center p-[10px] absolute top-[167px] w-full lg:justify-end lg:pr-[120px] 2xl:top-[500px]">
        <div className=" w-[400px] h-auto p-[25px] bg-white rounded-xl md:px-10 md:py-[65px]">
          <div className=" xl:flex xl:flex-col xl:gap-[10px] 2xl:gap-[35px]">
            <div className=" flex flex-col gap-[10px]">
              <h1 className=" text-xl font-bold">Sign In</h1>
              <p className=" text-sm text-gray-400">
                Don't have an account?{" "}
                <a
                  href="/Signup"
                  className=" text-blue-600 cursor-pointer underline"
                >
                  Sign up here!
                </a>
              </p>
            </div>
            <form action="" className=" mt-4 flex flex-col gap-4 xl:gap-[30px]">
              <div>
                <label className=" text-sm" htmlFor="">
                  Email
                </label>
                <input
                  type="text"
                  name="username"
                  value={formData.username}
                  onChange={handleInputChange}
                  id="username"
                  autocomplete="given-name"
                  className="block px-2 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
              <div>
                <label className=" text-sm" htmlFor="">
                  Password
                </label>
                <input
                  type="password"
                  name="password"
                  value={formData.password}
                  onChange={handleInputChange}
                  id="password"
                  autocomplete="given-name"
                  className="block px-2 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
              <div
                className=" cursor-pointer bg-[#6972FF] text-white rounded text-center py-[4px]"
                onClick={handleFormSubmit}
              >
                <button>Sign in</button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div className=" w-full h-[60vh] bg-[#D9D9D9]"></div>
    </div>
  );
}
