import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { FaGraduationCap } from "react-icons/fa";
import CustomCard from "../../components/Dashboard_components/CustomCard";
import DatePicker from "../../components/DatePicker/DatePicker";
import { Link, useNavigate } from "react-router-dom";
import Wrapper from "../../components/Wrapper/Wrapper";
import { GoPlus } from "react-icons/go";
import { IoMdClose } from "react-icons/io";
import AddCourse from "../../components/Dashboard_components/AddCourse";

const AddCourseCard = ({ icon, color, title, text1, text2, subject }) => {
  return (
    <Box>
      <Card
        variant="outlined"
        sx={{
          backgroundColor: color,
          width: "200px",
          height: "165px",
          borderRadius: "10px",
          color: "white",
          border: "1px solid #ccc",
        }}
      >
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "5px",
            height: "100%",
          }}
        >
          <Typography sx={{ fontSize: 14, marginTop: "auto" }} component="div">
            <div className=" flex-1 flex  gap-[10px] py-[10px] px-[15px] items-center">
              <span className=" flex justify-center p-[5px] items-center w-[35px] h-[35px] bg-[#131432] rounded-[25px]">
                {icon}
              </span>
              <p className=" text-[#818181]">{title}</p>
            </div>
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
};

export default function Dashboard() {
  const navigate = useNavigate();
  const [openAddCourseModal, setOpenAddCourseModal] = React.useState(false);
  const [courseColor, setCourseColor] = React.useState("");
  const [newCourse, setNewCourse] = React.useState({
    link: "/CourseA",
    color: "",
    icon: <FaGraduationCap color="white" size={30} />,
    title: "",
    subject: "",
    comment: "",
    date: "",
  });
  const [courses, setCourses] = React.useState([
    {
      link: "/CourseA",
      color: "#C94D3B",
      icon: <FaGraduationCap color="white" size={30} />,
      title: "6to Básico (A)",
      subject: "Mathematics",
      comment: "20 archivos en esta carpeta",
      date: "Created on January 27, 2021",
    },
    {
      link: "/CourseB",
      color: "#6972FF",
      icon: <FaGraduationCap color="white" size={30} />,
      title: "6to Básico (B)",
      subject: "English",
      comment: "20 archivos en esta carpeta",
      date: "Creado el 27 Enero 2021",
    },
    {
      link: "/CourseC",
      color: "#F7B847",
      icon: <FaGraduationCap color="white" size={30} />,
      title: "6to Básico (C)",
      subject: "Science",
      comment: "20 archivos en esta carpeta",
      date: "Creado el 27 Enero 2021",
    },
  ]);

  const getDate = () => {
    // Get the current date
    var currentDate = new Date();

    // Define months array
    var months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    // Get the month, day, and year
    var month = months[currentDate.getMonth()];
    var day = currentDate.getDate();
    var year = currentDate.getFullYear();

    // Format the date string
    var formattedDate = month + " " + day + ", " + year;

    console.log("Current Date:", formattedDate);
    return formattedDate;
  };

  const handleInputChange = (e) => {
    setNewCourse((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleAddCourse = () => {
    if (
      !newCourse.title ||
      !newCourse.subject ||
      !newCourse.comment ||
      !courseColor
    ) {
      console.log("Kindly enter all detail.");
      return;
    }
    newCourse.color = courseColor;
    newCourse.date = `Created on ${getDate()}`;
    console.log("New Course : ", newCourse);
    setCourses((prevState) => [...prevState, { ...newCourse }]);
    setNewCourse({
      link: "/CourseA",
      color: "",
      icon: <FaGraduationCap color="white" size={30} />,
      title: "",
      subject: "",
      comment: "",
      date: "",
    });
    setOpenAddCourseModal(false);
  };

  const navigateToCourse = (course) => {
    navigate("/CourseA", {
      state: { courseTitle: course.title, color: course.color },
    });
  };

  return (
    <Wrapper>
      {/* <div className=" flex justify-between h-[90vh]"> */}
      <div className=" flex justify-between">
        <div className=" px-[30px] py-[20px] w-full overflow-y-scroll h-[92vh]">
          <h1 className=" text-xl font-bold text-center md:text-start">
            Courses
          </h1>
          <div className=" mt-[10px] flex justify-center flex-wrap gap-[3rem] md:justify-start">
            {courses.map((course, index) => {
              return (
                <div
                  className=" cursor-pointer"
                  onClick={() => navigateToCourse(course)}
                >
                  <CustomCard
                    color={course.color}
                    icon={course.icon}
                    title={course.title}
                    subject={course.subject}
                    text1={course.comment}
                    text2={course.date}
                  />
                </div>
              );
            })}
            {/* Add New Course */}
            {/* <div> */}
            <AddCourseCard
              icon={
                <GoPlus
                  onClick={() => setOpenAddCourseModal(true)}
                  className=" w-[30px] h-[30px] cursor-pointer"
                  color="white"
                />
              }
              title="Agregar Nuevo Curso"
            />
            {openAddCourseModal && (
              <div className=" w-full h-full inset-0 absolute flex justify-center items-center ">
                <div className="w-[300px] sm:w-[500px] h-max px-[5px] py-[15px] lg:p-0 lg:h-[600px] rounded-md bg-white flex lg:items-center justify-center z-50 overflow-auto">
                  {/* {courseModal()} */}
                  {
                    <AddCourse
                      setOpenAddCourseModal={setOpenAddCourseModal}
                      newCourse={newCourse}
                      handleInputChange={handleInputChange}
                      setCourseColor={setCourseColor}
                      handleAddCourse={handleAddCourse}
                    />
                  }
                </div>
              </div>
            )}

            {openAddCourseModal && (
              <div class="fixed inset-0 bg-black opacity-50"></div>
            )}
            {/* </div> */}
          </div>
        </div>
        <DatePicker />
      </div>
    </Wrapper>
  );
}
