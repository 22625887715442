import CustomButton from "../../components/CustomButton/CustomButton";
import { TextField } from "@mui/material";
import { IoMdArrowBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { FaCircleCheck } from "react-icons/fa6";

export default function SuccessComponent() {
  const navigate = useNavigate();

  return (
    <div className=" flex flex-col gap-[30px] w-max sm:w-[327px]">
      <div className=" flex items-center justify-center">
        <span className=" w-[40px] h-[40px] rounded-lg bg-white flex items-center justify-center shadow-md border border-[#cccc]">
          <FaCircleCheck
            color="green"
            style={{ width: "20px", height: "20px" }}
          />
        </span>
      </div>
      <div className=" flex flex-col gap-2 text-center">
        <p className=" text-2xl">Password Reset Successfully</p>
      </div>
      <div className=" flex gap-2 items-center justify-center text-gray-500">
        <IoMdArrowBack
          onClick={() => navigate("/Login")}
          style={{ width: "20px", height: "20px", cursor: "pointer" }}
        />
        <span onClick={() => navigate("/Login")} style={{ cursor: "pointer" }}>
          Back to log in
        </span>
      </div>
    </div>
  );
}
