import BasicTable from "../../components/BasicTable/BasicTable";
import CustomButton from "../../components/CustomButton/CustomButton";
import Wrapper from "../../components/Wrapper/Wrapper";
import MastercardIcon from "../../SvgIcons/MastercardIcon";
import VisaIcon from "../../SvgIcons/VisaIcon";
import { useState } from "react";
import PaymentMethodsModal from "./PaymentMethodsModal";
import PaymentMethodsTable from "./PaymentMethodsTable";
import PaypalIcon from "../../SvgIcons/PaypalIcon";
import AddCardModal from "./AddCardModal";

const tableHeading = ["Descripción", "Tarjeta", "Precio", "Fecha"];
const tableData = [
  {
    Descripción: "Comprar de plan Silver",
    Tarjeta: { icon: <MastercardIcon />, text: "Mastercard ***8980" },
    Precio: "49.99$",
    Fecha: "31/01/2024",
  },
  {
    Descripción: "Comprar de plan Silver",
    Tarjeta: { icon: <MastercardIcon />, text: "Mastercard ***8980" },
    Precio: "49.99$",
    Fecha: "31/01/2024",
  },
  {
    Descripción: "Comprar de plan Silver",
    Tarjeta: { icon: <MastercardIcon />, text: "Mastercard ***8980" },
    Precio: "49.99$",
    Fecha: "31/01/2024",
  },
  {
    Descripción: "Comprar de plan Silver",
    Tarjeta: { icon: <MastercardIcon />, text: "Mastercard ***8980" },
    Precio: "49.99$",
    Fecha: "31/01/2024",
  },
];

const paymentsTableHeading = [
  "Tarjeta",
  "Titular",
  "Vencimiento",
  "Acción",
  "Seleccionado",
];
const paymentsTableData = [
  {
    fname: "Alejandro",
    lname: "Olavarrieta",
    icon: <MastercardIcon />,
    cardType: "Mastercard",
    cardNumber: 12345678980,
    dueDate: "01/28",
    securityCode: "999",
    selected: false,
  },
  {
    icon: <VisaIcon />,
    cardType: "Visa",
    cardNumber: 12345678980,
    fname: "Alejandro",
    lname: "Olavarrieta",
    dueDate: "01/28",
    securityCode: "999",
    selected: true,
  },
  {
    icon: <PaypalIcon />,
    cardType: "Paypal",
    cardNumber: 12345678980,
    fname: "Alejandro",
    lname: "Olavarrieta",
    dueDate: "01/28",
    securityCode: "999",
    selected: false,
  },
];

export default function Billing() {
  const [paymentsModal, setPaymentsModal] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState([...paymentsTableData]);
  const [openCardModal, setOpenCardModal] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState({
    icon: <VisaIcon />,
    cardType: "Visa",
    cardNumber: 12345678980,
    fname: "Alejandro",
    lname: "Olavarrieta",
    dueDate: "01/28",
    securityCode: "999",
    selected: true,
  });
  const [editValue, setEditValue] = useState("");
  const [editIndex, setEditIndex] = useState("");

  console.log("Edit Value : ", editValue);

  const handleCardModal = () => {
    setOpenCardModal(!openCardModal);
    handlePaymentsModal();
  };

  const addPaymentMethod = (method) => {
    setPaymentMethods((prev) => [...prev, method]);
  };
  const handlePaymentsModal = () => setPaymentsModal(!paymentsModal);

  const deletePaymentMethod = (index) => {
    console.log("deleting payment : ", index);
    if (paymentMethods[index].selected) {
      setSelectedPaymentMethod({
        icon: "",
        cardType: "",
        cardNumber: "",
        fname: "",
        lname: "",
        dueDate: "",
        securityCode: "",
        selected: false,
      });
    }
    setPaymentMethods((prev) => prev.filter((_, i) => i !== index));
  };

  const editPaymentMethod = (index) => {
    setEditValue(paymentMethods[index]);
    setEditIndex(index);
    handleCardModal();
  };

  const saveEditValue = (method) => {
    if (method.selected) {
      setSelectedPaymentMethod(method);
    }
    const copyMethods = [...paymentMethods];
    copyMethods[editIndex] = method;
    setPaymentMethods([...copyMethods]);
    setEditValue("");
    setEditIndex("");
  };

  const selectPaymentMethod = (index) => {
    setSelectedPaymentMethod(paymentMethods[index]);
    setPaymentMethods((prev) =>
      prev.map((method, i) =>
        i === index
          ? { ...method, selected: true }
          : { ...method, selected: false }
      )
    );
  };

  return (
    <Wrapper>
      {/* billing container */}
      <div className=" flex flex-col gap-[15px] px-[20px] lg:px-[40px] py-[20px] h-[90vh] overflow-scroll ">
        {/* heading */}
        <div className=" flex flex-col">
          <h1 className=" text-[18px] sm:text-[30px] font-bold">
            Facturación:
          </h1>
          <p className=" text-[12px] sm:text-[15px]">
            Accede a información de tu plan y pagos pasados.
          </p>
        </div>
        {/* cards */}
        <div className=" flex gap-[20px] flex-col sm:flex-row">
          {/* left card */}
          <div className=" h-max flex flex-col flex-1 gap-[15px] bg-[#F0F0FF] py-[20px] px-[20px] shadow-md border border-gainsboro rounded-2xl">
            <div>
              <h1 className=" text-[15px] sm:text-[20px] font-medium">
                Métodos de pago
              </h1>
              <p className=" text-[#61616E] text-[12px] sm:text-[15px]">
                Agrega o actualiza tus métodos de pago
              </p>
            </div>
            <div>
              <p className=" text-[#61616E] text-[12px]">
                El cobro del servicio se realiza automáticamente utilizando el
                método de pago seleccionado.
              </p>
            </div>
            <div className=" flex flex-col gap-[15px]">
              <div className=" flex justify-between border-b border-[#9F9FAB] pb-[10px] font-bold text-[10px] sm:text-[15px]">
                <span>Tarjeta</span>
                <span>Titular</span>
                <span>Vencimiento</span>
              </div>
              <div className=" flex justify-between items-center border-b border-[#9F9FAB] pb-[10px] text-[12px]">
                <span className=" flex items-center flex-1 gap-[5px]">
                  <span className="hidden sm:block">
                    {selectedPaymentMethod.icon}
                  </span>

                  <span>
                    <span>{selectedPaymentMethod.cardType}</span>
                    <span className=" hidden sm:block">
                      ***
                      {String(selectedPaymentMethod.cardNumber).slice(-4)}
                    </span>
                  </span>
                </span>
                <span className=" flex-1 text-center">
                  {selectedPaymentMethod.fname} {selectedPaymentMethod.lname}
                </span>
                <span className=" flex-1 text-center">
                  {selectedPaymentMethod.dueDate}
                </span>
              </div>
              <div>
                <CustomButton
                  text={"Cambiar método de pago"}
                  onClick={handlePaymentsModal}
                />
                <PaymentMethodsModal
                  open={paymentsModal}
                  handleClose={handlePaymentsModal}
                  handleCardModal={handleCardModal}
                  tableHeading={paymentsTableHeading}
                  tableData={paymentMethods}
                  deletePaymentMethod={deletePaymentMethod}
                  editPaymentMethod={editPaymentMethod}
                  selectPaymentMethod={selectPaymentMethod}
                />
                <AddCardModal
                  open={openCardModal}
                  handleClose={handleCardModal}
                  handleAddPaymentMethod={addPaymentMethod}
                  editMethod={editValue}
                  saveEditValue={saveEditValue}
                />
              </div>
            </div>
          </div>
          {/* right card */}
          <div className=" h-max flex flex-col flex-1 gap-[15px] bg-[#F0F0FF] py-[20px] px-[20px] shadow-md border border-[#dcdcdc] rounded-2xl">
            <div>
              <h1 className=" text-[15px] sm:text-[20px] font-medium">
                Detalles del plan
              </h1>
              <p className=" text-[#61616E] text-[12px] sm:text-[15px]">
                Silver Plan
              </p>
            </div>
            <div>
              <h3 className=" text-[15px] sm:text-[18px]">
                Usuarios asociados: 50
              </h3>
              <p className=" text-[#61616E] text-[12px] sm:text-[15px] mb-1">
                Restan 10 usuarios hasta que sea necesario actualizar el plan.
              </p>
              <CustomButton text={"Agregar usuarios"} />
            </div>
            <div>
              <h3 className=" text-[15px] sm:text-[18px]">
                Plan activo desde Dec 31, 2023
              </h3>
              <p className=" text-[#61616E] text-[12px] sm:text-[15px]">
                Te avisaremos cuando se acerque la fecha de vencimiento
              </p>
            </div>
            <div>
              <h3 className=" text-[15px] sm:text-[18px]">$49.99 por mes</h3>
              <p className=" text-[#61616E] text-[12px] sm:text-[15px]">
                Tienes acceso al plan Silver. Máximo de 60 usuarios asociados,
                agregar más beneficios relacionados al plan en forma breve y que
                sean de importancia para el usuario
              </p>
            </div>
          </div>
        </div>
        {/* table */}
        <div className="flex flex-col gap-[10px]">
          <div className=" flex justify-between items-center">
            <div>
              <h1 className=" text-[12px] sm:text-[20px] font-medium">
                Historial de pagos
              </h1>
              <p className=" hidden sm:block text-[#61616E] text-[10px] sm:text-[12px]">
                Accede a tus compras pasadas
              </p>
            </div>
            <div className=" border border-black rounded-md text-[12px] p-[5px] sm:p-[10px] text-center">
              {/* <CustomButton
                variant="secondary"
                text={"Descargar reporte"}
                border={"1px solid black"}
                borderRadius={"10px"}
                fontSize={"12px"}
              /> */}
              Descargar reporte
            </div>
          </div>
          <BasicTable tableHeader={tableHeading} tableData={tableData} />
        </div>
      </div>
    </Wrapper>
  );
}
