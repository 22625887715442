import Modal from "@mui/material/Modal";
import { useEffect, useState } from "react";
import CustomButton from "../../components/CustomButton/CustomButton";
import { IoMdClose } from "react-icons/io";
import TextField from "@mui/material/TextField";
import MastercardIcon from "../../SvgIcons/MastercardIcon";

const modalStyles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "#fff",
  border: "1px solid #ccc",
  boxShadow: 24,
  p: 4,
};

const formFields = ["fname", "lname", "cardNumber", "dueDate", "securityCode"];

export default function AddCardModal({
  open,
  handleClose,
  handleAddPaymentMethod,
  editMethod,
  saveEditValue,
}) {
  console.log("Edit Method : ", editMethod);

  const initialDetails = {
    fname: "Benjamin",
    lname: "",
    cardNumber: "**** **** **** 6875",
    dueDate: "",
    securityCode: "",
    cardType: "Mastercard",
    icon: <MastercardIcon />,
  };
  console.log("initial Method : ", initialDetails);

  const [cardDetails, setCardDetails] = useState(editMethod || initialDetails);

  console.log("card details : ", cardDetails);

  const handleInputChange = (e) => {
    setCardDetails((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleFormSubmit = () => {
    if (editMethod) {
      saveEditValue(cardDetails);
    } else {
      handleAddPaymentMethod(cardDetails);
    }
    handleClose();
  };

  useEffect(() => {
    if (editMethod) {
      setCardDetails(editMethod);
    } else {
      setCardDetails(initialDetails);
    }
  }, [editMethod]);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div
          style={modalStyles}
          className=" bg-[#F0F0FF] flex flex-col gap-[10px] w-[800px] p-[30px] rounded-2xl"
        >
          <div className=" flex justify-between items-center">
            <div>
              <h1 className=" text-[20px] font-medium">Historial de pagos</h1>
              <p className=" text-[#61616E] text-[12px]">
                Accede a tus compras pasadas
              </p>
            </div>
            <div className=" flex items-center justify-center w-[35px] h-[35px] rounded-full hover:bg-[#dcdcdc]">
              <IoMdClose
                style={{ width: "25px", height: "25px", cursor: "pointer" }}
                onClick={handleClose}
              />
            </div>
          </div>
          {/* form */}
          <div className=" flex flex-col gap-[10px]">
            <div className=" flex gap-[5px]">
              <div className=" flex-1">
                <TextField
                  id="filled-basic"
                  label="Nombre"
                  name="fname"
                  value={cardDetails.fname}
                  onChange={handleInputChange}
                  variant="filled"
                  required
                  sx={{
                    width: "100%",
                    "& .MuiFilledInput-root": {
                      borderRadius: "10px",
                      background: "white",
                    },
                    "& .MuiFilledInput-root::before": {
                      borderRadius: "10px",
                    },
                    "& .MuiFilledInput-root::after": {
                      border: "none",
                    },
                  }}
                />
              </div>
              <div className=" flex-1">
                <TextField
                  id="filled-basic"
                  label="Apellido"
                  name="lname"
                  value={cardDetails.lname}
                  onChange={handleInputChange}
                  required
                  variant="filled"
                  sx={{
                    width: "100%",
                    "& .MuiFilledInput-root": {
                      borderRadius: "10px",
                      background: "white",
                    },
                    "& .MuiFilledInput-root::before": {
                      borderRadius: "10px",
                    },
                    "& .MuiFilledInput-root::after": {
                      border: "none",
                    },
                  }}
                />
              </div>
            </div>
            <div>
              <TextField
                id="filled-basic"
                label="Número de la tarjeta"
                name="cardNumber"
                value={cardDetails.cardNumber}
                onChange={handleInputChange}
                required
                variant="filled"
                sx={{
                  width: "100%",
                  "& .MuiFilledInput-root": {
                    borderRadius: "10px",
                    background: "white",
                  },
                  "& .MuiFilledInput-root::before": {
                    borderRadius: "10px",
                  },
                  "& .MuiFilledInput-root::after": {
                    border: "none",
                  },
                }}
              />
            </div>
            <div className=" flex gap-[5px]">
              <div className=" flex-1">
                <TextField
                  id="filled-basic"
                  label="Fecha de vencimiento"
                  name="dueDate"
                  value={cardDetails.dueDate}
                  onChange={handleInputChange}
                  required
                  variant="filled"
                  sx={{
                    width: "100%",
                    "& .MuiFilledInput-root": {
                      borderRadius: "10px",
                      background: "white",
                    },
                    "& .MuiFilledInput-root::before": {
                      borderRadius: "10px",
                    },
                    "& .MuiFilledInput-root::after": {
                      border: "none",
                    },
                  }}
                />
              </div>
              <div className=" flex-1">
                <TextField
                  id="filled-basic"
                  label="Código de Seguridad"
                  name="securityCode"
                  value={cardDetails.securityCode}
                  onChange={handleInputChange}
                  required
                  variant="filled"
                  sx={{
                    width: "100%",
                    "& .MuiFilledInput-root": {
                      borderRadius: "10px",
                      background: "white",
                    },
                    "& .MuiFilledInput-root::before": {
                      borderRadius: "10px",
                    },
                    "& .MuiFilledInput-root::after": {
                      border: "none",
                    },
                  }}
                />
              </div>
            </div>
          </div>
          <div className=" mt-[10px]">
            <CustomButton
              text={"Agregar método de pago"}
              padding={"5px 12px"}
              borderRadius={"10px"}
              onClick={handleFormSubmit}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
}
