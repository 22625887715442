import { useRef, useState } from "react";
import EmailComponent from "./EmailComponent";
import CodeComponent from "./CodeComponent";
import PasswordComponent from "./PasswordComponent";
import SuccessComponent from "./SuccessComponent";

export default function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [step, setStep] = useState(1);
  const inputRefs = useRef([]);

  const [passwordFields, setPasswordFields] = useState({
    password: "",
    confirmPassword: "",
  });

  const [code, setCode] = useState({
    digit1: "",
    digit2: "",
    digit3: "",
    digit4: "",
    digit5: "",
    digit6: "",
  });

  const [errors, setErrors] = useState({
    email: "",
    code: "",
    password: "",
    confirmPassword: "",
  });

  const handleSetErrors = (key, message) => {
    setErrors((prev) => ({
      ...prev,
      [key]: message,
    }));
  };

  const handleEmailSubmit = () => {
    if (!email) {
      console.log("email not entered");
      handleSetErrors("email", "Kindly enter the email");
      return;
    }

    const re =
      /^[-a-z0-9~!$%^&*_=+}{\'?]+(\.[-a-z0-9~!$%^&*_=+}{\'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|[a-z][a-z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/i;
    if (!re.test(email)) {
      console.log("email not valid");
      handleSetErrors("email", "Kindly enter valid email.");
      return;
    }
    setStep(2);
  };

  const handleCodeChange = (key, value, index) => {
    setCode((prev) => ({
      ...prev,
      [key]: value,
    }));

    if (value && index < 5) {
      inputRefs.current[index + 1].focus();
    }
    if (!value && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };

  const handlePasswordChange = (e) => {
    setPasswordFields((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleCodeSubmit = () => {
    const verificationCode = Object.values(code).join("");
    console.log("code : ", verificationCode);
    if (!verificationCode || verificationCode.length < 6) {
      handleSetErrors("code", "Please enter valid verification code.");
      return;
    }
    setStep(3);
  };

  const handlePasswordSubmit = () => {
    if (!passwordFields.password) {
      handleSetErrors("password", "Please enter new password");
      return;
    } else {
      handleSetErrors("password", "");
    }
    if (!passwordFields.confirmPassword) {
      handleSetErrors("confirmPassword", "Please enter confirm password");
      return;
    } else {
      handleSetErrors("confirmPassword", "");
    }
    if (passwordFields.password !== passwordFields.confirmPassword) {
      handleSetErrors(
        "confirmPassword",
        "New password and confirm password are not same."
      );
      return;
    } else {
      handleSetErrors("confirmPassword", "");
    }
    if (passwordFields.password.length < 8) {
      handleSetErrors(
        "confirmPassword",
        "Password must be greater than 8 characters."
      );
      return;
    }
    setStep(4);
  };

  return (
    <div className=" flex flex-col items-center justify-center h-screen relative">
      {step === 1 && (
        <EmailComponent
          email={email}
          setEmail={setEmail}
          onClick={handleEmailSubmit}
          errors={errors}
        />
      )}
      {step === 2 && (
        <CodeComponent
          email={email}
          setEmail={setEmail}
          code={code}
          handleCodeChange={handleCodeChange}
          inputRefs={inputRefs}
          onClick={handleCodeSubmit}
          errors={errors}
        />
      )}

      {step === 3 && (
        <PasswordComponent
          passwordFields={passwordFields}
          errors={errors}
          handleInputChange={handlePasswordChange}
          onClick={handlePasswordSubmit}
        />
      )}

      {step === 4 && <SuccessComponent />}

      <div className=" flex items-center gap-2 w-max sm:w-[327px] absolute bottom-0 mb-[20px]">
        <div
          style={{ backgroundColor: step === 1 && "#2e2ef5" }}
          className=" w-[60px] h-[6px] rounded-md bg-[#dcdcdc] flex-1"
        ></div>
        <div
          style={{ backgroundColor: step === 2 && "#2e2ef5" }}
          className=" w-[60px] h-[6px] rounded-md bg-[#dcdcdc] flex-1"
        ></div>
        <div
          style={{ backgroundColor: step === 3 && "#2e2ef5" }}
          className=" w-[60px] h-[6px] rounded-md bg-[#dcdcdc] flex-1"
        ></div>
        <div
          style={{ backgroundColor: step === 4 && "#2e2ef5" }}
          className=" w-[60px] h-[6px] rounded-md bg-[#dcdcdc] flex-1"
        ></div>
      </div>
    </div>
  );
}
