import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import CourseB from "../components/Course_components/CourseB";
import CourseC from "../components/Course_components/CourseC";
import Login from "../pages/Login/Login";
import Signup from "../pages/Signup/Signup";
import AdminDashboardCursos from "../pages/AdminDashboard/AdminDashboardCursos";
import AdminDashboardProfessors from "../pages/AdminDashboard/AdminDashboardProfessors";
import AddCourse from "../components/Course_components/AddCourse";
import CourseAPlanification from "../components/Planification_components/CourseAPlanification/CourseAPlanification";
import withAuthProtection from "../components/AuthRoutes/withAuthProtection";
import CourseA from "../components/Course_components/CourseA";
import Dashboard from "../pages/Dashboard/Dashboard";
import Planification from "../pages/Planification/Planification";
import ProfessorProfile from "../components/Course_components/ProfessorProfile";
import Billing from "../pages/Billing/Billing";
import ForgotPassword from "../pages/ForgotPassword/ForgotPassword";
import EditProfile from "../pages/EditProfile/EditProfile";

export default function AppRoutes() {
  const ProtectedLogin = withAuthProtection(Login, "/Login");
  const ProtectedSignup = withAuthProtection(Signup, "/Signup");

  const ProtectedDashboard = withAuthProtection(Dashboard, "/Dashboard");
  const ProtectedPlanification = withAuthProtection(Planification, "/Planning");
  const ProtectedCourseA = withAuthProtection(CourseA, "/Courses");
  const ProtectedCourseAPlanification = withAuthProtection(
    CourseAPlanification,
    "/CourseAPlanning"
  );
  const ProtectedCourseB = withAuthProtection(CourseB, "/CourseB");
  const ProtectedCourseC = withAuthProtection(CourseC, "/CourseC");
  const ProtectedAdminDashboardCursos = withAuthProtection(
    AdminDashboardCursos,
    "/AdminDashboardCursos"
  );
  const ProtectedAdminDashboardProfessors = withAuthProtection(
    AdminDashboardProfessors,
    "/AdminDashboardProfessors"
  );
  const ProtectedAddCourse = withAuthProtection(AddCourse, "/AddCourse");
  const ProtectedProfessorProfile = withAuthProtection(
    ProfessorProfile,
    "/Profile"
  );
  const ProtectedBilling = withAuthProtection(Billing, "/Billing");
  const ProtectedEditProfile = withAuthProtection(EditProfile, "/EditProfile");
  const ProtectedForgotPassword = withAuthProtection(
    ForgotPassword,
    "/ForgotPassword"
  );

  return (
    <Router>
      <Routes>
        <Route path="/Signup" element={<Signup />} />
        <Route path="/Login" element={<ProtectedLogin />} />
        <Route path="/" element={<ProtectedDashboard />} />
        <Route path="/Dashboard" element={<ProtectedDashboard />} />
        <Route path="/Settings" element={<ProtectedDashboard />} />
        <Route path="/Planning" element={<ProtectedPlanification />} />
        <Route path="/Courses" element={<ProtectedCourseA />} />
        <Route path="/CourseA" element={<ProtectedCourseA />} />
        <Route
          path="/CourseAPlanning"
          element={<ProtectedCourseAPlanification />}
        />
        <Route path="/CourseB" element={<ProtectedCourseB />} />
        <Route path="/CourseC" element={<ProtectedCourseC />} />
        <Route
          path="/AdminDashboardCursos"
          element={<ProtectedAdminDashboardCursos />}
        />
        <Route
          path="/AdminDashboardProfessors"
          element={<ProtectedAdminDashboardProfessors />}
        />
        <Route path="/AddCourse" element={<ProtectedAddCourse />} />
        <Route path="/Profile" element={<ProtectedProfessorProfile />} />
        <Route path="/Billing" element={<Billing />} />
        <Route path="/ForgotPassword" element={<ForgotPassword />} />
        <Route path="/EditProfile" element={<EditProfile />} />
      </Routes>
    </Router>
  );
}
