import { FaGraduationCap } from "react-icons/fa";
import { GoPlus } from "react-icons/go";
import { FaRegEdit } from "react-icons/fa";
import DatePicker from "../DatePicker/DatePicker";
import Wrapper from "../Wrapper/Wrapper";
import { useLocation, useNavigate } from "react-router-dom";

const CourseA = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { courseTitle, color } = location.state || {};
  console.log("Data : ", courseTitle, color);
  return (
    <Wrapper>
      <div className=" flex justify-between">
        <div className=" w-full p-[20px] sm:p-[30px] h-[92vh] overflow-scroll">
          <div
            style={{ background: color }}
            className={`bg-[${color}] text-white w-max flex items-center gap-[10px] px-[8px] py-[15px] border-md rounded-md`}
          >
            <FaGraduationCap className=" w-[25px] h-[25px]" />
            <p>{courseTitle}</p>
          </div>

          <div className=" mt-[15px]">
            <p className=" mb-4 text-[#131531] text-[15px]">
              Crear Nueva Planificación
            </p>
            <div className=" flex gap-4">
              <div className=" flex-1 bg-[#D0DFF2] flex flex-col gap-[5px] py-[10px] px-[15px] rounded-md border border-[gainsboro]">
                <span className=" flex justify-center items-center w-[30px] h-[30px] md:w-[35px] md:h-[35px] bg-[#131432] rounded-[25px]">
                  <GoPlus
                    className=" w-[25px] h-[25px] md:w-[30px] md:h-[30px] cursor-pointer"
                    color="white"
                    onClick={() =>
                      navigate("/CourseAPlanning", {
                        state: { courseTitle, color },
                      })
                    }
                  />
                </span>
                <p className=" text-[12px]  md:text-[15px]">
                  Crear nueva planificación semanal
                </p>
              </div>
              <div className=" flex-1 bg-[#D0DFF2] flex flex-col gap-[5px] py-[10px] px-[15px] rounded-md border border-[gainsboro]"></div>
            </div>
          </div>

          <div className=" mt-10">
            <p className=" mb-2">Planificaciones Realizadas</p>
            <div className=" flex gap-4">
              <div className="bg-[#D0DFF2] w-[180px] sm:w-[200px] flex items-center gap-[10px] py-[10px] px-[15px] rounded-md border border-[gainsboro]">
                <span className=" flex justify-center items-center w-[80px] h-[40px] bg-white rounded-sm"></span>
                <div className=" flex items-center gap-[10px]">
                  <p className=" text-[10px]">
                    Planificación 1 Semanal Creada el 02/01/2024
                  </p>
                  <FaRegEdit className=" w-[30px] h-[25px] text-[#8d8d8d] mt-auto" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <DatePicker />
      </div>
    </Wrapper>
  );
};
export default CourseA;
