import { FaGraduationCap } from "react-icons/fa";
import { RiQuestionAnswerFill } from "react-icons/ri";
import { GoPlus } from "react-icons/go";
import { IoIosClose } from "react-icons/io";
import { FaRegEdit } from "react-icons/fa";
import { GrLinkNext, GrLinkPrevious } from "react-icons/gr";
import { useEffect, useState } from "react";
import { TiTick } from "react-icons/ti";
import axios from "axios";
import GetActivity from "./components/GetActivity";
import CustomSnackbar from "../../CustomSnackbar/CustomSnackbar";

const tempActivities = {
  0: {
    actividad_1: "Cargando...",
    actividad_2: "Cargando...",
    actividad_3: "Cargando...",
  },
  1: {
    actividad_1: "Cargando...",
    actividad_2: "Cargando...",
    actividad_3: "Cargando...",
  },
  2: {
    actividad_1: "Cargando...",
    actividad_2: "Cargando...",
    actividad_3: "Cargando...",
  },
  3: {
    actividad_1: "Cargando...",
    actividad_2: "Cargando...",
    actividad_3: "Cargando...",
  },
  4: {
    actividad_1: "Cargando...",
    actividad_2: "Cargando...",
    actividad_3: "Cargando...",
  },
  5: {
    actividad_1: "Cargando...",
    actividad_2: "Cargando...",
    actividad_3: "Cargando...",
  },
  6: {
    actividad_1: "Cargando...",
    actividad_2: "Cargando...",
    actividad_3: "Cargando...",
  },
  7: {
    actividad_1: "Cargando...",
    actividad_2: "Cargando...",
    actividad_3: "Cargando...",
  },
  8: {
    actividad_1: "Cargando...",
    actividad_2: "Cargando...",
    actividad_3: "Cargando...",
  },
  9: {
    actividad_1: "Cargando...",
    actividad_2: "Cargando...",
    actividad_3: "Cargando...",
  },
  10: {
    actividad_1: "Cargando...",
    actividad_2: "Cargando...",
    actividad_3: "Cargando...",
  },
  11: {
    actividad_1: "Cargando...",
    actividad_2: "Cargando...",
    actividad_3: "Cargando...",
  },
  12: {
    actividad_1: "Cargando...",
    actividad_2: "Cargando...",
    actividad_3: "Cargando...",
  },
};

const CourseAPlanificationActivities = ({
  handleNext,
  handlePrevious,
  data,
  setData,
}) => {
  // const [data, setData] = useState({ ...makeObject(objectives.length) });
  const [addedActivityGoal, setAddedActivityGoal] = useState("");
  const [activityOptions, setActivityOptions] = useState(tempActivities);
  const [activityInput, setActivityInput] = useState("");
  const [open, setOpen] = useState({ open: false, message: "" });
  console.log("data : ", data);

  const goals = Object.values(data.goals).reduce((acc, goal, index) => {
    acc[`goal_${index}`] = goal.goal;
    return acc;
  }, {});
  console.log("Goals : ", goals);

  useEffect(() => {
    const activitiesUrl = process.env.REACT_APP_API_URL+'/activities';

    const fetchActivities = async () => {
      try {
        const response = await axios.post(activitiesUrl, {
          weeks: data.weeks,
          comments: data.comment,
          objectives: data.objectives,
          goals: goals,
        });
        const updatedData = response.data.actividades.map((activityObj) => {
          return {
            ...activityObj,
            selected_activity: "",
          };
        });
        setActivityOptions(updatedData);
      } catch (error) {
        console.error("Failed to fetch activities:", error);
      }
    };

    fetchActivities();
  }, []);

  const handleActivityInputClose = () => {
    setAddedActivityGoal("");
    setActivityInput("");
  };

  const handleNextPage = () => {
    console.log("handle next page");
    console.log("Data : ", data);

    let index = 0;
    for (const goal of Object.values(activityOptions)) {
      if (index >= +data.weeks) break;

      console.log("GOAL: ", goal);
      console.log("selected_activity" in goal);
      if (goal.selected_activity === "") {
        setOpen({
          open: true,
          message: "U need to select at least one activity from each meta.",
        });
        return; // This will exit the handleNextPage function entirely
      }
      index++;
    }

    const newData = {
      ...data,
      goals: Object.values(data.goals).map((goal, index) => ({
        goal: goal.goal,
        element: index,
        activity: activityOptions[index].selected_activity.activity,
      })),
    };

    console.log("Updated Data : ", newData);
    setData(newData);

    handleNext();
  };

  // const getActivity = (activities, goal_no, goal, selected_activity = "") => {
  //   return (
  //     <div className=" flex flex-col gap-4">
  //       <div className=" flex-1 bg-[#D0DFF2] flex gap-[5px] py-[10px] px-[15px] rounded-md border border-[gainsboro]">
  //         <p className=" text-[12px]">
  //           Depending on your chosen goals, I suggest the following activities
  //           for goal {goal_no + 1}. If you want to add an additional activity,
  //           just write it.
  //         </p>
  //       </div>

  //       {/* activity */}
  //       <div className=" flex gap-4">
  //         <div className="bg-[#D0DFF2] flex flex-col flex-1 justify-center items-center gap-[5px] py-[5px] px-[10px] rounded-md border border-[gainsboro]">
  //           <span className=" text-[15px] flex flex-col justify-center items-center pb-[10px] border-b border-b-gray-500 w-full text-center">
  //             <span>Meta</span>
  //             <span>#{goal_no + 1}</span>
  //           </span>
  //           <p className=" text-[8px] pt-[5px] text-center">{goal}</p>
  //         </div>
  //         {/* activity options */}
  //         <div className=" flex flex-col gap-2 flex-[9]">
  //           {activities.map((option, index) => {
  //             return (
  //               <div
  //                 style={{
  //                   backgroundColor:
  //                     selected_activity?.activity === option &&
  //                     selected_activity?.index === index &&
  //                     "gainsboro",
  //                 }}
  //                 onClick={() => selectActivity(goal_no, index)}
  //                 className=" bg-[#F0F0F0] cursor-pointer flex gap-2 justify-between rounded-md border border-[gainsboro] text-[10px]  items-center px-[5px]"
  //               >
  //                 {/* <span className=" py-[10px]">A.</span> */}
  //                 <p>
  //                   {index + 1}. {option}
  //                 </p>
  //                 <div className=" flex items-center">
  //                   {selected_activity?.activity === option &&
  //                     selected_activity?.index === index && (
  //                       <TiTick className=" w-[18px] h-[18px]" />
  //                     )}
  //                   <IoIosClose
  //                     onClick={(e) => removeActivityOption(e, goal_no, index)}
  //                     className=" w-[25px] h-[25px]"
  //                   />
  //                 </div>
  //               </div>
  //             );
  //           })}
  //           {addedActivityGoal === goal_no && (
  //             <div className=" h-full bg-[gainsboro] flex gap-[1px] justify-between rounded-md border border-[gainsboro] text-[10px]  items-center px-[5px]">
  //               {/* <span className=" py-[10px]">A.</span> */}
  //               {/* <p>{option}</p> */}
  //               <input
  //                 type="text"
  //                 name=""
  //                 id=""
  //                 className=" w-full bg-transparent outline-none"
  //                 placeholder="Add Activity"
  //                 value={activityInput}
  //                 onChange={(e) => setActivityInput(e.target.value)}
  //               />
  //               <TiTick
  //                 className=" w-[18px] h-[25px] cursor-pointer"
  //                 onClick={() => addActivity(goal_no)}
  //               />
  //               <IoIosClose
  //                 onClick={handleActivityInputClose}
  //                 className=" w-[25px] h-[25px] cursor-pointer"
  //               />
  //             </div>
  //           )}
  //         </div>

  //         <div className="bg-[#D0DFF2] flex flex-col flex-1 justify-center items-center gap-[5px] py-[5px] px-[10px] rounded-md border border-[gainsboro]">
  //           <span className=" flex justify-center items-center w-[30px] h-[30px] bg-[#131432] rounded-[25px]">
  //             <GoPlus
  //               onClick={() => handleAddActivity(goal_no)}
  //               className=" w-[25px] h-[25px] cursor-pointer"
  //               color="white"
  //             />
  //           </span>
  //           <p className=" text-[12px] text-center">Agrega Actividad</p>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // };

  return (
    <div className=" w-full p-[20px] sm:p-[30px] text-[#646588] h-[92vh] overflow-scroll">
      <div className=" bg-[#C94D3B] text-white w-fit flex items-center gap-[15px] px-[15px] py-[10px] border-md rounded-md">
        <FaGraduationCap className=" w-[30px] h-[30px] hidden sm:block" />
        <p className=" text-[12px] md:text-[20px]">
          Curso 6to básico (A) - Planificación de Unidad - Actividades
        </p>
      </div>

      <div className=" mt-[15px] flex flex-col gap-4 overflow-scroll pb-[20px] max-h-[75vh]">
        {/* activity and materials */}
        {Object.keys(activityOptions)
          .slice(0, +data.weeks)
          .map((actKey, index) => {
            const filteredKeys = Object.keys(activityOptions[actKey]).filter(
              (key) => key.startsWith("actividad_")
            );

            const activity = filteredKeys.map(
              (key) => activityOptions[actKey][key]
            );
            console.log("Activity Options : ", activityOptions);

            console.log("Activity : ", activity);
            const selected_activity = activityOptions[actKey].selected_activity;
            const goal = data.goals[actKey].goal;

            // return getActivity(activity, index, goal, selected_activity);
            return (
              <GetActivity
                activities={activity}
                goal_no={index}
                goal={goal}
                selected_activity={selected_activity}
                setActivityInput={setActivityInput}
                activityInput={activityInput}
                activityOptions={activityOptions}
                setActivityOptions={setActivityOptions}
                setAddedActivityGoal={setAddedActivityGoal}
                addedActivityGoal={addedActivityGoal}
              />
            );
          })}

        <div className=" flex-1 flex gap-2 justify-end">
          <div
            onClick={handlePrevious}
            className=" flex items-center bg-[#65CD6F] w-[200px] rounded-sm py-[4px] px-[6px] cursor-pointer"
          >
            <span className=" w-[18px] h-[18px] flex items-center justify-center bg-slate-50 rounded-full">
              <GrLinkPrevious className="w-[12px] h-[12px]" color="#65CD6F" />
            </span>
            <p className=" text-sm m-auto text-white">Back</p>
          </div>
          <div
            onClick={handleNextPage}
            className=" flex items-center bg-[#65CD6F] w-[200px] rounded-sm py-[4px] px-[6px] cursor-pointer"
          >
            <span className=" w-[18px] h-[18px] flex items-center justify-center bg-slate-50 rounded-full">
              <GrLinkNext className="w-[12px] h-[12px]" color="#65CD6F" />
            </span>
            <p className=" text-sm m-auto text-white">Next</p>
          </div>
        </div>
      </div>
      <CustomSnackbar open={open} setOpen={setOpen} severity={"error"} />
    </div>
  );
};
export default CourseAPlanificationActivities;
